import { useState } from 'react'
import { MobileMenu } from './mobile_menu'
import { MobileBackDrop } from './mobile_menu/mobileBackdrop'
import { PrimaryMenu } from './primary_menu'
import { TopBar } from './top_bar'
import { AlertBanner } from 'components/header/alert-banner'

const Header = () => {

    const [showMobileMenu, setShowMobileMenu] = useState(false)

    const toggleMobile = () => {
        setShowMobileMenu(!showMobileMenu)
    }
    
    return (
        <header className="header">
            <AlertBanner />
            <TopBar />
            <PrimaryMenu toggleMobile={toggleMobile} />
            <MobileMenu show={showMobileMenu} />
            <MobileBackDrop />
        </header>
    )
}

export { Header }
