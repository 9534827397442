import { SimpliCitySDK } from 'cms/sdk'

export const generateImageSlides = async (images, token) => {
    const imageSlides = await Promise.all(
        images.map(async (data) => {
            const image = await SimpliCitySDK.images.findOne(token, data?.image)
            
            return {
                src: image?.url,
                width: 1920,
                height: 1080,
                title: image?.title,
                description: image?.caption ? image?.caption : image?.alt,
            }
        }),
    )
    return imageSlides
}
