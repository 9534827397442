import { isGridQuestion } from 'cms/forms/utils/is-grid-question'
import { isStaticQuestion } from 'cms/forms/utils/is-static-question'

/**
 * @param {FormQuestion[]} questions
 */
const getValidatableQuestions = (questions) => {
    const requiredQuestions = questions
        .filter(
            /**
             * A question must not be static, must be required, and
             * must be shown to the user to be validatable.
             */
            (question) => {
                const isStatic = isStaticQuestion(question)
                const { isRequired, isShown } = question

                if (isStatic || !isShown) {
                    return false
                }

                if (isGridQuestion(question)) {
                    /** @type {FormQuestionGrid} */
                    const { errors, columns } = question

                    /**
                     * We have to use errors instead of values since errors
                     * appear immediately when a row is selected, but values
                     * are only set when entering inputs.
                     */
                    const hasErros = errors.length > 0
                    const hasRequiredSubQuestion = columns.some(
                        (column) => column.subQuestion.required
                    )

                    // if we have errors and at least 1 subquestion is required
                    return hasErros && hasRequiredSubQuestion
                }

                return isRequired
            }
        )

    return requiredQuestions
}

export { getValidatableQuestions }
