import React, { Fragment } from 'react'
// import { useNavigate, Link } from "react-router-dom";

const CommonTaskItem = (props) => {
    
    const { service } = props || {}
    const { text, icon, url } = service || {}
    
    return (
        <Fragment>
            {service && (
                <div className='common-tasks-items-item'>
                    <a href={`${url}`} className='common-tasks-link' aria-label={text}>
                        {/*<span className={`common-tasks-icon ${icon_lib} ${icon}`}></span>*/}
                        <div className='common-tasks-icon'>{icon}</div>
                        <div className='common-tasks-text'>{text}</div>
                    </a>
                </div>
            )}
        </Fragment>
    )
    
    // TODO: Testing Local navigation - needs better detection of not found
    // const navigate = useNavigate()
    // return (
    //     <Fragment>
    //         {service && (
    //             <div className='common-tasks-items-item'>
    //                 <Link to={url} className='common-tasks-link'>
    //                     <span className={`common-tasks-icon ${icon_lib} ${icon}`}></span>
    //                     <span className="common-tasks-text">{text}</span>
    //                 </Link>
    //             </div>
    //         )}
    //     </Fragment>
    // )
}
export { CommonTaskItem }
