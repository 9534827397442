import React, { useEffect, useState } from "react";
import { SimpliCitySDK } from 'cms/sdk'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
import { StoryItem } from './story-item'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { evaluateIsCategory, getCategory } from 'pages/story/category/story-config'
import { useSimpliCityPath } from 'cms/webpage'
import { Header } from "components/header";
import { StoryNav } from "pages/story/story-nav";
import { BreadcrumbList } from "cms/shared/breadcrumb";
import { Footer } from "components/footer";

const defaultPage = 1
const defaultPageSize = 10

const StoryCategory = () => {
    
    const { slug } = useSimpliCityPath()
    const type = 'model.story'
    const categoryName = 'category'
    const { token } = useAuth()
    const page = defaultPage
    const pageSize = defaultPageSize
    const initialCrumbs = [
        { path: '/', title: 'Home' },
        { path: '/dnv-stories', title: 'Stories' },
    ]
    // const [crumbs,setCrumbs] = useState([initialCrumbs])
    const [stories,setStories] = useState([])
    
    // Fetch Listing
    const fetchFeed = async (token, category) => {
        // setLoading(true)
        let options = { page, pageSize, type, category, categoryName }
        if (!category) {
            options = { page, pageSize, type }
        }
        const { results: models } = await SimpliCitySDK.model.findFeed(token, options)
        setStories(models)
        // setLoading(false)
    }
    useEffect(() => {
        const isCategory = evaluateIsCategory(slug)
        if (isCategory) {
            const _category = getCategory(slug)
            fetchFeed(token,_category.title)
        } else {
            fetchFeed(token)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, slug])
    
    return (
        <>
            
            <Header />
            
            <div className='event two-column-side-nav'>
                <div className="two-column-side-nav-right">
                    <div id='layout' className='layout'>
                        <div className='main-content'>
                            
                            <BreadcrumbList crumbs={initialCrumbs} />
                            
                            <div>
                                {isArrayNotEmpty(stories) && stories.map((story, index) => {
                                    return (
                                        <div key={index}>
                                            
                                            <StoryItem
                                                model={story}
                                            />
                                        
                                        </div>
                                    )
                                })
                                }
                            
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="two-column-side-nav-left">
                    <StoryNav title={"Categories"} />
                </div>
            
            </div>
            
            <Footer />
        
        </>
    )
}
StoryCategory.displayName = 'StoryCategory'
export { StoryCategory }
