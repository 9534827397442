import { icons } from 'icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { useForm } from 'cms/forms/context'
import { ErrorMessages } from 'cms/forms/error-messages'
import ErrorMsg from 'cms/forms/error-msg'
import { useFileUploadErrors } from 'cms/forms/hooks/use-file-upload-errors'
import {
    getAcceptableFilename,
    getAcceptableFiles,
    processFiles
} from "cms/forms/questions/file-upload/upload-files-utils"
import { UploadedFile } from 'cms/forms/questions/file-upload/uploaded-file.jsx'
import { UploadingFile } from 'cms/forms/questions/file-upload/uploading-file.jsx'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
import { calculateBytesByUnit } from 'cms/utils/format'
import { CmsConfig } from 'cms/config'
import { useCallback, useMemo, useState } from 'react'
import { ErrorCode, useDropzone } from 'react-dropzone'
import { useUpdateEffect } from 'react-use'
import { Logger } from 'cms/utils/logger'


/**
 * @type {QuestionComponent<FormQuestionFileUpload>}
 */
const FileUpload = (props) => {

    const { field } = props
    const {
        guid,
        prompt,
        value,
        hasDescription,
        description,
        isAllowOnlyFileTypes,
        allowOnlyFileTypes,
        maxFiles,
        maxFileSize,
        fileUnit,
        hasHelperText,
        helperText,
        isRequired,
    } = field || {}

    const { website } = useSimpliCity()
    const { organization } = website || {}
    const defaultMaxSize = CmsConfig.forms.files.defaultMaxSize

    /** @type {File[]} */
    const defaultFiles = []
    const [files, setFiles] = useState(defaultFiles)
    const [attachments, setAttachments] = useState(value)
    const { onInputChange } = useForm()

    useUpdateEffect(
        () => {
            onInputChange(attachments, field)
        },
        [attachments]
    )

    const onDrop = useCallback(
        /**
         * @param {File[]} acceptedFiles 
         */
        async (acceptedFiles) => {
            Logger.debug(`Files Dropped.`, { acceptedFiles })
            /** @type {File[]} */
            const renamedFiles = []
            for (const file of acceptedFiles) {
                const replacementFile = new File([file], getAcceptableFilename(file), {
                    type: file.type,
                    lastModified: file.lastModified,
                })
                renamedFiles.push(replacementFile)
            }
            setFiles(renamedFiles)

            Logger.debug('upload [processFiles] started...')
            const uploaded_attachments = await processFiles(
                renamedFiles, guid, organization
            )
            setAttachments(
                (attachments) => {
                    return [
                        ...attachments,
                        ...uploaded_attachments
                    ]
                }
            )
            setFiles([])
            Logger.flush()
        }, [guid, organization]
    )

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        fileRejections,
    } = useDropzone({
        onDrop,
        validator () {
            // perform a check here to trigger an immediate file rejection when
            // attempting to upload additional files
            if (attachments.length >= Number(maxFiles)) {
                return {
                    code: ErrorCode.TooManyFiles,
                    // the message can be empty since we handle it in useFileUploadErrors
                    message: ErrorMessages.files.tooMany(maxFiles)
                  }
            }

            return null
        },
        maxFiles: Number(maxFiles).valueOf(),
        maxSize: Number(maxFileSize).valueOf() > 0
            ? calculateBytesByUnit(Number(maxFileSize).valueOf(), fileUnit)
            : defaultMaxSize,
        multiple: Number(maxFiles).valueOf() > 1,
        uploadMultiple: Number(maxFiles).valueOf() > 1,
        // default => does not limit types
        accept: getAcceptableFiles(isAllowOnlyFileTypes, allowOnlyFileTypes)
    })

    const { error, hasError } = useFileUploadErrors(field, fileRejections)

    const dragActiveStyle = isDragActive ? `drag-active` : ``
    const dragRejectStyle = isDragReject ? `drag-active` : ``

    /**
     * @param {File} file 
     */
    const onCancelFile = (file) => {
        const fileName = file.name
        
        // TODO: use API to delete?
        
        setFiles(
            (files) => files.filter(
                (item) => item.name !== fileName
            )
        )
    }

    /**
     * @param {Attachment} attachment 
     */
    const onRemove = (attachment) => {
        
        // TODO: use API to delete?
        
        setAttachments(
            (attachments) => {
                const attachmentsAfterDelete = attachments.filter(
                    (item) => item?.guid !== attachment.guid
                )
                return attachmentsAfterDelete
            }
        )
    }

    const fileTypeList = useMemo(
        () => {
            let list = ''

            if (isArrayNotEmpty(allowOnlyFileTypes)) {
                list = allowOnlyFileTypes
                    .map((type) => {
                        let formattedType = type

                        // remove leading period (.) if it exists
                        if (type[0] === ".") {
                            formattedType = type.slice(1)
                        }

                        return formattedType
                    })
                    .sort()
                    .join(", ")
            }

            return list
        },
        [allowOnlyFileTypes]
    )

    return (
        <div className='simplicity-forms-field-group dragActive'>
            <label htmlFor={`simplicity-forms-file-upload-${guid}`} className='simplicity-forms-field-label'>
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </label>
            {hasDescription && description && (
                <span className='simplicity-forms-description' style={{ fontStyle: 'normal', fontWeight: 400 }}>{description}</span>
            )}
            {hasHelperText && helperText && <span className='simplicity-forms-helper-text'>{helperText}</span>}

            <div className='simplicity-forms-file-upload-settings'>
                <p>
                    Max file size: <span>{maxFileSize}{fileUnit.toUpperCase()}</span>
                </p>
                <p>
                    Max # of files: <span>{maxFiles}</span>
                </p>
                {isAllowOnlyFileTypes && fileTypeList && (
                    <p>Accepted file types: <span>{fileTypeList}</span></p>
                )}
            </div>
            <div
                {...getRootProps()}
                className={`simplicity-forms-file-upload ${dragActiveStyle} ${dragRejectStyle}`}
            >
                {/*{isFalse(isDragReject) && (*/}
                <div className='simplicity-forms-file-upload-content'>
                    <div className="simplicity-forms-file-upload-icon">
                        <FontAwesomeIcon
                            icon={icons.regular.faCloudArrowUp}
                            size='2x'
                        />
                    </div>
                    <div className="simplicity-forms-file-upload-text">
                        <p>Drag and drop file(s) here or </p>
                    </div>
                    <div className="simplicity-forms-file-upload-button">
                        <button type="button" className="form-btn-file-upload">
                            Browse files
                        </button>
                    </div>
                </div>
                {/*)}*/}
                {/*{isTrue(isDragActive) && isTrue(isDragReject) && (*/}
                {/*    <p className='drag-problem'>Problem detected. Please check acceptable file rules.</p>*/}
                {/*)}*/}
                <input {...getInputProps()} />
            </div>

            {hasError && (
                <ErrorMsg type='error' error={error} />
            )}


            <div className='files-container'>
                {isArrayNotEmpty(attachments) && attachments.map((attachment) => (
                    <UploadedFile
                        key={attachment.guid}
                        attachment={attachment}
                        onRemove={onRemove}
                    />
                ))}

                {isArrayNotEmpty(files) && files.map((file) => (
                    <UploadingFile
                        key={file.name}
                        file={file}
                        onCancelFile={onCancelFile}
                    />
                ))}
            </div>
        </div>
    )
}

export { FileUpload }

