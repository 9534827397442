import { useEffect, useState } from "react";
import { LiveAlertBanner } from './LiveAlertBanner'
import { PreviewAlertBanner } from './PreviewAlertBanner'
import { useAlerts } from 'cms/hooks/use-alerts'
import { useNavigate } from 'react-router-dom'
import { CmsConfig } from 'cms/config'

const AlertBanner = () => {
    
    const isPreview = CmsConfig.isPreview
    const [showBanner, setShowBanner] = useState(false)
    const [started, setStarted] = useState(false)
    
    const navigate = useNavigate()
    const { alert, alertLoaded, loadAlerts } = useAlerts()
    // const { model_guid, enabled_live, enabled_preview } = alert || {}
    const { enabled_live, enabled_preview } = alert || {}
    
    const handleCloseBanner = () => {
        sessionStorage.setItem('hideAlertBanner', 'true')
        setShowBanner(false)
    }
    const onClickBanner = (event, path) => {
        navigate(path)
    }
    
    useEffect(() => {
        if (!started) {
            setStarted(true)
            loadAlerts()
        }
    }, [started, loadAlerts])
    
    useEffect(() => {
        const hideBanner = sessionStorage.getItem('hideAlertBanner') === 'true'
        if (hideBanner) {
            setShowBanner(false)
            return
        }
        const {enabled_live, enabled_preview, } = alert || {}
        if (enabled_preview) {
            setShowBanner(true)
        }
        if (enabled_live) {
            setShowBanner(true)
        }
    }, [alert])
    
    useEffect(() => {
        const hideBanner = sessionStorage.getItem('hideAlertBanner')
        if (hideBanner) {
            setShowBanner(false)
        }
    }, [])
    
    return (
        <div>
            
            {!isPreview &&
                <LiveAlertBanner
                    alert={alert}
                    enabled={enabled_live}
                    alertLoaded={alertLoaded}
                    handleCloseBanner={handleCloseBanner}
                    showBanner={showBanner}
                    onClickBanner={onClickBanner}
                />
            }
            
            {isPreview &&
                <PreviewAlertBanner
                    alert={alert}
                    enabled={enabled_preview}
                    alertLoaded={alertLoaded}
                    handleCloseBanner={handleCloseBanner}
                    showBanner={showBanner}
                    onClickBanner={onClickBanner}
                />
            }
            
        </div>
    )
}

export { AlertBanner }
