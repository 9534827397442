import { FormReducer } from 'cms/forms/context/reducer/form-reducer'
import { useImmerReducer } from 'use-immer'
import { FormContext } from './form.context'

const FormProvider = ({ initState, children }) => {

    const [state, dispatch] = useImmerReducer(FormReducer, initState)

    return (
        <FormContext.Provider value={{ state, dispatch }}>
            {children}
        </FormContext.Provider>
    )
}

export { FormProvider }
