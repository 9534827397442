const DefaultError = () => {
    
    return (
        <div className="main-content">
            <div>
                <div className="field field-field.component">
                    <div className="component rich-text" id="field-component-43888204-81c5-425c-8063-e6451ee17ed3">
                        <div className="rich-text-container "><p>You have searched for a page that is
                            not&nbsp;available on our&nbsp;website. Let us help you find the information you need.</p>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th colSpan="1" rowSpan="1">
                                            <p>To find information on</p>
                                        </th>
                                        <th colSpan="1" rowSpan="1">
                                            <p>Go here</p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td colSpan="1" rowSpan="1"><p>Consolidated bylaws, bylaw tickets and fines, bylaw infractions</p>
                                        </td>
                                        <td colSpan="1" rowSpan="1">
                                            <p><a href={'/government-administration/bylaws'}>Bylaws</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1" rowSpan="1">
                                            <p>The environment and climate action&nbsp;</p>
                                        </td>
                                        <td colSpan="1" rowSpan="1">
                                            <p><a href={'/community-environment'}>Community and environment</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1" rowSpan="1">
                                            <p>Council meeting and public hearing dates, minutes, agendas, and decisions</p>
                                        </td>
                                        <td colSpan="1" rowSpan="1">
                                            <p><a href={'https://app.dnv.org/councilsearchnew/'}>Council materials</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1" rowSpan="1">
                                            <p>Permits and licences for building, development, street use, and more</p>
                                        </td>
                                        <td colSpan="1" rowSpan="1">
                                            <p><a href={'government-administration/view-list-all-permits-and-licences'}>Permits and licences</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1" rowSpan="1">
                                            <p>Building, renovating, and development</p>
                                        </td>
                                        <td colSpan="1" rowSpan="1">
                                            <p><a href={'/business-development'}>Property and development</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1" rowSpan="1">
                                            <p>Property tax deadlines and penalties, tax rates, home owner grants, your &quot;My Utility and Tax&quot; account</p>
                                        </td>
                                        <td colSpan="1" rowSpan="1">
                                            <p><a href={'/your-home-property/property-taxes'}>Property tax and utility information</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1" rowSpan="1">
                                            <p>Parks projects, destination parks, park and trail maps</p>
                                        </td>
                                        <td colSpan="1" rowSpan="1">
                                            <p><a href={'/parks-trails-recreation'}>Parks, Trails &amp; Recreation</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1" rowSpan="1">
                                            <p>Transportation planning, streets, projects that impact traffic</p>
                                        </td>
                                        <td colSpan="1" rowSpan="1">
                                            <p><a href={'/streets-transportation'}>Streets &amp; Transportation</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1" rowSpan="1">
                                            <p>Job openings and how to apply</p>
                                        </td>
                                        <td colSpan="1" rowSpan="1">
                                            <p><a href={'/government-administration/jobs-and-volunteering'}>Work for the District</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1" rowSpan="1">
                                            <p>District services (garbage, water, sewer, wildlife, home safety)</p>
                                        </td>
                                        <td colSpan="1" rowSpan="1">
                                            <p><a href={'/your-home-property'}>Your home and property</a></p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h2>Still can&apos;t find what you need?</h2>
                            <p>You can try another search, or:&nbsp;</p>
                            <ul>
                                <li><p><a href={'/'}>Go to the homepage</a></p></li>
                                <li><p><a href={'/'}>Contact us</a></p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { DefaultError };
