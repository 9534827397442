import React from 'react'
import { NavigationItem } from './navigation_item'
import { RichText } from 'cms/rich-text'
import { isArrayNotEmpty } from "cms/utils/empty-exists";

const NavigationListStyle = (props) => {
    
    const {
        children,
        description,
        // error
    } = props
    
    return (
        <>
            <RichText
                className="navigation-list-description"
                richtext={description}
            />
            <div className='navigation-list-body'>
                {isArrayNotEmpty(children) &&
                    children.map((item) => {
                        const { guid } = item
                        return (
                            <NavigationItem
                                key={`navigation-item-${guid}`}
                                item={item}
                            />
                        )
                    })}
            </div>
        </>
    )
}

NavigationListStyle.displayName = 'NavigationListStyle'
export { NavigationListStyle }
