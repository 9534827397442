import React from 'react'
import { RichText } from 'cms/rich-text'
import { ImageGalleryFilmstrip } from './image_gallery_filmstrip'
import { ImageGalleryCarousel } from './image_gallery_carousel'
import { ImageGalleryGrid } from "./image_gallery_grid"
import { ImageGalleryCollage } from './image_gallery_collage'
import { isArrayNotEmpty, isRichTextNotEmpty } from 'cms/utils/empty-exists'
import { Logger } from 'cms/utils/logger'

const ImageGalleryComponent = (props) => {
    
    const { component } = props
    const {
        title = '',
        description = '',
        image_gallery,
        galleryType,
    } = component || {}
    Logger.debug('Image gallery type:', galleryType)
    Logger.flush()
    
    return (
        <div className='image-gallery'>
            
            <h2>{title}</h2>
            
            {isRichTextNotEmpty(description) &&
                <RichText richtext={description} />
            }
            
            {/*  Filmstrip  */}
            {galleryType === 'thumbnail' && isArrayNotEmpty(image_gallery) &&
                <ImageGalleryFilmstrip
                    image_gallery={image_gallery}
                />
            }
            
            {/*  Carousel  */}
            {galleryType === 'slider' && isArrayNotEmpty(image_gallery) &&
                <ImageGalleryCarousel
                    image_gallery={image_gallery}
                />
            }
            
            {/*  Grid  */}
            {galleryType === 'grid' && isArrayNotEmpty(image_gallery) &&
                <ImageGalleryGrid
                    image_gallery={image_gallery}
                />
            }
            
            {/*  Collage  */}
            {galleryType === 'masonry' && isArrayNotEmpty(image_gallery) &&
                <ImageGalleryCollage
                    image_gallery={image_gallery}
                />
            }
            
        </div>
    )
}

ImageGalleryComponent.displayName = 'ImageGalleryComponent'
export { ImageGalleryComponent }
