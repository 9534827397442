import React from 'react'
import Document from "../shared/document"
import { DOCUMENT_FORMATS, DOCUMENT_STYLES } from "cms/components/document-list/helpers";

const SimpleList = (props) => {
    
    const { component } = props || {}
    const { documents = [], title, listStyle, settings } = component || {}
    const {
        style = DOCUMENT_STYLES.BODY,
        format = DOCUMENT_FORMATS.TITLE
    } = settings || {}
    
    return (
        <>
            <div className={`list-heading ${listStyle}`}>
                {title}
            </div>
            <div className={`document-list ${listStyle}`}>
                {documents.map((doc, index) => (
                    <Document
                        key={index}
                        listStyle={listStyle}
                        doc={doc}
                        style={style}
                        format={format}
                    />
                ))}
            </div>
        </>
    )
}

export default SimpleList
