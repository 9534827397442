import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EmptyCarouselSlide = () => {
    
    return (
        <div className="home-banner">
            
            <div className="home-banner-image"
                 style={{ backgroundColor: '#989898'}}
                 // style={{ backgroundImage: `url(${currentSection?.image})` }}
            />
            
            <div className="home-banner-content">
                
                <h1>[...title]</h1>
                
                <p>[...description]</p>
                
                <div className="controls">
                    
                    <Link to >[...link]</Link>
                    
                    <div className="forward-back">
                        <button
                            aria-label='Previous'
                            // onClick={() => moveIdx(-1)}
                        >
                            <FontAwesomeIcon icon={`fa-regular fa-arrow-left`} />
                        </button>
                        
                        <span>0/0</span>
                        
                        <button
                            aria-label='Next'
                            // onClick={() => moveIdx(1)}
                        >
                            <FontAwesomeIcon icon={`fa-regular fa-arrow-right`} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { EmptyCarouselSlide }
