import { useForm } from 'cms/forms/context'
import { isArray, isObject } from 'cms/utils/empty-exists'
import { useMemo } from 'react'
import { ErrorMessages } from '../error-messages'
import ErrorMsg from '../error-msg'
import { useSetQuestionErrors } from '../hooks/use-set-question-errors'
import { useShowError } from '../hooks/use-show-error'

/**
 * @type {QuestionComponent<FormQuestionName>}
 */
const SimplicityFormName = ({ field }) => {

    const {
        guid,
        hasDescription,
        description,
        prompt,
        value,
        hasPlaceholderText,
        helperText,
        requestSeparate,
        requestSeparateOptions,
        isRequired,
    } = field || {}
    const { onInputChange } = useForm()

    const activeSeparateOptions = useMemo(
        () => {
            const activeOptions = isArray(requestSeparateOptions) ?
                requestSeparateOptions.filter((item) => item.value) :
                []
            return activeOptions
        },
        [requestSeparateOptions]
    )

    const currentError = useMemo(
        () => {
            if (requestSeparate) {
                // multiple field mode
                const errors = []
                const excludedOptions = ['hasMiddleName', 'hasNickName']

                for (const separateOption of activeSeparateOptions) {
                    const { name: key, label } = separateOption
                    const isExcluded = excludedOptions.includes(key)

                    if (!isExcluded && isRequired && !value[key]) {
                        errors.push(`Please enter a value for ${label}`)
                    }
                }

                if (errors.length) {
                    return errors
                }

            } else {
                // single field mode
                const notEmpty = value?.length > 0
                const empty = !notEmpty
                if (isRequired && empty) {
                    return ErrorMessages.required()
                }
            }

            return null
        },
        [requestSeparate, activeSeparateOptions, value, isRequired]
    )

    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    /**
     * @param {string} key
     * @param {string} inputValue
     */
    const onSeparateNameChange = (key, inputValue) => {
        const currentValue = isObject(value) ? value : {}
        const newValue = {
            ...currentValue,
            [key]: inputValue
        }

        onInputChange(newValue, field)
    }

    const onFullNameChange = (event) => {
        const newValue = event.target.value
        onInputChange(newValue, field)
    }

    return (
        <div className='simplicity-forms-field-group'>
            <label className='simplicity-forms-field-label'>
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </label>
            {hasDescription && description && <span className='simplicity-forms-description'>{description}</span>}
            {!requestSeparate ? (
                <input
                    type='text'
                    id={guid}
                    name={guid}
                    placeholder={hasPlaceholderText}
                    value={value}
                    onChange={onFullNameChange}
                />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                    }}
                >
                    {activeSeparateOptions
                        .map((item) => (
                            <div key={item?.label} className='inline-block' style={{ flex: `0 0 49%` }}>
                                <label className='simplicity-forms-field-label' style={{ fontSize: `14px` }}>
                                    {item.label}
                                </label>
                                <input
                                    type='text'
                                    id={`${guid}_${item.name}`}
                                    name={`${guid}_${item.name}`}
                                    value={value[item.name] || ''}
                                    onChange={(e) => onSeparateNameChange(item.name, e.target.value)}
                                />
                            </div>
                        ))}
                </div>
            )}
            {hasError && <ErrorMsg type='error' error={error} />}
            {helperText && <span className='simplicity-forms-helper-text'>{helperText}</span>}
        </div>
    )
}

export { SimplicityFormName }
