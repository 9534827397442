import { createBrowserRouter } from 'react-router-dom'
import { redirectLoader } from './redirect-loader'

import { RouterError } from 'cms/layout/error/router-error'
import { ApplicationLayout } from 'layout/application-layout'
// import { ApplicationSidebarLayout } from 'layout/application-sidebar-layout'
// import { ApplicationEventLayout } from 'layout/event-layout'
import { ApplicationNewsLayout } from 'layout/news-layout'
import { ApplicationStoryLayout } from 'layout/story-layout'
import { Home } from 'pages/home'
import { Search } from 'pages/search'
import { News } from 'pages/news'
import { Events } from 'pages/events'
import { Notices } from 'pages/notices'
import { Alerts } from 'pages/alerts'
import { Webpage } from 'pages/webpage'
import { Licences } from 'pages/licences'
import { EventSearch } from 'pages/events-search'
import { Stories } from 'pages/story'
// import { Bylaws } from 'pages/bylaws'
// import { Permits } from 'pages/permits'
// import { DevelopmentApplications } from 'pages/devapps'
// import { Meetings } from 'pages/meetings'
// import { Committees } from 'pages/committees'
// import { DevelopmentPermitArea } from 'pages/dpa'
// import { NeighbourhoodTests } from 'pages/neighbourhood'
// import { CollectionsWizard } from 'pages/collections-wizard'
// import { CollectionsCalendar } from 'pages/collections-calendar'

/**
 * Data enabled routes for the application.  This exists outside of the app itself
 * as they only need to be rendered once.
 */
export const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <RouterError />,
    },

    // example of nested application layout structure
    {
        path: "/search",
        element: <ApplicationLayout crumbs={[{ path: '/', title: 'Home' }, { title: 'Search Results' }]} />,
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <Search />,
            },
        ]
    },
    {
        path: "/events",
        element: <ApplicationNewsLayout
            // element: <ApplicationEventLayout
            crumbs={[{ path: '/', title: 'Home' }, { title: 'Events' }]}
            selectedSection={'Events'}
        />,
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <Events />,
            },
        ]
    },
    {
        path: "/news",
        element: <ApplicationNewsLayout
            crumbs={[{ path: '/', title: 'Home' }, { title: 'News' }]}
            selectedSection={'News'}
        />,
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <News />,
            },
        ]
    },
    {
        path: "/notices",
        element: <ApplicationNewsLayout
            crumbs={[{ path: '/', title: 'Home' }, { title: 'Notices' }]}
            selectedSection={'Notices'}
        />,
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <Notices />,
            },
        ]
    },
    {
        path: "/alerts",
        element: <ApplicationNewsLayout
            crumbs={[{ path: '/', title: 'Home' }, { title: 'Alerts' }]}
            selectedSection={'Alerts'}
        />,
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <Alerts />,
            },
        ]
    },
    // {
    //     path: "/bylaws",
    //     element: <ApplicationSidebarLayout
    //         crumbs={[{  path: '/', title: 'Home' }, { title: 'Bylaws' }]}
    //         selectedSection={'Bylaws'}
    //     />,
    //     errorElement: <RouterError />,
    //     children: [
    //         {
    //             index: true,
    //             element: <Bylaws />,
    //         },
    //     ]
    // },
    // {
    //     path: "/permits",
    //     element: <ApplicationSidebarLayout
    //         crumbs={[{  path: '/', title: 'Home' }, { title: 'Permits' }]}
    //         selectedSection={'Permits'}
    //     />,
    //     errorElement: <RouterError />,
    //     children: [
    //         {
    //             index: true,
    //             element: <Permits />,
    //         },
    //     ]
    // },
    // {
    //     path: "/collections-calendar",
    //     element: <ApplicationSidebarLayout
    //         crumbs={[{  path: '/', title: 'Home' }, { title: 'Collections' }]}
    //         selectedSection={'Collections'}
    //     />,
    //     errorElement: <RouterError />,
    //     children: [
    //         {
    //             index: true,
    //             element: <CollectionsCalendar />,
    //         },
    //     ]
    // },
    // {
    //     path: "/collections-wizard",
    //     element: <ApplicationSidebarLayout
    //         crumbs={[{  path: '/', title: 'Home' }, { title: 'Collections' }]}
    //         selectedSection={'Collections'}
    //     />,
    //     errorElement: <RouterError />,
    //     children: [
    //         {
    //             index: true,
    //             element: <CollectionsWizard />,
    //         },
    //     ]
    // },
    // {
    //     path: "/devapps",
    //     element: <ApplicationSidebarLayout
    //         crumbs={[{  path: '/', title: 'Home' }, { title: 'Development Application' }]}
    //         selectedSection={'Development Application'}
    //     />,
    //     errorElement: <RouterError />,
    //     children: [
    //         {
    //             index: true,
    //             element: <DevelopmentApplications />,
    //         },
    //     ]
    // },
    {
        path: "/licences",
        element: <ApplicationNewsLayout
            crumbs={[{ path: '/', title: 'Home' }, { title: 'Licences' }]}
            selectedSection={'Licences'}
        />,
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <Licences />,
            },
        ]
    },
    {
        path: "/events/search",
        element: <ApplicationNewsLayout
            crumbs={[{ path: '/', title: 'Home' }, { title: 'Events' }]}
            selectedSection={'Events'}
        />,
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <EventSearch />,
            },
        ]
    },
    // {
    //     path: "/committees",
    //     element: <ApplicationSidebarLayout
    //         crumbs={[{  path: '/', title: 'Home' }, { title: 'Committees' }]}
    //         selectedSection={'Committees'}
    //     />,
    //     errorElement: <RouterError />,
    //     children: [
    //         {
    //             index: true,
    //             element: <Committees />,
    //         },
    //     ]
    // },
    // {
    //     path: "/dpas",
    //     element: <ApplicationSidebarLayout
    //         crumbs={[{  path: '/', title: 'Home' }, { title: 'Development Permit Area' }]}
    //         selectedSection={'Development PermitArea'}
    //     />,
    //     errorElement: <RouterError />,
    //     children: [
    //         {
    //             index: true,
    //             element: <DevelopmentPermitArea />,
    //         },
    //     ]
    // },
    // {
    //     path: "/neighbourhood",
    //     element: <ApplicationSidebarLayout
    //         crumbs={[{  path: '/', title: 'Home' }, { title: 'Neighbourhood Feeds' }]}
    //         selectedSection={'Neighbourhood Feeds'}
    //     />,
    //     errorElement: <RouterError />,
    //     children: [
    //         {
    //             index: true,
    //             element: <NeighbourhoodTests />,
    //         },
    //     ]
    // },
    {
        path: "/dnv-stories-old",
        element: <ApplicationStoryLayout
            crumbs={[{ path: '/', title: 'Home' }, { title: 'Stories' }]}
        />,
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                path: "*",
                index: true,
                element: <Stories />,
            },
        ]
    },

    // catch all for paths of any length
    // will need to handle its own 404 logic
    {
        path: "*",
        element: <Webpage />,
        errorElement: <RouterError />,
        loader: redirectLoader,
    },
])
