import { LayoutWrapper } from "cms/layout/layout-wrapper"
import { useFetchWebpage, useSimpliCityPath } from "cms/webpage"
import { useEffect } from "react"
// import { RemoveTrailingSlash } from "../remove-trailing-slash";

const Webpage = () => {
    
    const { fetchWebpageData } = useFetchWebpage()
    const { path } = useSimpliCityPath()
    
    useEffect(() => {
        fetchWebpageData(path)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])

    return (
        <>
            <LayoutWrapper />
        </>
    )
}

export { Webpage }
