import { useForm } from 'cms/forms/context'
import { isString, objectHasKeys } from 'cms/utils/empty-exists'
import { useEffect, useMemo, useRef } from 'react'
import { ErrorMessages } from '../error-messages'
import { GetGridRowFactory } from '../questions/grid/get-grid-row.factory'

/**
 * @param {FormQuestionGrid} question
 * @param {GridQuestionOption[]} rows
 * @param {import('react').MutableRefObject<GridQuestionValue>} gridValues
 */
export function useGridErrors (question, rows, gridValues) {

    const {
        guid,
        columns,
        columnLabel: defaultColumnLabel,
        errors: rawErrors,
        value,
        isRequired,
    } = question

    const { setQuestionError } = useForm()

    // Using a map here makes it easier to insert and remove data.
    // It's also trivial to transform to an array.
    /** @type {import('react').MutableRefObject<Map<string, GridQuestionError>>} */
    const gridErrorMap = useRef(new Map())

    function updateFormErrors () {
        const errorList = Array.from(gridErrorMap.current.values())

        // We need to rely on the grid values ref in order to get the current value.
        const hasNoGridValues = !objectHasKeys(gridValues.current)
        const hasNoGridErrors = gridErrorMap.current.size === 0

        // Show a required message if this question is required,
        // there are no other errors, and the form is empty.
        // Doing so here (instead of an effect) prevents flashing.
        if (isRequired && hasNoGridValues && hasNoGridErrors) {
            setQuestionError(guid, ErrorMessages.required())
        } else {
            setQuestionError(guid, errorList)
        }
    }

    // Ensure that at least the required message is set.  Handles when there
    // are no optional fields to trigger an initial error.
    useEffect(
        () => {
            updateFormErrors()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    /**
     * @param {string} rowId
     * @param {string} columnId
     * @param {string | null} error 
     */
    function onError (rowId, columnId, error) {
        const gridErrorKey = [rowId, columnId].join()

        // add the error to the error list
        if (isString(error)) {
            gridErrorMap.current.set(
                gridErrorKey,
                { rowId, columnId, error }
            )
        } else {
            gridErrorMap.current.delete(gridErrorKey)
        }

        updateFormErrors()
    }

    const formattedErrors = useMemo(() => {
        const getGridRow = GetGridRowFactory(value, rows)

        const errorsWithPosition = rawErrors.map(
            (rawError) => {
                if (isString(rawError)) {
                    return { row: 0, col: 0, message: rawError }
                }

                const { columnId, rowId, error } = rawError

                // get row label
                const row = getGridRow(rowId)
                const rowLabel = row.label

                // get column label
                const column = columns.find((column) => column.guid === columnId)
                const columnLabel = column ? column.label : defaultColumnLabel
                const columnPosition = column ? column.position : 0

                const gridErrorMessage = `${rowLabel}, ${columnLabel}: ${error}`
                return { 
                    row: row.position,
                    col: columnPosition,
                    message: gridErrorMessage
                }
            }
        )

        // sort by row,col asc
        errorsWithPosition.sort(
            (a, b) => {
                if (a.row === b.row) {
                    return a.col - b.col
                }
                return a.row - b.row
            }
        )

        const errors = errorsWithPosition.map(
            (err) => err.message
        )

        return errors
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, rawErrors, rows, value])

    /**
     * @param {string} rowId 
     */
    function removeRowErrors (rowId) {
        // unset error keys
        const gridErrorKeys = Array.from(gridErrorMap.current.keys())
        const rowErrorKeys = gridErrorKeys.filter((k) => k.startsWith(rowId))

        for (const errorKey of rowErrorKeys) {
            gridErrorMap.current.delete(errorKey)
        }

        // update form state
        updateFormErrors()
    }

    return {
        onError,
        removeRowErrors,
        formattedErrors,
    }
}
