import React from 'react'
import { isEmpty } from 'lodash'
import { TableOfContentsTarget } from 'cms/shared/toc/target'
import shortHash from 'short-hash'
import { useSimpliCity } from 'cms/hooks/use-simplicity'

const HeadingComponent = (props) => {
    
    const { component } = props
    const { value, style } = component || {}
    const hasContent = !isEmpty(value)
    const isHeading1 = style === 'heading1'
    const isHeading2 = style === 'heading2'
    const isHeading3 = style === 'heading3'
    const isHeading4 = style === 'heading4'
    const valueForHash = value ? value.toString() : new Date().getTime().toString()
    const target = shortHash(valueForHash)
    const { model } = useSimpliCity()
    const { features } = model || {}
    const { toc } = features || {}
    
    return (
        <div className='simplicity-heading'>
            
            {hasContent && isHeading1 &&
                <h1>{value}</h1>
            }
            
            {hasContent && isHeading2 && toc &&
                <>
                    <TableOfContentsTarget target={target}/>
                    <h2>{value}</h2>
                </>
            }
            
            {hasContent && isHeading2 && !toc &&
                <>
                    <h2>{value}</h2>
                </>
            }
            
            {hasContent && isHeading3 &&
                <h3>{value}</h3>
            }
            
            {hasContent && isHeading4 &&
                <h4>{value}</h4>
            }
        </div>
    )
}

HeadingComponent.displayName = 'HeadingComponent'
export { HeadingComponent }
