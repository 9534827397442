import React from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { formatBytes } from 'cms/utils/format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icons } from 'icons'
import { Logger } from 'cms/utils/logger'

/**
 * @type {import('react').FC<UploadedFileProps>}
 */
const UploadedFile = (props) => {
    
    const {
        attachment,
        onRemove
    } = props

    // const { token } = useAuth()

    const remove = async (event) => {
        event.stopPropagation()

        // Note: uploads are completed - so attachments now exist
        const { guid: attachment_guid } = attachment || {}

        try {
            // TODO: this try/catch block will not trigger from errors on the
            // api call because errors on the underlying request are caught and not
            // rethrown
            const response = await SimpliCitySDK.attachment.delete(attachment_guid)
            Logger.debug('[remove-attachment]', response)
            Logger.flush()
            onRemove(attachment)
        } catch (error) {
            Logger.error(error)
            Logger.flush()
        }
    }

    return (
        <div className="form-file-upload-file-listing" key={attachment.file_name}>
            <div className="file-upload-control-bar">
                <div className="file-title">
                    <div>
                        {attachment?.file_name} ({formatBytes(attachment.file_size)})
                    </div>
                </div>
                <div className="deleteIcon" onClick={(event) => remove(event)}>
                    <span className="simplicity-icon-inline">
                        <FontAwesomeIcon
                            icon={icons.regular.faXmark}
                            size="2x"
                        />
                    </span>
                    <span>Remove</span>
                </div>
            </div>
        </div>
    
    )
}

export { UploadedFile };


/**
 * @typedef {object} UploadedFileProps
 * @property {Attachment} attachment
 * @property {(removed: Attachment) => void} onRemove
 */
