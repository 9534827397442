import { Config } from '../config'
import { makeRequest } from '../common/index'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/publish`

export const getStatus = async function (token, website,webpage) {
    const method = 'GET'
    const path = `${endpoint}/${website}/status/${webpage}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getMyModifications = async function (token, website) {
    const method = 'GET'
    const path = `${endpoint}/modification/${website}/my`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getModifications = async function (token, website) {
    const method = 'GET'
    const path = `${endpoint}/modification/${website}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const doneEditSave = async function (token, payload) {
    const method = 'POST'
    const path = `${endpoint}/saveVersion`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
export const publishChangeSet = async function (token, website, target, change_set_guid, payload) {
    const method = 'POST'
    const path = `${endpoint}/${website}/publish/${target}/changeset/${change_set_guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
export const publishCurrentChangeSet = async function (token, website, target, payload) {
    const method = 'POST'
    const path = `${endpoint}/${website}/publish/${target}/current`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
export const publishCurrentModifications = async function (token, website, target, payload) {
    const method = 'POST'
    const path = `${endpoint}/${website}/publish/${target}/modifications`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getChangeSet = async function (token, website, change_set_guid) {
    const method = 'GET'
    const path = `${endpoint}/${website}/changeset/${change_set_guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getCurrentChangeSet = async function (token, website) {
    const method = 'GET'
    const path = `${endpoint}/${website}/changeset/current`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const addToChangeSet = async function (token, website, payload) {
    const method = 'POST'
    const path = `${endpoint}/${website}/changeset/add`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
export const removeFromChangeSet = async function (token, website, payload) {
    const method = 'POST'
    const path = `${endpoint}/${website}/changeset/remove`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
