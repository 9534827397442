/**
 * @type {SnapshotComponent<LocationSnapshotValue>}
 */
const LocationSnapshot = (props) => {
    const { value } = props
    const { lat, lng } = value


    return (
        <ul>
            <li>Latitude: {lat}</li>
            <li>Longitude: {lng}</li>
        </ul>
    )
}

export { LocationSnapshot }

/**
 * @typedef {object} LocationSnapshotValue
 * @property {number} lat
 * @property {number} lng
 */
