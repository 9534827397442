import { createContext } from 'react'
import PropTypes from 'prop-types'
// import LoaderWrapper from 'cms/auth/components/loader-wrapper'

/**
 * @type {{ token?: string }}
 */
const defaultContext = null
const SimpliCityNoAuthContext = createContext(defaultContext)

export const SimpliCityNoAuthProvider = (props) => {
    
    const { children } = props
    const token = 'Anonymous'

    return (
        <SimpliCityNoAuthContext.Provider
            value={{
                token
            }}
        >
            {children}
        </SimpliCityNoAuthContext.Provider>
    )
}

SimpliCityNoAuthProvider.propTypes = {
    children: PropTypes.node
}

export default SimpliCityNoAuthContext
