import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/link`

const getLinks = async function (token, params) {
    const method = "GET"
    let path = `${endpoint}`
    if (params) {
        const page = params.page;
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const searchLinks = async function (token, searchTerm, params) {
    const method = "GET"
    let path = `${endpoint}/search`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            {
                term: searchTerm,
                pagination: { page, pageSize },
            },
            { encodeValuesOnly: true }
        );
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const getLink = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const createLink = async function (token, payload) {
    const method = "POST"
    const path = `${endpoint}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
};
const updateLink = async function (token, guid, payload) {
    const method = "PUT"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
};
const deleteLink = async function (token, guid) {
    const method = "DELETE"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};

export {
    getLinks,
    searchLinks,
    getLink,
    createLink,
    deleteLink,
    updateLink,
};
