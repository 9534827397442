import { debounce } from 'lodash'
import { useCallback } from 'react'

const useDebouncedQuery = (wait = 375) => {
    const debouncedQuery = debounce(
        /**
         * @param {string} query 
         * @param {(value: string) => void} search 
         */
        function queryHook (query, search) {
            // only fire the query if we are at the minimum length
            const isMinLength = query?.length >= 2

            // we have to pass empty queries onto the algolia client to handle
            if (!query || isMinLength) {
                search(query)
            }
        },
        wait
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback (debouncedQuery, [wait])
}

export { useDebouncedQuery }
