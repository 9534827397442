import React from 'react'
import Document from 'cms/components/document-list/shared/document'
import { DOCUMENT_FORMATS, DOCUMENT_STYLES } from 'cms/components/document-list/helpers'

const IconList = (props) => {
    
    const { component } = props || {}
    const { documents = [], title, listStyle, settings } = component || {}
    const {
        style = DOCUMENT_STYLES.BODY,
        format = DOCUMENT_FORMATS.TITLE
    } = settings || {}
    
    return (
        <>
            <div className={`list-heading ${listStyle}`}>
                {title}
            </div>
            <ul className={`document-list ${listStyle}`}>
                {documents.map((doc, index) => {
                    return (
                        <Document
                            key={`${index}${doc.document}`}
                            listStyle={listStyle}
                            doc={doc}
                            style={style}
                            format={format}
                        />
                    )
                })}
            </ul>
        </>
    )
}

export default IconList
