import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { useEffect, useState } from 'react'
import { parentsToCrumbs } from './parents-to-crumbs'
import { Logger } from 'cms/utils/logger'

export const useFetchBreadcrumbs = (webpage,model) => {
    
    const { guid: webpage_guid } = webpage || {}
    const { type } = model || {}
    const { token } = useAuth()

    /** @type {import('./breadcrumb-list').Crumb[]} */
    const defaultCrumbs = []

    const [crumbs, setCrumbs] = useState(defaultCrumbs)
    const [error, setError] = useState()

    async function fetchData (token, webpage_guid) {
        try {
            let parents = await SimpliCitySDK.webpage.findParents(token, webpage_guid)

            if (parents) {
                // console.log('Set - WEBPAGE')
                parents = parents.reverse()
                parents.pop() // remove self
                
                const crumbs = parentsToCrumbs(parents)
                Logger.debug(`breadcrumbs for ${webpage_guid} => ${JSON.stringify(crumbs)}`)
                setCrumbs(crumbs)
            }
        } catch (error) {
            Logger.error(error)
            Logger.flush()
            setError(error)
        }
    }
    
    useEffect(() => {
        let data
        if (type === 'model.news') {
            data = [{ path: '/', title: 'Home' }, { title: 'News', path: '/news' }]
        
        } else if (type === 'model.notice') {
            data = [{ path: '/', title: 'Home' }, { title: 'Notices', path: '/notices' }]
            
        } else if (type === 'model.alert') {
            data = [{ path: '/', title: 'Home' }, { title: 'Alerts', path: '/alerts' }]
            
        } else if (type === 'model.story') {
            data = [{ path: '/', title: 'Home' }, { title: 'Stories', path: '/dnv-stories' }]
            
        } else if (type === 'model.meeting') {
            // Yes Meetings do use events navigation
            data = [{ path: '/', title: 'Home' }, { title: 'Events', path: '/events' }]
            
        } else if (type === 'model.event') {
            data = [{ path: '/', title: 'Home' }, { title: 'Events', path: '/events' }]
        }
        
        if (data) {
            setCrumbs(data)
        } else {
            fetchData(token, webpage_guid)
        }
        
    }, [token, webpage_guid, type])

    return {
        crumbs,
        error
    }
}

