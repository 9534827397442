import { searchQueryAtom } from 'cms/search/state/search-query-atom'
import { useSetAtom } from 'jotai'
import { useSearchBox } from 'react-instantsearch'
import { useLocation } from 'react-router-dom'
import { Logger } from 'cms/utils/logger'

const useSaveSearchQuery = () => {
    
    const setSearchQuery = useSetAtom(searchQueryAtom)
    const location = useLocation()
    const { query } = useSearchBox()

    function saveSearchQuery () {
        if (location.pathname === '/search') {
            Logger.debug(`Saving search query ${query}`)
            Logger.flush()
            setSearchQuery(query)
        }
    }

    return saveSearchQuery
}
export { useSaveSearchQuery }
