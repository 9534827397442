import React, {useState, useEffect} from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { exists, isObject, isStringNotEmpty, isTrue, objectHasPropertyString } from 'cms/utils/empty-exists'
import { LINK_FORMATS } from 'cms/links/helpers'
import { TextLink } from 'cms/links/link/text/text-link'
import { UnderlineLink } from 'cms/links/link/underline/underline-link'
import { BulletPointLink } from 'cms/links/link/bullet/bullet-point'
import { CTAStyleLink } from 'cms/links/link/cta/cta-link'

const CTALink = (props) => {
    
    const {
        link: lnk,
        style,
        format
    } = props || {}
    const [link, setLink] = useState()
    const {token} = useAuth()
    
    const isCTALink = format === LINK_FORMATS.CTA
    const isTextLink = format === LINK_FORMATS.TEXT
    const isBulletLink = format === LINK_FORMATS.BULLET_POINT
    const isUnderlineLink = format === LINK_FORMATS.UNDERLINE
    
    useEffect(() => {
        const loadLink = async (guid) => {
            if (isStringNotEmpty(guid)) {
                const _link = await SimpliCitySDK.link.findOne(token, guid)
                setLink(_link)
            }
        }
        // avoid repeat loads
        if (!isObject(link) || !objectHasPropertyString(link, 'guid')) {
            const guid = lnk?.link
            loadLink(guid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lnk?.link])
    
    return (
        <div className='cta-link-item'>
            {exists(link) && isTrue(isTextLink) &&
                <TextLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
            {exists(link) && isTrue(isUnderlineLink) &&
                <UnderlineLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
            {exists(link) && isTrue(isBulletLink) &&
                <BulletPointLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
            {exists(link) && isTrue(isCTALink) &&
                <CTAStyleLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
        </div>
    )
}

export { CTALink }
