import { GridColumnSnapshot } from './grid-column.snapshot'

/**
 * @param {object} props
 * @param {string} props.label
 * @param {GridColumnSnapshot[]} props.columns
 */
const GridRowSnapshot = (props) => {

    const { label, columns } = props

    return (
        <div className='snapshot-grid-row'>
            <h4>{label}</h4>
            {columns.map(
                (column) => {
                    const { label, value, colId } = column

                    return (
                        <GridColumnSnapshot
                            key={colId}
                            label={label}
                            value={value}
                        />
                    )
                }
            )}
        </div>
    )
}

export { GridRowSnapshot }
