
import  {
    faXTwitter,
    faYoutube,
    faInstagram,
    faFacebookF,
    faLinkedinIn
} from '@awesome.me/kit-f6a7b16729/icons/classic/brands'

const brands = {
    faXTwitter,
    faYoutube,
    faInstagram,
    faFacebookF,
    faLinkedinIn,
}

export {
    brands,
}
