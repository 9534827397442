/**
 * Generate a sequence of numbers.
 * @param {number} start The number to start at
 * @param {number} length The size of the sequence
 */
const sequence = (start, length) => {
    const seq = [...Array(length).keys()].map(
        (index) => index + start
    )

    return seq
}

export { sequence }
