
import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/application`
const endpoints = `/${context}/applications`  // FIXME: make same

const getApplications = async function (token, params) {
    const method = 'GET'
    let path = `${endpoints}`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } }, { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const searchApplications = async function (token, searchTerm, searchType, params) {
    const method = 'GET'
    let path = `${endpoint}/search`
    const payload = undefined
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            {
                term: searchTerm,
                type: searchType,
                pagination: { page, pageSize }
            },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
const getApplication = async function (token, id) {
    const method = 'GET'
    const path = `${endpoint}/${id}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const createApplication = async function (token, websiteNav) {
    const method = 'POST'
    const path = `${endpoint}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = websiteNav
    return await makeRequest(method, base_url, path, headers, payload)
}
const updateApplication = async function (token, id, websiteNav) {
    const method = 'PUT'
    const path = `${endpoint}/${id}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = websiteNav
    return await makeRequest(method, base_url, path, headers, payload)
}
const deleteApplication = async function (token, id) {
    const method = 'DELETE'
    const path = `${endpoint}/${id}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

export {
    getApplications,
    searchApplications,
    getApplication,
    createApplication,
    deleteApplication,
    updateApplication,
}