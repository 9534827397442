import React from 'react'

function FooterLogo () {
    return (
        <div className={"footer-logo"}>
            <a href="/" className="footer-logo logo stacked">
                District of North Vancouver
            </a>
            <p>
                We respectfully acknowledge the original peoples of these lands and
                waters, specifically the səlilwətaɬ (Tsleil-Waututh), Sḵwx̱wú7mesh
                Úxwumixw (Squamish), and xʷməθkʷəy̓əm (Musqueam), on whose unceded
                ancestral lands the District of North Vancouver is located. We value the
                opportunity to learn, share, and serve our community on these unceded
                lands.
            </p>
        </div>
    )
}

export { FooterLogo }
