/**
 * Ensure the target element is set for the given rules
 * @param {QuestionOptionRule[]} newRules
 * @param {string} guid
 */
const ensureTargetElementExists = (newRules, guid) => {
    // set the target of invalidate actions to be the source question; they are empty
    for (const rule of newRules) {
        if (rule.action === 'INVALIDATE_SOURCE_QUESTION') {
            rule.targetElement = guid
        }
    }
}

export { ensureTargetElementExists }
