import React from 'react'
import { Outlet } from 'react-router-dom'
import { ErrorDisplay } from 'cms/shared/error_display'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { RemoveTrailingSlash } from 'router/remove-trailing-slash'

const ApplicationStoryLayout = () => {
    
    /*
    *   Story provides its own layout so that it works as a regular webpage as well
    *   this occurs when the path is not under /dnv-stories
    */
    
    // const { crumbs } = props
    const { error } = useSimpliCity()
    
    return (
        <>
            <RemoveTrailingSlash />
            <ErrorDisplay error={error} />
            <Outlet />
        </>
    )
}
export { ApplicationStoryLayout }
