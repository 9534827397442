import React from 'react'
import { LargeFeedFilter } from 'cms/shared/feeds/large'

const FeedComponent = (props) => {
    
    const { component } = props
    const {
        selected_type,
        selected_category,
        enable_bar
    } = component || {}
    
    return(
        <>
            <LargeFeedFilter
                type={selected_type}
                selected_category={selected_category}
                enableBar={enable_bar}
            />
        </>
    )
}

FeedComponent.displayName = 'FeedComponent'
export { FeedComponent }
