import { useMemo, useRef } from "react"
import isEqual from 'react-fast-compare'

/**
 * Deeply compares dependencies.
 * Use with caution as it is not without a performance penalty
 * @template {any} T
 * @param {() => T} factory
 * @param {import("react").DependencyList} deps
 * @returns {T}
 */
export function useDeepCompareMemo (factory, deps = []) {
    const cachedDeps = useRef(deps);

    if (!isEqual(cachedDeps.current, deps)) {
        // intentionally create a shallow copy for now
        // if we find issue with mutations, move to a structuredClone
        cachedDeps.current = [...deps]
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(factory, cachedDeps.current)
}
