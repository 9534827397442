import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/form`

export const getSchemaForms = async function (token, params) {
    const method = "GET"
    let path = `${endpoint}`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } },
            { encodeValuesOnly: true }
        );
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getSchemaForm = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getFormPlacements = async function (token, guid) {
    const method = "GET"
    let path = `${endpoint}/${guid}/placements`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const duplicateSchemaForm = async function (token, guid) {
    const method = "POST"
    const path = `${endpoint}/${guid}/duplicate`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const searchSchemaForms = async function (token, searchTerm, params) {
    const method = "GET"
    let path = `${endpoint}/search`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        // const last_response = params.last_response
        const query = qs.stringify(
            {
                term: searchTerm,
                pagination: { page, pageSize },
                // filters: {
                //     last_response,
                // }
            },
            { encodeValuesOnly: true }
        );
        path = `${endpoint}/search?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const createSchemaForm = async function (token, payload) {
    const method = "POST"
    const path = `${endpoint}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}
export const deleteSchemaForm = async function (token, guid) {
    const method = "DELETE";
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const updateSchemaForm = async function (token, id, payload) {
    const method = "PUT"
    const path = `${endpoint}/${id}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}

export const getSchemaFormKeywords = async function (token) {
    const method = "GET"
    const path = `${endpoint}/keywords`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

// export {
//     getSchemaForms,
//     getSchemaForm,
//     searchSchemaForms,
//     createSchemaForm,
//     deleteSchemaForm,
//     updateSchemaForm,
//     getSchemaFormKeywords,
// }
