import { useContext } from "react"
import { SimpliCityAppContext } from "../context/simplicity-app.context"
import { InvalidContext } from 'cms/context/invalid-context.error'

export function useSimpliCity () {
    
    const context = useContext(SimpliCityAppContext)

    if (!context) {
        throw new InvalidContext("SimpliCityAppContext", "SimpliCityAppProvider")
    }

    return context
}
