import { useForm } from "cms/forms/context"
import { emailMask } from "cms/utils/inputMask"
import { useMemo } from "react"
import { IMaskInput } from "react-imask"
import ErrorMsg from "../error-msg"
import { ErrorMessages } from "../error-messages"
import { useSetQuestionErrors } from "../hooks/use-set-question-errors"
import { useShowError } from "../hooks/use-show-error"

// const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const validRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,14}$/

/**
 * @type {QuestionComponent<FormQuestion<string>>}
 */
const SimplicityFormEmail = (props) => {

    const { field } = props || {}
    const {
        guid,
        description,
        prompt,
        value,
        hasPlaceholderText,
        helperText,
        isRequired,
        required,
    } = field || {}
    const { onInputChange } = useForm()

    const currentError = useMemo(
        () => {
            if (isRequired && !value) {
                return ErrorMessages.required()
            }

            if (!validRegex.test(value)) {
                return 'Please enter a valid email address'
            }

            return null
        },
        [isRequired, value]
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    return (
        <div className="simplicity-forms-field-group">
            <label className="simplicity-forms-field-label">
                {prompt}
                {!required && <span> (optional)</span>}
            </label>
            {description &&
                <span className="simplicity-forms-description">{description}</span>
            }
            <IMaskInput
                id={guid}
                name={guid}
                mask={emailMask}
                placeholder={hasPlaceholderText}
                value={value}
                onAccept={(value) =>
                    onInputChange(value, field)
                }
                className={hasError ? 'form-field-error-input' : ''}
            />
            {helperText &&
                <span className="simplicity-forms-helper-text">{helperText}</span>
            }
            {hasError &&
                <ErrorMsg type="error" error={error} />
            }
        </div>
    )
}

export { SimplicityFormEmail }
