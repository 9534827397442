import { useForm } from 'cms/forms/context'
import { useState } from 'react'
import { useUpdateEffect } from 'react-use'

/**
 * Renders an input if the option is `other`; otherwise, it will use the
 * configured label
 * @type {import('react').FC<OtherLabelProps>}
 */
const OtherLabel = (props) => {
    const {
        className,
        guid,
        id,
        option,
        placeholder = '',
        instructions = '',
        isChecked
    } = props

    const [value, setValue] = useState('')
    const { saveLabels: mergeRefs } = useForm()

    useUpdateEffect(
        () => {
            const partialRef = {
                [id]: value
            }

            mergeRefs(guid, partialRef)
        },
        [value]
    )

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event 
     */
    const onChange = (event) => {
        setValue(event.target.value)
    }

    if (option.isOther) {
        return (
            <div className='other-label'>
                <p className='checked-label'>{instructions}</p>

                {
                    isChecked && (
                        <input
                            id={id}
                            className={className}
                            value={value}
                            type="text"
                            onChange={onChange}
                            placeholder={placeholder}
                        />
                    )
                }

            </div >
        )
    }

    return option.label

}

export { OtherLabel }


/**
 * @typedef {object} OtherLabelProps
 * @property {string} id
 * @property {string} guid
 * @property {QuestionOption} option
 * @property {string} className
 * @property {string=} placeholder
 * @property {string=} instructions
 */
