import Slider from '@mui/material/Slider'
import { useForm } from 'cms/forms/context'
import { ErrorMessages } from 'cms/forms/error-messages'
import ErrorMsg from 'cms/forms/error-msg'
import { useSetQuestionErrors } from 'cms/forms/hooks/use-set-question-errors'
import { useShowError } from 'cms/forms/hooks/use-show-error'
import { isNumber } from 'cms/utils/empty-exists'
import { useMemo } from 'react'

/**
 * @type {QuestionComponent<FormQuestionOpinionScale>}
 */
const SimplicityFormOpinionScale = (props) => {

    const { field } = props || {}
    const {
        guid,
        prompt,
        minValue,
        maxValue,
        hasDescription,
        description,
        hasHelperText,
        helperText,
        isRequired,
        value,
    } = field || {}
    const { onInputChange } = useForm()

    // TODO: This validation is here for completeness, but rating currently
    // defaults to its mininimum value in the upstream reducer meaning that
    // none of these checks will ever fail.
    const currentError = useMemo(
        () => {
            if (isRequired) {
                if (!isNumber(value)) {
                    return ErrorMessages.required()
                }

                const isInBounds = value >= minValue && value <= maxValue

                if (!isInBounds) {
                    return `Please enter a value between ${minValue} and ${maxValue}`
                }
            }

            return null
        },
        [isRequired, maxValue, minValue, value]
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    /** @type {import('@mui/material').SliderOwnProps['onChange']} */
    const onChange = (e, val) => {
        onInputChange(val, field)
    }

    const initialValue = useMemo(
        () => isNumber(value) ? value : 0,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return (
        <div className="simplicity-forms-field-group">
            <label
                htmlFor={`simplicity-forms-rating-${guid}`}
                className="simplicity-forms-field-label"
            >
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </label>
            {hasDescription && description &&
                <span className="simplicity-forms-description">{description}</span>
            }
            <Slider
                defaultValue={initialValue}
                min={minValue}
                max={maxValue}
                step={1}
                marks
                valueLabelDisplay="auto"
                className="simplicity-forms-opinion-scale"
                onChange={onChange}
            />
            {hasHelperText && helperText &&
                <span className="simplicity-forms-helper-text">{helperText}</span>
            }
            {hasError &&
                <ErrorMsg type="error" error={error} />
            }
        </div>
    )
}

export { SimplicityFormOpinionScale }
