import React, { Fragment, lazy, Suspense, useEffect } from "react";
import cn from "clsx";
import { isTrue } from "cms/utils/empty-exists";

const Wrapper = lazy(() => import('./wrapper'))

const WebComponentEmbedComponent = (props) => {
    
    const { component } = props
    const {
        html,
        isResponsive,
        enableScript,
        script: _script,
    } = component || {}
    const hasContent = html !== '/*<!-- SimpliCity CMS Embed placeholder -->*/\n'
    const classNames = cn('simplicity-embed', { 'responsive': isResponsive })
    
    useEffect(() => {
        if (isTrue(enableScript)) {
            window.eval(_script)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_script])
    
    return (
        <Fragment>
            {hasContent &&
                <div className={classNames}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Wrapper component={component} />
                    </Suspense>
                </div>
            }
        </Fragment>
    )
}

export { WebComponentEmbedComponent }
