import { SortDropdown } from "./sort-dropdown";
import styles from "./sort-results.module.css";

export function SortResults (props) {
    const { className } = props;
    return (
        <div className={`${styles.container} ${className}`}>
            <p>Sort by</p>
            <SortDropdown />
        </div>
    );
}
