import cn from 'clsx'
import { ErrorMessages } from 'cms/forms/error-messages'
import { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

/**
 * @param {object} props
 * @param {DropdownSubQuestion} props.subQuestion
 * @param {boolean} props.isTouched
 * @param {string=} props.defaultValue
 * @param {(id: string) => void} props.onChange
 * @param {(error: string) => void} props.onError
 */
export function GridDropdown (props) {
    const { subQuestion, onChange, isTouched, onError, defaultValue } = props
    const {
        options = [],
        required,
    } = subQuestion

    const defaultOption = options.find(
        (option) => option.guid === defaultValue
    )

    /** @type {ReturnType<typeof useState<DropdownOption | undefined | null>>} */
    const [value, setValue] = useState(defaultOption)

    useEffect(
        () => {
            // react-select may emit a null value, so check for undefined to prevent
            // firing when the dropdown has been initialized
            if (value !== undefined) {
                // the value may still be null
                onChange(value?.guid)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value]
    )

    const error = useMemo(
        () => {
            if (required && !value) {
                return ErrorMessages.required()
            }

            return null
        },
        [required, value]
    )

    const hasError = isTouched && Boolean(error)

    useEffect(
        () => {
            onError(error)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error]
    )

    const classNames = cn(
        'simplicity-forms-field-dropdown', {
        'form-field-error-input': hasError
    })

    return (
        <Select
            defaultValue={defaultOption}
            className={classNames}
            openOnFocus
            isClearable
            options={options}
            getOptionValue={(option) => option?.guid}
            textFieldProps={{
                InputLabelProps: {
                    shrink: true,
                },
            }}
            placeholder="Select"
            fullWidth
            onChange={(option) => setValue(option)}
        />
    )
}

