/**
 * Temporary structure for colocating error messages.  It may be removed in
 * favor of direct error messages
 */
export const ErrorMessages = {
    // common
    required: () => 'Please input the required field',

    // text
    text: {
        minLength: (min) => `Please enter as least ${min} characters`,
        maxLength: (max) => `Please enter as most ${max} characters`,
    },

    // numeric
    numeric: {
        minValue: (min) => `Enter a number equal to or greater than ${min}`,
        maxValue: (max) => `Enter a number lower than ${max}`,
        decimals: (numDecimals) => `Please use no more than ${numDecimals} decimal places`,
    },

    // file upload
    files: {
        tooLarge: (maxFileSize, fileUnit) => `File is too large, please ensure it is less than ${maxFileSize} ${fileUnit}.`,
        tooMany: (maxFiles) => `The maximum number of files to upload is ${maxFiles}.`,
        invalidType: (fileTypes) => `A file was not suitable. Allowed file types include: ${fileTypes}`,
    },

    // logical
    invalid: () => 'Please make a valid selection'
}
