import { isGroupQuestion } from 'cms/forms/utils/is-group-question'
import { isStaticQuestion } from 'cms/forms/utils/is-static-question'
import { objectHasKeys } from 'cms/utils/empty-exists'
import { snapshotQuestions } from './snapshot-questions'
import { useFormState } from './use-form-state'

const useFormSnapshot = () => {
    const FormState = useFormState()

    /**
     * Snapshot form
     */
    const snapshot = () => {
        const { schemaForm } = FormState
        const { questions } = schemaForm

        // extract group refs
        const groups = questions
            .filter(isGroupQuestion)
            .map(
                (question) => {
                    const { guid, prompt } = question

                    return {
                        guid,
                        title: prompt
                    }
                }
            )

        // extract other refs
        /** @type {Record<string, object>} */
        const labels = questions
            .filter((question) => objectHasKeys(question.labels))
            .reduce(
                (collector, question) => {
                    const { guid, labels: refs } = question

                    return {
                        ...collector,
                        [guid]: refs
                    }
                },
                {}
            )

        const snapshottableQuestions = questions.filter(
            (question) => {
                const isStatic = isStaticQuestion(question)
                const { isShown } = question

                // only snapshot questions that are not static and are shown
                const isSnapshottable = !isStatic && isShown
                return isSnapshottable
            }
        )

        const snapshots = snapshotQuestions(snapshottableQuestions, labels)

        /**
         * @typedef {object} Result
         * @property {typeof snapshots} snapshots
         * @property {typeof groups} groups
         * @property {typeof labels=} labels
         */

        /** @type {Result} */
        const result = {
            snapshots,
            groups,
        }

        if (objectHasKeys(labels)) {
            result.labels = labels
        }

        return result
    }

    return snapshot
}

export { useFormSnapshot }
