import { isDate } from 'moment'

/**
 * @param {OptionalQuestion<FormQuestionDateTime>} question
 */
const dateTimeValueTransformer = (question) => {
    const { dateTimeType, value: selectedDate } = question
    
    const dateTimeString = isDate(selectedDate) ?
        selectedDate.toISOString() :
        selectedDate
    
    // TODO: Test dayjs for this: https://day.js.org/docs/en/parse/is-valid
    // const dateTimeString = dayjs(selectedDate).isValid() ?
    //     selectedDate.toISOString() :
    //     selectedDate
    
    return {
        dateTimeType, 
        dateTimeString
    }
}

export { dateTimeValueTransformer }
