import { optionTransformer } from './option.transformer'

/**
 * @param {FormQuestionCheckbox} question
 */
const checkboxSchemaTransformer = (question) => {
    const {
        guid,
        hasOtherOptions,
        options: initialOptions,
    } = question

    const options = optionTransformer(
        guid,
        hasOtherOptions,
        initialOptions,
        1,
        (guid) => {
            return {
                value: guid,
                name: guid,
            }
        }
    )

    /**
     * @type {FormQuestionCheckbox}
     */
    const newQuestion = {
        ...question,
        options,
        value: [],
    }

    return newQuestion
}

export { checkboxSchemaTransformer }
