import { AddressSnapshot } from './snapshots/address.snapshot'
import { CheckboxSnapshot } from './snapshots/checkbox.snapshot'
import { DateTimeSnapshot } from './snapshots/date-time.snapshot'
import { DropdownSnapshot } from './snapshots/dropdown.snapshot'
import { FileUploadSnapshot } from './snapshots/file-upload.snapshot'
import { GridSnapshot } from './snapshots/grid.snapshot/grid.snapshot'
import { LocationSnapshot } from './snapshots/location.snapshot'
import { NameSnapshot } from './snapshots/name.snapshot'
import { OpinionScaleSnapshot } from './snapshots/opinion-scale.snapshot'
import { RatingSnapshot } from './snapshots/rating.snapshot'
import { TextSnapshot } from './snapshots/text.snapshot'

/**
 * @type {Record<QuestionTypes, SnapshotComponent>}
 */
const SnapshotTypeMap = {
    "question.address": AddressSnapshot,
    "question.checkbox": CheckboxSnapshot,
    "question.dateTime": DateTimeSnapshot,
    "question.dropdown": DropdownSnapshot,
    "question.email": TextSnapshot,
    "question.fileUpload": FileUploadSnapshot,
    "question.grid": GridSnapshot,
    "question.location": LocationSnapshot,
    "question.longAnswer": TextSnapshot,
    "question.multipleChoice": TextSnapshot,
    "question.name": NameSnapshot,
    "question.numericalValue": TextSnapshot,
    "question.opinionScale": OpinionScaleSnapshot,
    "question.phoneNumber": TextSnapshot,
    "question.rating": RatingSnapshot,
    "question.shortAnswer": TextSnapshot,
    "question.websiteUrl": TextSnapshot,
    "question.yesNo": TextSnapshot,
}

export { SnapshotTypeMap }
