import { RichText } from "cms/rich-text"

/**
 * Render richtext content to HTML
 * @param {object} props
 * @param {any} props.component
 */
export function RichTextComponent (props) {
    const { component } = props

    return <RichText richtext={component?.richtext} />
}
