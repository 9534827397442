import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/schema/model`

const getSchemaModels = async function (token, params) {
  const method = "GET"
  let path = `${endpoint}`
  if (params) {
    const page = params.page
    const pageSize = params.pageSize
    const query = qs.stringify(
        { pagination: { page, pageSize } },
        { encodeValuesOnly: true }
    );
    path = `${path}?${query}`
  }
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
}
const getSchemaModel = async function (token, guid) {
  const method = "GET"
  const path = `${endpoint}/${guid}`
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
}
const getSchemaModelByType = async function (token, type) {
  const method = "GET"
  const path = `${endpoint}/type/${type}`
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
};
const createSchemaModel = async function (token, payload) {
  const method = "POST"
  const path = `${endpoint}`
  const headers = { Authorization: `Bearer ${token}` }
  return await makeRequest(method, base_url, path, headers, payload)
};
const addFieldToSchemaModel = async function (token, model, field) {
  const method = "POST"
  const path = `${endpoint}/${model}/addfield/${field}`
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
};
const searchSchemaModel = async function (token, model, field) {
  const method = "POST"
  const path = `${endpoint}/${model}/search`
  
  // TODO: implement search better here
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
};
const updateSchemaModel = async function (token, guid, payload) {
  const method = "PUT"
  const path = `${endpoint}/${guid}`
  const headers = { Authorization: `Bearer ${token}` }
  return await makeRequest(method, base_url, path, headers, payload)
};
const updateSchemaModelTOC = async function (token, guid, payload) {
  const method = "PATCH"
  const path = `${endpoint}/${guid}/toc_enable`
  const headers = { Authorization: `Bearer ${token}` }
  return await makeRequest(method, base_url, path, headers, payload)
};
const deleteSchemaModel = async function (token, guid) {
  const method = "DELETE"
  const path = `${endpoint}/${guid}`
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
}

export {
  getSchemaModels,
  getSchemaModel,
  getSchemaModelByType,
  createSchemaModel,
  addFieldToSchemaModel,
  searchSchemaModel,
  updateSchemaModel,
  updateSchemaModelTOC,
  deleteSchemaModel,
}
