import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { useEffect, useState } from 'react'
import { filterHiddenRecursively } from '../read_node'
import { Logger } from 'cms/utils/logger'

/**
 * @param {string} webpageGuid
 */
const usePageNav = (webpageGuid) => {
    
    const { token } = useAuth()

    // tracks the section tree structure that makes up the sidebar navigation
    const [sectionTree, setSectionTree] = useState({})
    const [error, setError] = useState()

    // tracks the current section guid
    const [sectionGuid, setSectionGuid] = useState()

    // controls highlighting for the active menu item
    const [selectedSections, setSelectedSections] = useState([])

    // tracks the menus that the user has open
    const [openMenus, setOpenMenus] = useState([])
    
    async function fetchParents () {
        try {
            let parents = await SimpliCitySDK.webpage.findParents(token, webpageGuid)
            // FIXME: this logic may not always be correct for unparented pages
            if (parents) {
                // const section = parents?.length > 1 ? parents[0] : undefined
                const section = parents[parents.length - 2]
                const parentGuids = parents.map((page) => page?.guid)
                // parentGuids is a list of ancestor guids
                // - ordered from the parent of this webpage up to the root
                Logger.debug(`[use-page-nav] section guid, parents for webpage ${webpageGuid}`)
                Logger.flush()
                if (section && section?.guid) {
                    setSectionGuid(section?.guid)
                }
                setSelectedSections(parentGuids)
                setOpenMenus(parentGuids)
            }

        } catch (error) {
            Logger.error(error)
            Logger.flush()
            setError({ error })
        }
    }

    async function fetchSectionTree () {
        try {
            const sectionTree = await SimpliCitySDK.webpage.findTree(token, sectionGuid)
            if (sectionTree) {
                Logger.info(`[use-page-nav] section tree for section ${sectionGuid}`, { sectionGuid })
                Logger.debug('[use-page-nav] section tree', sectionTree)
                Logger.flush()
                filterHiddenRecursively(sectionTree)
                
                setSectionTree(sectionTree)
            }
        } catch (error) {
            Logger.error(error)
            Logger.flush()
            setError({ error })
        }
    }

    // when the webpageGuid changes, fetch its parents
    useEffect(() => {
        if (webpageGuid) {
            fetchParents()
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, webpageGuid])

    
    // when the sectionGuid changes, fetch the tree
    useEffect(() => {
        if (sectionGuid) {
            fetchSectionTree()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionGuid])
    
    return {
        sectionGuid,
        sectionTree,
        error,
        selectedSections,
        openMenus,
        setOpenMenus
    }
}

export { usePageNav }
