import axios from 'axios'
import { Config } from '../config'
import { Logger } from './logger'

const makeRequest = async function (method, baseUrl, path, headers, payload ) {
    // Logger.debug("[SimpliCitySDK] PAYLOAD", payload)
    const request_url = new URL(path, baseUrl).toString()
    // Logger.debug('[SimpliCitySDK] make api request with headers: '  + JSON.stringify(headers))
    return axios({
        method,
        url: request_url,
        headers,
        data: payload,
        timeout: 15000,
        responseType: 'json',  // TODO: NOTE: may need to avoid once in a while
        // rejectUnauthorized: false,
    })
        .then(function (response) {
            if (response?.status > 199 && response?.status < 300) {
                if (Config.public.http_log) {
                    Logger.debug(`[SimpliCitySDK] ${response?.status} ${response?.statusText} ${method} ${request_url}`)
                }
                return response.data
            } else {
                Logger.error(`[SimpliCitySDK] API Request - No data returned: ${response?.status} ${response?.statusText} ${request_url} ${response?.data}`)
                Logger.flush()
                //     throw Error(`API Request Failure: ${response.status} ${response.statusText}`)
            }
        })
        .catch(function (error) {
            Logger.error(`[SimpliCityAdminSDK] `,error)
            Logger.flush()
            if (error?.response?.status === 401) {
                // token not valid or expired
                // throw Error("401 Unauthorized Error")
                Logger.error("[SimpliCitySDK] 401 Unauthorized Error", error)
                Logger.flush()
                // TODO: need to reimplement this
                // window.location.href = '/logout'
            } else {
                Logger.error('[SimpliCitySDK] public sdk error', error)
                Logger.flush()
                throw error
            }
        })
}

export { makeRequest }