import { Config } from '../config'
import { makeRequest } from '../common/index'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/publish/alert`

export const getCurrentAlert = async function (token) {
    const method = 'GET'
    const path = `${endpoint}/current`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getCurrentAlertForModel = async function (token, model_guid) {
    const method = 'GET'
    const path = `${endpoint}/${model_guid}/current`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getAlert = async function (token,guid) {
    const method = 'GET'
    const path = `${endpoint}/{guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const publishLive = async function (token,model_guid) {
    const method = 'POST'
    const path = `${endpoint}/${model_guid}/live/publish`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = { live: true, preview: true }
    return await makeRequest(method, base_url, path, headers, payload)
}
export const unpublishLive = async function (token, model_guid, guid) {
    const method = 'POST'
    const path = `${endpoint}/${model_guid}/live/${guid}/unpublish`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = { live: true, preview: true }
    return await makeRequest(method, base_url, path, headers, payload)
}
export const publishPreview = async function (token,model_guid) {
    const method = 'POST'
    const path = `${endpoint}/${model_guid}/preview/publish`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = { preview: true }
    return await makeRequest(method, base_url, path, headers, payload)
}
export const unpublishPreview = async function (token, model_guid, guid) {
    const method = 'POST'
    const path = `${endpoint}/${model_guid}/preview/${guid}/unpublish`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = { preview: true }
    return await makeRequest(method, base_url, path, headers, payload)
}