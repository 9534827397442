import Paragraph from "@tiptap/extension-paragraph"
import { className } from "./class-name"

const CustomParagraph = Paragraph.extend({
    // this allows us to specify the attributes that we want to manage
    addAttributes () {
        return {
            // the parent may not exist: https://tiptap.dev/docs/editor/guide/custom-extensions#extend-existing-attributes
            ...this.parent?.(),
            class: className,
        };
    },
})

export { CustomParagraph }
