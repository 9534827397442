import React from 'react'
import { NavLink } from 'react-router-dom'
import { FooterLogo } from './footer-column/footer_logo'
import { FooterNeighbourhoods } from './footer-column/neighbourhoods'
import { FooterContactUs } from './footer-column/footer_contactus'
import { FooterSocial } from './footer-column/footer_social'

const Footer = () => {
    return (
        <>
            <div id="footer" className="page-footer">
                <div className={"page-footer-content"}>
                    
                    <FooterLogo />
                    
                    <FooterNeighbourhoods />
                    
                    <FooterContactUs />
                    
                    <FooterSocial />
                    
                </div>
            </div>
            <div className={"page-copyright"}>
                <div className={"page-copyright-content"}>
                    <p>
                        © {new Date().getFullYear()} Corporation of the
                        District of North Vancouver
                    </p>
                    <ul>
                        <li>
                            Built with <a aria-label='SimpliCity CMS' href="https://www.simplicitycms.ca/">SimpliCity</a>&trade;
                        </li>
                        <li>
                            <div>
                                <NavLink aria-label='Privacy Policy' to="/government-administration/our-privacy-statement">Privacy Policy</NavLink>
                            </div>
                            <div>
                                <NavLink aria-label='Terms of use' to="/terms-use">Terms of use</NavLink>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export { Footer }
