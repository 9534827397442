import { Config } from '../config'
import { makeRequest } from "../common/index"
// import qs from 'qs'

const base_url = Config.public.api_base_url
const context = Config.public.context
const endpoint = `/${context}/link`

export const getLink = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
