import { noop } from 'lodash'
import { createContext } from 'react'

/**
 * @type {FormContext}
 */
const defaultFormState = {
    state: {},
    dispatch: noop
}

const FormContext = createContext(defaultFormState)

export { FormContext }
