import React, { Fragment } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isStringNotEmpty } from "cms/utils/empty-exists";

const Icon = (props) => {
    
    const { icon } = props
    if (!isStringNotEmpty(icon)) {
        return null
    }
    
    debugger
    return (
        <Fragment>
            {icon &&
                <FontAwesomeIcon icon={`fa-regular ${icon}`} />
            }
        </Fragment>
    )
}

export { Icon }
