import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { RichText } from 'cms/rich-text'
import { clsx } from 'clsx'

const AccordionSection = (props) => {

    const {
        section,
        selectSection,
        tabIndex: tabindex = 0,
        group_id,
        stepNumber,
        showSteps
    } = props || {}

    const {
        richtext,
        tab_id,
        title = 'Title missing',
        isVisible
    } = section

    const guid = tab_id
    const instance_id = tab_id
    const panel = `panelGroup_panel-${group_id}-${instance_id}`
    const label = `panelGroup_tab-${group_id}-${instance_id}`
    const visibility = isVisible ? 'open' : 'closed'
    
    const containerClassName = clsx('simplicity-accordion', visibility, {
        first: tabindex === 0  
    })

    return (
        <div className={containerClassName}>
            {showSteps && (
                <div className='step-container'>
                    <h6>{stepNumber}</h6>
                </div>
            )}

            <div className='accordion-container'>
                <div
                    className='accordion-header'
                    onClick={(e) => selectSection(guid, e)}
                    role='tab'
                    id={instance_id}
                    aria-controls={panel}
                    tabIndex={tabindex}
                    aria-expanded='false'
                    aria-selected='false'
                >
                    <p>{title}</p>
                    <span>
                        {isVisible &&
                            <RemoveIcon />
                        }
                        {!isVisible &&
                            <AddIcon />
                        }
                    </span>
                </div>
                <div
                    className={'accordion-section-content'}
                    role='tabpanel'
                    aria-labelledby={label}
                    id={panel}
                >
                    <RichText richtext={richtext} />
                </div>
            </div>

        </div>

    )
}

AccordionSection.displayName = 'AccordionSection'
export { AccordionSection }

