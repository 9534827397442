
/**
 * @typedef {object} Parent
 * @property {string} path
 * @property {string} nav_title
 */

/**
 * Converts parent objects to crumbs
 * @param {Parent[]} parents 
 * @returns 
 */
export function parentsToCrumbs (parents) {
    /** @type {import('./breadcrumb-list').Crumb[]} */
    const crumbs = parents.map(function (parent) {
        return {
            path: parent.path,
            title: parent.nav_title
        }
    })

    return crumbs
}
