import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'
import { get } from 'lodash'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/form/response`

const createForm = async function (token, payload, options) {
    const method = "POST"
    const path = `${endpoint}`

    const additionalHeaders = get(options, 'headers', {})
    const headers = { 
        ...additionalHeaders,
        Authorization: `Bearer ${token}`
    }
    return await makeRequest(method, base_url, path, headers, payload)
}
const getAllFormResults = async function (token, payload) {
    const method = "GET"
    const path = `${endpoint}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}
const getFormResult = async function (token, id, payload) {
    const method = "GET"
    const path = `${endpoint}/${id}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}
const getFormResults = async function (token, id, payload) {
    const method = "GET"
    const path = `${endpoint}/form/${id}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}
const getFormResultsAsCSV = async function (token, id, params) {
    const method = "GET"
    let path = `${endpoint}/form/${id}/csv`
    
    if (params) {
        const query = qs.stringify(params, {
            encodeValuesOnly: true
        })
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers)
}
const deleteFormResponse = async function (token, id, payload) {
    const method = "DELETE"
    const path = `${endpoint}/${id}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}

export {
    getFormResults,
    getFormResult,
    getAllFormResults,
    getFormResultsAsCSV,
    createForm,
    deleteFormResponse,
}
