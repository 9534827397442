import { useForm } from 'cms/forms/context'
import { useMemo } from 'react'
import { ErrorMessages } from '../error-messages'
import ErrorMsg from '../error-msg'
import { useSetQuestionErrors } from '../hooks/use-set-question-errors'
import { useShowError } from '../hooks/use-show-error'

/**
 * @type {QuestionComponent<FormQuestion<string>>}
 */
const SimplicityFormLongAnswer = (props) => {

    const { field } = props || {}
    const {
        guid,
        prompt,
        value,
        hasDescription,
        description,
        hasPlaceholderText,
        helperText,
        hasCharacterLimit,
        hasCharacterLimitText,
        hasCharacterLimitAmount,
        isRequired,
        required,
    } = field || {}
    const { onInputChange } = useForm()
    const hasLimit = Boolean(hasCharacterLimit).valueOf()
    const isUpdated = !!hasCharacterLimitAmount
    const limitAmountText = Number(hasCharacterLimitText).valueOf()
    const limitAmountAmount = Number(hasCharacterLimitAmount).valueOf()
    // TODO: clean up data and remove 'hasCharacterLimitText'
    const limitAmount = isUpdated ? limitAmountAmount : limitAmountText
    const characterLimit = hasLimit ? limitAmount : undefined

    const currentError = useMemo(
        () => {
            if (isRequired && !value) {
                return ErrorMessages.required()
            }

            if (value.length < 3) {
                return ErrorMessages.text.minLength(3)
            }

            return null
        },
        [isRequired, value]
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    const onChangeAnswer = (event) => {
        const value = event.target.value
        onInputChange(value, field)
    }

    return (
        <div className="simplicity-forms-field-group">
            <label className="simplicity-forms-field-label">
                {prompt}
                {!required && <span> (optional)</span>}
            </label>
            {hasDescription && description && <span className="simplicity-forms-description">{description}</span>}
            <textarea
                id={guid}
                name={guid}
                placeholder={hasPlaceholderText}
                value={value}
                rows={3}
                onChange={onChangeAnswer}
                className={hasError ? 'form-field-error-input' : ''}
                maxLength={characterLimit}
            />
            <div className='form-character-limit-error'>
                {hasError && <ErrorMsg type="error" error={error} />}
                {hasCharacterLimit &&
                    <span className="simplicity-forms-helper-text2">
                        Character Limit: {value.length} / {limitAmount}
                    </span>
                }
            </div>
            {helperText &&
                <span className="simplicity-forms-helper-text">{helperText}</span>
            }
        </div>
    )
}

export { SimplicityFormLongAnswer }
