import React, { Fragment } from 'react'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { exists, isNull, isObject, objectHasPropertyString } from 'cms/utils/empty-exists'
import { Header } from 'components/header'
import { Footer } from 'components/footer'
// import { Component } from 'cms/fields/component'
import { Logger } from 'cms/utils/logger'
import { DefaultError } from 'cms/layout/error/default-error'

const ErrorLayout = () => {
    
    const {
        error,
        // errorWebpage,
        // errorModel,
        // errorFields,
        // setModel,
    } = useSimpliCity()
    // const { title } = errorModel || {}
    // const { body } = errorFields || {}
    const wasNull = isNull(error)
    const didExist = exists(error)
    const wasErrorObject = isObject(error) && objectHasPropertyString(error, 'message')
    const hasError = !wasNull && didExist && wasErrorObject
    let displayedError = ''
    if (hasError) {
        Logger.warn('[ErrorLayout] Error Occurred: ' + error?.message)
        Logger.warn('[ErrorLayout] Error trace: ' + error?.trace)
        Logger.warn('[ErrorLayout] Error stack: ' + error?.stack)
        Logger.error(error)
        Logger.flush()
        displayedError = hasError ? JSON.stringify(error, null,2) : ''
        // need to set model for Richtext component internals
        // setModel(errorModel)
    } else {
        return null
    }
    const title = 'Sorry, that page is no longer available'
    
    return (
        <Fragment>
            <Header />
            <div id="layout" className="layout">
                
                <div id="layout-fullwidth" className="layout-fullwidth layout-margins">
                    
                    <div className="main-content">
                        
                        {/* Title */}
                        <h1 className="page-title">{title}</h1>
                        
                        <DefaultError />

                        {/*<div className="region region-content">*/}
                        {/*    <div className="content wysiwyg">*/}
                        {/*        <div id="block-system-main" className="block block-system">*/}
                        {/*            <Component*/}
                        {/*                webpage={errorWebpage}*/}
                        {/*                field={body}*/}
                        {/*            />*/}
                        {/*        */}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    
                    </div>
                    
                    
                    <div>
                        {/*<div className='homepage-wrapper'>*/}
                        {/*    <div style={{*/}
                        {/*        width: '100%',*/}
                        {/*        minHeight: '50vh',*/}
                        {/*        gap: '24px',*/}
                        {/*        display: 'flex', flexDirection: 'column',*/}
                        {/*        justifyContent: 'center',*/}
                        {/*        alignItems: 'center',*/}
                        {/*        padding: '40px'*/}
                        {/*    }}>*/}
                        {/*        */}
                        {/*        <h1>This service is not available.</h1>*/}
                        {/*        */}
                        {/*{hasError && Config.showErrors &&*/}
                        {hasError &&
                            <details>
                                <summary>
                                    <div style={{
                                        display: "flex", justifyContent: "flex-end", padding: '20px, 20px, 20px, 20px' }}>
                                        Error Details
                                    </div>
                                </summary>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{
                                        border: "1px solid black",
                                        borderRadius: "8px",
                                        overflow: "auto",
                                        width: "100%",
                                        maxWidth: "1200px",
                                        // height: '600px',
                                        padding: "40px"
                                    }}>
                                    <pre style={{ width: "1000px" }}>
                                        {displayedError}
                                    </pre>
                                    </div>
                                </div>
                            </details>
                        }
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                
                
                </div>
            </div>
            
            
            <Footer />
        </Fragment>
    )
}
ErrorLayout.displayName = 'ErrorLayout'
export { ErrorLayout }

