import { useForm } from '../context/hooks/use-form'

/**
 * @param {FormQuestion[]} stepQuestions
 * @param {() => void} onNavigate
 */
const useStepControls = (stepQuestions, onNavigate) => {

    const {
        nextStep,
        prevStep,
        validate
    } = useForm()

    const next = () => {
        // Check all valid questions
        const isValid = validate(stepQuestions)

        //  if the form is valid, go to the next step
        if (isValid) {
            nextStep()
        }
        onNavigate()
    }

    const prev = () => {
        prevStep()
        onNavigate()
    }

    return {
        next,
        prev
    }
}

export { useStepControls }
