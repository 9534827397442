import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/model`

export const getModels = async function (token, params) {
    const method = "GET";
    let path = `${endpoint}`;
    if (params) {
        const page = params.page;
        const pageSize = params.pageSize;
        const query = qs.stringify(
            { pagination: { page, pageSize } },
            { encodeValuesOnly: true }
        );
        path = `${path}?${query}`;
    }
    const headers = { Authorization: `Bearer ${token}` };
    const payload = undefined;
    return await makeRequest(method, base_url, path, headers, payload);
};
// export const getDataModels = async function (token, params) {
//     const method = "GET";
//     let path = `${endpoint}/data`;
//     if (params) {
//         const page = params.page;
//         const pageSize = params.pageSize;
//         const query = qs.stringify(
//             { pagination: { page, pageSize } },
//             { encodeValuesOnly: true }
//         );
//         path = `${path}?${query}`;
//     }
//     const headers = { Authorization: `Bearer ${token}` };
//     const payload = undefined;
//     return await makeRequest(method, base_url, path, headers, payload);
// };
export const searchModels = async function (token, searchTerm, params, filterValue) {
    const method = "GET";
    let path = `${endpoint}/search`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            {
                term: searchTerm,
                type: filterValue,
                pagination: { page, pageSize }
            },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
// export const searchDataModels = async function (token, searchTerm, params, filterValue) {
//     const method = "GET";
//     let path = `${endpoint}/data/search`
//     if (params) {
//         const page = params.page
//         const pageSize = params.pageSize
//         const query = qs.stringify(
//             {
//                 term: searchTerm,
//                 type: filterValue,
//                 pagination: { page, pageSize }
//             },
//             { encodeValuesOnly: true }
//         )
//         path = `${path}?${query}`
//     }
//     const headers = { Authorization: `Bearer ${token}` }
//     const payload = undefined
//     return await makeRequest(method, base_url, path, headers, payload)
// }
export const getModel = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getModelTOC = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/${guid}/toc`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getRecentModelsFeed = async function (token, options) {
    const method = "GET"
    let path
    if (options) {
        const { page, pageSize, type, term, category, categoryName, sort, direction, range } = options
        if (!type) {
            throw Error(`Bad Request: getRecentModelsFeed failed. missing 'type' parameter.`)
        }
        const query = qs.stringify({
                term,
                category,
                categoryName,
                range,
                sort,
                direction,
                pagination: { page, pageSize }
            },
            { encodeValuesOnly: true }
        )
        path = `${endpoint}/${type}/recent?${query}`
    } else {
        throw Error('Bad Request: getRecentModelsFeed failed. options undefined')
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getRecentEventModelsFeed = async function (token, options) {
    const method = "GET"
    let path
    if (options) {
        const { page, pageSize, term, category, categoryName, days, mode, type } = options
        const query = qs.stringify({
                type,
                term,
                category,
                categoryName,
                days,
                mode,
                pagination: { page, pageSize }
            },
            { encodeValuesOnly: true }
        )
        path = `${endpoint}/recent/event?${query}`
    } else {
        throw Error('Bad Request: getRecentEventModelsFeed failed. options undefined')
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const createModel = async function (token, payload) {
    const method = "POST"
    const path = `${endpoint}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}
export const updateModel = async function (token, guid, payload) {
    const method = "PUT"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}
export const deleteModel = async function (token, guid) {
    const method = "DELETE"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

// export {
//     searchModels,
//     // searchDataModels,
//     getModels,
//     // getDataModels,
//     getModel,
//     createModel,
//     deleteModel,
//     updateModel,
//     getRecentModelsFeed,
//     getRecentEventModelsFeed,
//     getModelTOC
// };
