import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context

// const endpoint = `/${context}/image`
const endpoint = `/${context}/media/images`

export const getImages = async function (token,params) {
    const method = 'GET'
    let path = `${endpoint}`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } }, { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const searchImages = async function (token,searchTerm,params) {
    const method = 'GET'
    let path = `${endpoint}/search`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            {
                term: searchTerm,
                pagination: { page, pageSize }
            },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getImage = async function (token, guid) {
    const method = 'GET'
    let path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getImageByFilePath = async function (token, filepath) {
    const method = 'GET'
    let path = `${endpoint}/filepath/${filepath}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const createImage = async function (token, payload) {
    const method = 'POST'
    let path = `${endpoint}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
export const updateImage = async function (token, guid, payload) {
    const method = 'PUT'
    let path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
export const deleteImage = async function (token, guid) {
    const method = 'DELETE'
    let path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const purgeAllImages = async function (token) {
    const method = 'DELETE'
    const path = `${endpoint}/purge/all`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const purgeImages = async function (token, paths) {
    const method = 'DELETE'
    const path = `${endpoint}/purge`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = { paths }
    return await makeRequest(method, base_url, path, headers, payload)
}
