// named "selected" solid icons in the kit
import {
    faAdd,
    faArrowDown,
    faArrowDownToLine,
    faArrowRight,
    faArrowToBottom,
    faArrowUpRightFromSquare,
    faBars,
    faBasketballHoop,
    faBriefcase,
    faCheck,
    faCheckCircle,
    faChevronCircleDown,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronCircleUp,
    faChevronDoubleDown,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDoubleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronsDown,
    faChevronsLeft,
    faChevronSquareDown,
    faChevronSquareLeft,
    faChevronSquareRight,
    faChevronSquareUp,
    faChevronsRight,
    faChevronsUp,
    faChevronUp,
    faCircleCheck,
    faCircleChevronDown,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleChevronUp,
    faCircleExclamation,
    faCircleInfo,
    faCircleMinus,
    faCirclePlus,
    faCircleXmark,
    faClipboardCheck,
    faClose,
    faCloudArrowUp,
    faComment,
    faDiamondExclamation,
    faDownload,
    faEnvelopeOpenDollar,
    faEye,
    faFilePdf,
    faGavel,
    faHand,
    faInfo,
    faLegal,
    faMagnifyingGlass,
    faMessage,
    faMessageCheck,
    faMessageExclamation,
    faMessageMiddle,
    faMessageMiddleTop,
    faMinus,
    faPeopleLine,
    faPlayCircle,
    faPlus,
    faQuoteLeft,
    faQuoteRight,
    faRecycle,
    faSearch,
    faSortDown,
    faSortUp,
    faSpinner,
    faSquareChevronDown,
    faSquareChevronLeft,
    faSquareChevronRight,
    faSquareChevronUp,
    faSquareExclamation,
    faSquareInfo,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashAlt,
    faTrashCan,
    faTrashCanSlash,
    faTrashSlash,
    faTree,
    faTriangleExclamation,
    faXmark,
} from '@awesome.me/kit-f6a7b16729/icons/classic/solid'

const solid = {
    faAdd,
    faArrowDown,
    faArrowDownToLine,
    faArrowRight,
    faArrowToBottom,
    faArrowUpRightFromSquare,
    faBars,
    faBasketballHoop,
    faBriefcase,
    faCheck,
    faCheckCircle,
    faChevronCircleDown,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronCircleUp,
    faChevronDoubleDown,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDoubleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronsDown,
    faChevronsLeft,
    faChevronSquareDown,
    faChevronSquareLeft,
    faChevronSquareRight,
    faChevronSquareUp,
    faChevronsRight,
    faChevronsUp,
    faChevronUp,
    faCircleCheck,
    faCircleChevronDown,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleChevronUp,
    faCircleExclamation,
    faCircleInfo,
    faCircleMinus,
    faCirclePlus,
    faCircleXmark,
    faClipboardCheck,
    faClose,
    faCloudArrowUp,
    faComment,
    faDiamondExclamation,
    faDownload,
    faEnvelopeOpenDollar,
    faEye,
    faFilePdf,
    faGavel,
    faHand,
    faInfo,
    faLegal,
    faMagnifyingGlass,
    faMessage,
    faMessageCheck,
    faMessageExclamation,
    faMessageMiddle,
    faMessageMiddleTop,
    faMinus,
    faPeopleLine,
    faPlayCircle,
    faPlus,
    faQuoteLeft,
    faQuoteRight,
    faRecycle,
    faSearch,
    faSortDown,
    faSortUp,
    faSpinner,
    faSquareChevronDown,
    faSquareChevronLeft,
    faSquareChevronRight,
    faSquareChevronUp,
    faSquareExclamation,
    faSquareInfo,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashAlt,
    faTrashCan,
    faTrashCanSlash,
    faTrashSlash,
    faTree,
    faTriangleExclamation,
    faXmark,
}

export { solid }
