import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faArrowUpRightFromSquare } from 'icons'
import { icons } from 'icons'

const BulletPointLink = (props) => {
    const { link, style, format } = props || {}
    const { link_type } = link || {}
    const isExternalLink = link_type === "external"
    let size = "1x"  // scales with the font

    return (
        <div className="simplicity-text-link-item">
            <li>
                <a
                    href={link?.url}
                    className={`simplicity-bullet-point-link ${style} ${format} ${
                        isExternalLink ? "link--icon link--icon--left" : ""
                    }`}
                    target={link?.target}
                >
                    {link?.text}

                    {isExternalLink && (
                        <span className="simplicity-link-icon">
                            <FontAwesomeIcon icon={icons.regular.faArrowUpRightFromSquare} size={size} />
                        </span>
                    )}
                </a>
            </li>
        </div>
    );
};

export { BulletPointLink };
