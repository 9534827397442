import React, {useState, useEffect} from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { exists, isObject, isStringNotEmpty, objectHasPropertyString } from 'cms/utils/empty-exists'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { BulletPointLink } from 'cms/links/link/bullet/bullet-point'
import { CTAStyleLink } from 'cms/links/link/cta/cta-link'
import { TextLink } from 'cms/links/link/text/text-link'
import { UnderlineLink } from 'cms/links/link/underline/underline-link'
import { LINK_FORMATS } from "cms/links/helpers";

const Link = (props) => {
    
    const { link: linkObj, style, format = 'text-link' } = props || {}
    const [link, setLink] = useState()
    const {token} = useAuth()
    
    const isCTALink = format === LINK_FORMATS.CTA
    const isTextLink = format === LINK_FORMATS.TEXT
    const isBulletLink = format === LINK_FORMATS.BULLET_POINT
    const isUnderlineLink = format === LINK_FORMATS.UNDERLINE
    
    const loadLink = async (guid) => {
        if (guid && guid.length > 30) {
            const _link = await SimpliCitySDK.link.findOne(token, guid)
            setLink(_link)
            return _link
        }
    }
    useEffect(() => {
        const load = async (guid) => {
            if (isStringNotEmpty(guid)) {
                const _link = await loadLink(guid)
                setLink(_link)
            }
        }
        // load once
        if (!isObject(link) || !objectHasPropertyString(link, 'guid')) {
            load(linkObj?.link)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkObj.link])
    
    return (
        <>
            {exists(link) && isTextLink &&
                <TextLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
            {exists(link) && isUnderlineLink &&
                <UnderlineLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
            {exists(link) && isBulletLink &&
                <BulletPointLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
            {exists(link) && isCTALink &&
                <CTAStyleLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
        </>
    )
}

export { Link }
