import React, { useEffect, useState } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { FeedStyleClear } from 'cms/shared/feeds/homepage/style-clear'
import { FeedStyleHeader } from 'cms/shared/feeds/homepage/style-header'

const HomeFeed = (props) => {
    
    const {
        quantity,
        type,
        category,
        categoryName,
        title,
        linkText,
        linkText2,
        path,
        path2,
        style = 'header',
        range,
        empty_message,
    } = props
    
    const { token } = useAuth()
    const [models,setModels] = useState([])
    const [pagination,setPagination] = useState()
    const [loaded,setLoaded] = useState(false)
    const term = undefined
    
    const fetchFeed = async (token, quantity, timer) => {
        const page = 1
        const pageSize = quantity
        const options = { page, pageSize, type, term, category, categoryName, range }
        const { pagination, results: models } = await SimpliCitySDK.model.findFeed(token, options)
        setModels(models)
        setPagination(pagination)
        setLoaded(true)
        clearInterval(timer)
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!loaded) {
                setLoaded(true)
            }
        }, 12000)
        fetchFeed(token, quantity, timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token,quantity])
    
    return (
        <>
            {style === 'clear' &&
                <FeedStyleClear
                    type={type}
                    title={title}
                    // count={count}
                    models={models}
                    pagination={pagination}
                    linkText={linkText}
                    linkText2={linkText2}
                    path={path}
                    path2={path2}
                    empty_message={empty_message}
                    loaded={loaded}
                />
            }
            {style === 'header' &&
                <FeedStyleHeader
                    title={title}
                    // count={count}
                    models={models}
                    pagination={pagination}
                    linkText={linkText}
                    path={path}
                    empty_message={empty_message}
                    loaded={loaded}
                />
            }
        </>
    )
}

export { HomeFeed }
