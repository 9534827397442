
/**
 * @param {boolean} isRequired
 * @param {QuestionAddressValue} address
 */
const createAddressErrorMap = (isRequired, address) => {
    /**
     * @type {AddressErrorMap}
     */
    const errorMap = {
        street_address: false,
        city: false,
        province: false,
        postcode: false
    }

    if (isRequired) {
        errorMap.street_address = !address.street_address
        errorMap.city = !address.city
        errorMap.province = !address.province
        errorMap.postcode = !address.postcode
    }

    return errorMap
}

export { createAddressErrorMap }
