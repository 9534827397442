import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.public.api_base_url
const context = Config.public.context
const endpoint = `/${context}/website`

export const getWebsites = async function (token, params) {
    const method = 'GET'
    let path = `${endpoint}`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
// export const searchWebsites = async function (token, searchTerm, params) {
//     const method = 'GET'
//     let path = '${endpoint}/search'
//     if (params) {
//         const page = params.page
//         const pageSize = params.pageSize
//         const query = qs.stringify(
//             {
//                 term: searchTerm,
//                 pagination: { page, pageSize }
//             },
//             { encodeValuesOnly: true }
//         )
//         path = `${path}?${query}`
//     }
//     const headers = {"Authorization": `Bearer ${token}`}
//     const payload = undefined
//     return await makeRequest(method, base_url, path, headers, payload)
// }
export const getWebsite = async function (token, guid) {
    const method = 'GET'
    const path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getWebsitesForOrganization = async function (token, organization) {
    const method = 'GET'
    const path = `${endpoint}/organization/${organization}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getWebsiteForApplication = async function (token, application) {
    const method = 'GET'
    const app = `${endpoint}/application/${application}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, app, headers, payload)
}
export const getWebsiteForDomain = async function (token, domain) {
    const method = 'GET'
    const _domain = `${endpoint}/domain/${domain}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, _domain, headers, payload)
}
