import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icons } from 'icons'

export const common_tasks_data = [
    {
        text: 'Report a problem',
        icon: <FontAwesomeIcon icon={icons.regular.faDiamondExclamation} size="2x" fixedWidth />,
        url: '/government-administration/report-problem'
    },
    {
        text: 'Apply for a permit',
        icon: <FontAwesomeIcon icon={icons.regular.faClipboardCheck} size="2x" fixedWidth />,
        url: '/government-administration/view-list-all-permits-and-licences'
    },
    {
        text: 'Look up collection day',
        icon: <FontAwesomeIcon icon={icons.regular.faTrashAlt} size="2x" fixedWidth />,
        url: '/your-home-property/collection-schedules'
    },
    {
        text: 'Apply for a job',
        icon: <FontAwesomeIcon icon={icons.regular.faBriefcase} size="2x" fixedWidth />,
        url: '/government-administration/jobs-and-volunteering'
    },
    {
        text: 'View Council materials',
        icon: <FontAwesomeIcon icon={icons.regular.faGavel} size="2x" fixedWidth />,
        url: '/government-administration/meeting-agendas-and-minutes'
    },
    {
        text: 'Volunteer for a committee',
        icon: <FontAwesomeIcon icon={icons.regular.faHand} size="2x" fixedWidth />,
        url: '/our-government/volunteer-serve-committee'
    }
    // {
    //     text: 'View the road works map',
    //     icon: <FontAwesomeIcon icon={faTrafficCone} size="2x" fixedWidth />,
    //     url: '/streets-transportation/infrastructure-and-construction-projects-may-impact-local-traffic'
    // }
]
