
export const categories = [
    {
        title: "- Any -",
        redirect: "/dnv-stories?field_blog_category_tid=All",
        path: "/dnv-stories",
        slug: "dnv-stories"
    },
    {
        title: "Community involvement",
        redirect: "/dnv-stories?field_blog_category_tid=39",
        path: "/dnv-stories/community-involvement",
        slug: "community-involvement"
    },
    {
        title: "Fire and Rescue Services",
        redirect: "/dnv-stories?field_blog_category_tid=57",
        path: "/dnv-stories/fire-and-rescue-services",
        slug: "fire-and-rescue-services"
    },
    {
        title: "Housing",
        redirect: "/dnv-stories?field_blog_category_tid=58",
        path: "/dnv-stories/housing-stories",
        slug: "housing-stories"
    },
    {
        title: "Streets and transportation",
        redirect: "/dnv-stories?field_blog_category_tid=56",
        path: "/dnv-stories/streets-and-transportation",
        slug: "streets-and-transportation"
    },
    {
        title: "Economy and finance",
        redirect: "/dnv-stories?field_blog_category_tid=41",
        path: "/dnv-stories/economy-and-finance",
        slug: "economy-and-finance"
    },
    {
        title: "Environment and sustainability",
        redirect: "/dnv-stories?field_blog_category_tid=43",
        path: "/dnv-stories/environment-and-sustainability",
        slug: "environment-and-sustainability"
    },
    {
        title: "Growth and development",
        redirect: "/dnv-stories?field_blog_category_tid=42",
        path: "/dnv-stories/growth-and-development",
        slug: "growth-and-development"
    },
    {
        title: "Parks and green spaces",
        redirect: "/dnv-stories?field_blog_category_tid=47",
        path: "/dnv-stories/parks-and-green-spaces",
        slug: "parks-and-green-spaces"
    },
    {
        title: "Projects and infrastructure",
        redirect: "/dnv-stories?field_blog_category_tid=45",
        path: "/dnv-stories/projects-and-infrastructure",
        slug: "projects-and-infrastructure"
    },
]

export const getCategory = (slug) => {
    const category = categories.find((category) => category.slug === slug)
    return category
}
export const evaluateIsCategory = (slug) => {
    const category = getCategory(slug)
    if (category?.slug === 'all') {
        return false
    }
    return !!category
}
