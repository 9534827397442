import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/model`

const getModelFeatures = async function (token, options) {
    const method = "GET"
    let path = `${endpoint}/feature`
    if (options) {
        const { page, pageSize } = options
        const query = qs.stringify(
            { pagination: { page, pageSize } },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const getModelFeaturesByModel = async function (token, guid, options) {
    const method = "GET"
    let path = `${endpoint}/${guid}/feature`
    if (options) {
        const { page, pageSize } = options
        const query = qs.stringify(
            { pagination: { page, pageSize } },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const getModelFeature = async function (token, guid, feature) {
    const method = "GET"
    const path = `${endpoint}/${guid}/feature/${feature}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const createModelFeature = async function (token, guid, payload) {
    const method = "POST"
    const path = `${endpoint}/${guid}/feature`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}
const updateModelFeature = async function (token, guid, feature, payload) {
    const method = "PUT"
    const path = `${endpoint}/${guid}/feature/${feature}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}
const deleteModelFeature = async function (token, guid, feature) {
    const method = "DELETE"
    const path = `${endpoint}/${guid}/feature/${feature}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

export {
    getModelFeatures,
    getModelFeaturesByModel,
    getModelFeature,
    createModelFeature,
    deleteModelFeature,
    updateModelFeature,
}
