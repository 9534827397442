import Link from '@mui/material/Link'
import cn from 'clsx'
import { useRichTextToHtml } from 'cms/rich-text/use-rich-text-to-html'
import { useForm } from 'cms/forms/context/hooks/use-form'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const baseClass = 'forms-notification'
const statusClassMap = {
    success: 'forms-notification--success',
    error: 'forms-notification--error',
    caution: 'forms-notification--caution',
}

/**
 * @param {object} props 
 * @param {keyof statusClassMap} props.status 
 * @param {string} props.message 
 * @param {string} props.title 
 */
const StatusBanner = (props) => {
    
    const { status, message, title } = props
    const classes = cn(baseClass, statusClassMap[status])
    const html = useRichTextToHtml(message)
    const { resetForm } = useForm()
    const showReset = status !== 'caution'
    
    return (
        <>
            <div className={classes}>
                <div>
                    {status === 'success' &&
                        <FontAwesomeIcon
                            transform="grow-6 left-20"
                            icon={`fa-solid fa-circle-check`}
                        />
                    }
                    {status === 'error' &&
                        <FontAwesomeIcon
                            transform="grow-6 left-20"
                            icon={`fa-solid fa-circle-xmark`}
                        />
                    }
                    {status === 'caution' &&
                        <FontAwesomeIcon
                            transform="grow-6 left-20"
                            icon={`fa-solid fa-circle-exclamation`}
                        />
                    }
                </div>
                <div>
                    <h3 className='forms-notification__title'>{title}</h3>
                    {message && (
                        <div
                            className='forms-notification__message'
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                    )}
                </div>
            </div>
            {showReset && (
                <Link
                    onClick={resetForm}
                    style={{ cursor: 'pointer' }}
                >
                    Go back to the form
                </Link>
            )}
        </>
    )
}

export default StatusBanner
