import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.public.api_base_url
const context = Config.public.context
const endpoint = `/${context}/webpage`

export const getWebpages = async function (token, params) {
    const method = "GET"
    let path = `${endpoint}`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
// export const searchWebpages = async function (token, searchTerm, params) {
//     const method = "GET"
//     let path = `${endpoint}/search`
//     if (params) {
//         const page = params.page
//         const pageSize = params.pageSize
//         const query = qs.stringify(
//             {
//                 term: searchTerm,
//                 pagination: { page, pageSize },
//             },
//             { encodeValuesOnly: true }
//         );
//         path = `${path}?${query}`
//     }
//     const headers = { Authorization: `Bearer ${token}` }
//     const payload = undefined
//     return await makeRequest(method, base_url, path, headers, payload)
// }
export const getWebpage = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getWebpageBySlug = async function (token, slug) {
    const method = "GET"
    const path = `${endpoint}/slug/${slug}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getWebpageByPath = async function (token, path) {
    const method = "GET"
    const fullpath = `${endpoint}/path/${path}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, fullpath, headers, payload)
}
export const getWebpageTree = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/tree/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getWebpageParents = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/parents/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getWebpageChildren = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/children/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getWebpageKeywords = async function (token) {
    const method = "GET"
    const path = `${endpoint}/keywords`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
// const getWebpageKeywordsByWebpage = async function (token, guid) {
//     const method = "GET"
//     const path = `${endpoint}/${guid}/keywords`
//     const headers = { Authorization: `Bearer ${token}` }
//     const payload = undefined
//     return await makeRequest(method, base_url, path, headers, payload)
// }