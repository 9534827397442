import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import cn from 'clsx'
import { useForm } from 'cms/forms/context'
import ErrorMsg from 'cms/forms/error-msg'
import { useRequiredValidator } from 'cms/forms/hooks/use-required-validator'
import { useSetQuestionErrors } from 'cms/forms/hooks/use-set-question-errors'
import { useShowError } from 'cms/forms/hooks/use-show-error'
import { OtherLabel } from './common/other-label'

/**
 * @type {QuestionComponent<FormQuestionMultipleChoice>}
 */
const SimplicityFormMultipleChoice = (props) => {
    const { field } = props
    const {
        guid,
        hasDescription,
        description,
        prompt,
        value,
        hasHelperText,
        helperText,
        options,
        isRequired,
        otherOptions: instructions
    } = field
    const { onInputChange } = useForm()

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event
     */
    const handleChange = (event) => {
        const value = event.target.value
        onInputChange(value, field)
    }

    const currentError = useRequiredValidator(
        isRequired,
        value,
        'Please make a selection'
    )

    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    const className = cn('simplicity-forms-field-radios', {
        error: hasError
    })

    return (
        <div className='simplicity-forms-field-group'>
            <label
                htmlFor={`simplicity-forms-multiple-choice-${guid}`}
                className='simplicity-forms-field-label'
            >
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </label>
            {hasDescription && description && <span className='simplicity-forms-description'>{description}</span>}
            {hasError && <ErrorMsg type='error' error={error} />}
            <RadioGroup value={value}>
                {options && options.map((option) => {
                    // `id` is a more recent field; if it doesn't exist, then use the `name`
                    const id = option?.id || option?.name
                    const isChecked = value === option?.value
                    return (
                        <FormControlLabel
                            key={id}
                            id={id}
                            className={className}
                            value={option?.value}
                            control={<Radio />}
                            label={
                                <OtherLabel
                                    guid={guid}
                                    className={className}
                                    id={id}
                                    option={option}
                                    instructions={instructions}
                                    isChecked={isChecked}
                                />
                            }
                            onChange={handleChange}
                        />
                    )
                })
                }
            </RadioGroup>
            {hasHelperText && helperText &&
                <span className='simplicity-forms-helper-text'>{helperText}</span>
            }
        </div>
    )
}

export { SimplicityFormMultipleChoice }
