import { useForm } from 'cms/forms/context'
import { useMemo } from 'react'
import { ErrorMessages } from '../error-messages'
import ErrorMsg from '../error-msg'
import { useSetQuestionErrors } from '../hooks/use-set-question-errors'
import { useShowError } from '../hooks/use-show-error'

const validUrlRegex = /^https?:\/\//

/**
 * @type {QuestionComponent<FormQuestion<string>>}
 */
const SimplicityFormWebsiteUrl = (props) => {

    const { field } = props || {}
    const {
        guid,
        prompt,
        value,
        hasPlaceholderText,
        hasDescription,
        description,
        hasHelperText,
        helperText,
        isRequired,
        required,
    } = field || {}
    const { onInputChange } = useForm()

    const handleChange = (event) => {
        const value = event.target.value
        onInputChange(value, field)
    }

    const currentError = useMemo(
        () => {
            if (isRequired && !value?.length) {
                return ErrorMessages.required()
            }

            if (!validUrlRegex.test(value)) {
                return 'Please enter a valid url that begins with http(s)://'
            }

            return null
        },
        [isRequired, value]
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    return (
        <div className="simplicity-forms-field-group">
            <label className="simplicity-forms-field-label">
                {prompt}
                {!required && <span> (optional)</span>}
            </label>
            {hasDescription && description && <span className="simplicity-forms-description">{description}</span>}
            {/* removed Imask input as it was broken and not doing anything */}
            <input
                type='text'
                id={guid}
                name={guid}
                placeholder={hasPlaceholderText}
                value={value}
                className={hasError ? 'form-field-error-input' : ''}
                onChange={handleChange}
            />
            {hasHelperText && helperText && <span className="simplicity-forms-helper-text">{helperText}</span>}
            {hasError && <ErrorMsg type="error" error={error} />}
        </div>
    )
}

export { SimplicityFormWebsiteUrl }
