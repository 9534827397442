import { Fragment, useEffect, useState } from 'react'
import { RichText } from 'cms/rich-text'
import { exists, isTrue } from 'cms/utils/empty-exists'

const TabContent = (props) => {
    
    const { tab } = props;
    const { richtext, description, isVisible: tabVisible } = tab || {}
    
    const [isVisible, setIsVisible] = useState(tabVisible)

    useEffect(() => {
        setIsVisible(tabVisible)
    }, [tabVisible])

    return (
        <Fragment>
            {isTrue(isVisible) && exists(tab) && (
                <div
                    className="ds-1col entity entity-field-collection-item field-collection-item-field-tabs view-mode-full item clearfix"
                    data-tab-index="1"
                    role="tabpanel"
                    id={tab.guid}
                    aria-hidden="false"
                >
                    <h2 className="item-header sr-only print-only">{tab.title}</h2>
                    <div className="field field-name-field-content item-content wysiwyg">{description}</div>
                    <div className="field field-name-field-content item-content wysiwyg">
                        <RichText richtext={richtext} />
                    </div>
                </div>
            )}
        </Fragment>
    );
}

TabContent.displayName = 'TabContent'
export { TabContent }
