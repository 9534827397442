export const defaultFormSettings = {
    // status of the form: pre-active, active, closed, inactive, archived
    active_status: "pre-active",
    closed_message: "Submissions for this form are closed",

    // closed banner description
    enable_closed_banner: false,
    closed_banner_reference: "",

    // Review step
    enable_review_step: false,
    review_title: "Review your response",
    review_description_reference: "",

    // Notifications
    confirm_sender: "info@simplicitycms.ca",
    confirm_email_field: "email",
    confirm_subject: "",
    confirm_preheader: "",
    confirm_preamble_reference: "",
    enable_captcha: true,
    enable_confirm_notification: false,
    enable_send_confirm_email: false,
    enable_submit_notifications: false,
    recipients_list: "",

    // Success Status
    success_title: "Submission was successful",
    enable_success_description: false,
    success_description_reference: "",

    // Error Status
    failed_title: "Submission was not successful",
    failed_description_reference: "",
    enable_failed_description: false,
}

