import { useForm } from 'cms/forms/context'
import { useEffect, useState } from 'react'

/**
 * @param {FormQuestionAddress} question 
 */
const useAddressValue = (question) => {
    const { onInputChange } = useForm()
    const [address, setAddress] = useState(question.value)

    useEffect(() => {
        onInputChange(address, question)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event 
     * @param {keyof QuestionAddressValue} field 
     */
    const onChangeAnswer = (event, field) => {
        const value = event.target.value

        setAddress((address) => {
            return {
                ...address,
                [field]: value
            }
        })
    }

    return  {
        address,
        onChangeAnswer
    }
}

export { useAddressValue }
