import { Outlet } from 'react-router-dom'
import { BreadcrumbList } from 'cms/shared/breadcrumb'
import { Footer } from 'components/footer'
import { Header } from 'components/header'

// /** @type {import("cms/shared/breadcrumb").Crumb[]} */
// const crumbs = [
//     {  path: '/', title: 'Home' },
//     { title: 'Search Results' }
// ]

const ApplicationLayout = (props) => {
    
    const { crumbs } = props

    return <>
        <Header />

        {/* Child routes are rendered here */}
        <div className="application layout layout-fullwidth">
            
            <BreadcrumbList crumbs={crumbs}/>
            
            <Outlet />
            
        </div>

        <Footer />
    </>
}
export { ApplicationLayout }
