import qs from 'qs'
import { Config } from '../config'
import { makeRequest } from '../common/index'

const base_url = Config.admin.api_base_url

export const getComponent = async function (token, params, filters) {
    const method = "GET"
    let path = "/admin/components"
    if (params) {
        const { pagination, filters, sorting } = params
        const query = qs.stringify(
            {
                pagination: { ...pagination },
                filters: { ...filters },
                sorting: { ...sorting },
            },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getComponentById = async function (token, id) {
    const method = "GET"
    const path = `/admin/component/${id}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getComponentUsage = async function (token, guid) {
    const method = "GET"
    let path = `/admin/components/usage/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers)
}
export const getReusableComponents = async function (token, params, filters) {
    const method = "GET"
    let path = "/admin/components/reusable"
    if (params) {
        const { pagination, filters, sorting } = params
        const query = qs.stringify(
            {
                pagination: { ...pagination },
                filters: { ...filters },
                sorting: { ...sorting },
            },
            { encodeValuesOnly: true }
        );
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const searchComponentsByTerm = async function (token, searchTerm, params) {
    const method = "GET"
    let path = `/admin/components/search/${searchTerm}`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            {
                term: searchTerm,
                pagination: { page, pageSize },
            },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const createComponent = async function (token, paylaod) {
    const method = "POST"
    const path = `/admin/component`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, paylaod)
}
export const updateComponent = async function (token, id, payload) {
    const method = "PUT";
    const path = `/admin/component/${id}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}
export const deleteComponent = async function (token, id) {
    const method = "DELETE"
    const path = `/admin/component/${id}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
