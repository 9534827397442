import { Stepper } from '@mui/material'
import { Fragment } from 'react'
import clsx from 'clsx'

/**
 * @param {object} props
 * @param {number} props.activeStep
 */
const StepsDisplayHeader = (props) => {
    const { steps, activeStep } = props

    const stepIndex = activeStep - 1
    const stepLabel = steps[stepIndex]?.label

    return (
        <>
            {steps &&
                <>
                    <Stepper className='form-steps-numbers-header'>
                        {steps && steps.map((step, index) => (
                            <Fragment key={step?.step}>
                                <div className={`step ${index === stepIndex ? 'active' : stepIndex > index ? 'passed' : ''}`}>{step.step}</div>
                                {index < steps.length - 1 && <div className='step-line' />}
                            </Fragment>
                        ))}
                    </Stepper>
                    <div className='form-step-heading-container'>
                        <h4 className='form-steps-numbers-title'>Step {activeStep} of {steps.length}</h4>
                        <h2 className={clsx(stepLabel ? "simplicity-heading" : "empty-step")}>{stepLabel}</h2>
                    </div>
                </>
            }
        </>
    )
}

export default StepsDisplayHeader
