import React from 'react'
import SimplicityImageListItem from './image_list_item'
import { RichText } from 'cms/rich-text'
import { isArrayNotEmpty, isRichTextNotEmpty, isStringNotEmpty } from "cms/utils/empty-exists";

const ImageListComponent = (props) => {
    
    const { component } = props
    const { image_list, title, description } = component || {}
    
    return (
        <div className={"image-list"}>
            {isStringNotEmpty(title) &&
                <h2>{title}</h2>
            }
            {isRichTextNotEmpty(description) &&
                <RichText
                    richtext={description}
                />
            }
            {isArrayNotEmpty(image_list) &&
                image_list.map((item, index) => (
                    <SimplicityImageListItem
                        key={index}
                        item={item}
                    />
                ))
            }
        </div>
    )
}

ImageListComponent.displayName ='ImageListComponent'
export { ImageListComponent }
