
/**
 * 
 * @param {object} props
 * @param {string} props.label
 * @param {string | number} props.value
 */
const GridColumnSnapshot = (props) => {

    const { label, value } = props

    return (
        <p className="snapshot-grid-column">{label}: {value}</p>
    )
}

export { GridColumnSnapshot }
