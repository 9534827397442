import React from 'react'
import { TableOfContents } from 'cms/shared/toc/table-of-contents'

const TocComponent = (props) => {
    
    const { component } = props
    const {
        richtext,
    } = component || {}
    
    return(
        <>
            <TableOfContents richtext={richtext} />
        </>
    )
}

TocComponent.displayName = 'TocComponent'
export { TocComponent }
