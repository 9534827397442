// import { faSearch } from 'icons'
import { icons } from 'icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'

/**
 * @type {import('react').FC<StaticLinkProps>}
 */
export const StaticLink = (props) => {
    const { href, children } = props

    return (
        <p>
            <FontAwesomeIcon
                icon={icons.regular.faSearch}
                size="1x"
                color="#757575"
                fixedWidth
            />
            <NavLink to={href}>
                {children}
            </NavLink>
        </p>
    )
}

/**
 * @typedef {object} StaticLinkProps
 * @property {string} href
 * @property {string} children
 */
