import qs from 'qs'
import { Config } from '../config'
import { makeRequest } from "../common/index"

const base_url = Config.public.api_base_url
const context = Config.public.context
const endpoint = `/${context}/seo`

export const getSeo = async function (token, params) {
  const method = "GET"
  let path = `${endpoint}`
  if (params) {
    const page = params.page
    const pageSize = params.pageSize
    const query = qs.stringify(
      { pagination: { page, pageSize } },
      { encodeValuesOnly: true }
    )
    path = `${path}?${query}`
  }
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
}
export const getSeoByGuid = async function (token, guid) {
  const method = "GET"
  const path = `${endpoint}/${guid}`
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
}
export const getSeoByWebpageGuid = async function (token, guid) {
  const method = "GET"
  const path = `${endpoint}/webpage/${guid}`
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
}
