import React from "react"
import { HomeBanner } from 'layout/homepage/services-banner/carousel'
// import { CommonTasks } from 'layout/homepage/services-banner/common-tasks'
import { CommonTasksStatic } from 'layout/homepage/services-banner/common-tasks-static'

const HomeServicesBanner = () => {
    
    return (
        <div className="home-top-section">
            <HomeBanner />
            {/*<CommonTasks />*/}
            <CommonTasksStatic />
        </div>
    )
}

export { HomeServicesBanner }
