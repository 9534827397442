import {
    checkToken,
    checkTokenExpiry,
    decodeToken,
    handleJSON,
    makeRequest,
    parseParams,
    TOKEN
} from "./common"
import {
    login,
    me,
    register,
    requestAccessToken,
    verifyToken,
} from "./account"
import {
    publishLive,
    unpublishLive,
    publishPreview,
    unpublishPreview,
    getAlert,
    getCurrentAlert,
    getCurrentAlertForModel,
} from "./alert"
import {
    createApplication,
    deleteApplication,
    getApplication,
    getApplications,
    searchApplications,
    updateApplication,
} from "./application"
import {
    getAttachments,
    searchAttachments,
    getAttachment,
    getAttachmentByFilePath,
    generateAttachmentSasTokenPublic,
    // These are public - does admin need them?
    createAttachmentPublic,
    deleteAttachmentPublic,
} from './attachment'
import {
    createComponent,
    deleteComponent,
    getComponent,
    getComponentById,
    getComponentUsage,
    getReusableComponents,
    searchComponentsByTerm,
    updateComponent,
} from "./components"
import {
    getDocuments,
    searchDocuments,
    getDocument,
    getDocumentByFilePath,
    createDocument,
    updateDocument,
    deleteDocument,
    renameDocument,
    archiveDocument,
    purgeDocuments,
    purgeAllDocuments,
} from './document'
import {
    getExcerpts,
    // searchExcerpts,
    getExcerpt,
    createExcerpt,
    updateExcerpt,
    deleteExcerpt,
} from "./excerpt"
import {
    getSchemaForm,
    getSchemaForms,
    getSchemaFormKeywords,
    createSchemaForm,
    duplicateSchemaForm,
    updateSchemaForm,
    deleteSchemaForm,
    searchSchemaForms,
    getFormPlacements,
} from './form'
import {
    createForm,
    getFormResults,
    getFormResult,
    getAllFormResults,
    getFormResultsAsCSV,
    deleteFormResponse,
} from "./form-response"
import {
    getFormSetting,
    getFormSettings,
    createFormSettings,
    createFormSetting,
    updateFormSettings,
    updateFormSetting,
    deleteFormSetting,
} from "./form-settings"
import {
    createImage,
    deleteImage,
    getImage,
    getImageByFilePath,
    getImages,
    searchImages,
    updateImage,
    purgeAllImages,
    purgeImages
} from "./image"
import {
    createLayout,
    deleteLayout,
    getLayout,
    getLayouts,
    getLayoutsForModel,
    // searchLayouts,
    updateLayout,
} from "./layout"
import {
    getLink,
    getLinks,
    searchLinks,
    createLink,
    deleteLink,
    updateLink,
} from "./link"
import {
    generateDocumentSasToken,
    generateImageSasToken,
} from "./media"
import {
    searchModels,
    getModels,
    getModel,
    createModel,
    deleteModel,
    updateModel,
    getRecentModelsFeed,
    getRecentEventModelsFeed,
    getModelTOC,
} from './model'
import {
    getModelFeatures,
    getModelFeaturesByModel,
    getModelFeature,
    createModelFeature,
    deleteModelFeature,
    updateModelFeature,
} from './model_feature'
// import {
//     searchNodes,
//     getNodes,
//     getNode,
//     getRecentNodes,
// } from "./node"
import {
    createOrganization,
    deleteOrganization,
    getOrganization,
    getOrganizations,
    searchOrganizations,
    updateOrganization,
} from "./organization"
import {
    getOrgSettings,
    getOrgSetting,
    createOrgSetting,
    updateOrgSetting,
    deleteOrgSettings,
} from './organization-settings'
import {
    getPermission,
    getPermissions,
    getRolesForPermission,
    createPermission,
    updatePermission,
    deletePermission,
} from './permission'
import {
    getStatus,
    getMyModifications,
    getModifications,
    doneEditSave,
    publishCurrentChangeSet,
    addToChangeSet,
    removeFromChangeSet,
    getChangeSet,
    getCurrentChangeSet,
    publishChangeSet,
    publishCurrentModifications,
} from './publishing'
import {
    createRedirect,
    deleteRedirect,
    getRedirect,
    getRedirects,
    updateRedirect,
} from "./redirects"
import {
    createRole,
    deleteRole,
    getRole,
    getRoles,
    searchRoles,
    updateRole,
    getRolesForProfile,
} from './role'
import {
    getSchemaModels,
    getSchemaModel,
    getSchemaModelByType,
    createSchemaModel,
    addFieldToSchemaModel,
    searchSchemaModel,
    updateSchemaModel,
    updateSchemaModelTOC,
    deleteSchemaModel,
} from "./schema-model"
import {
    createSeo,
    deleteSeo,
    getSeo,
    getSeoByGuid,
    getSeoByWebpageGuid,
    updateSeo,
} from "./seo"
import {
    createTarget,
    deleteTarget,
    getTarget,
    getTargets,
    updateTarget,
} from "./target"
import {
    createUser,
    deleteUser,
    getUser,
    getUsers,
    searchUsers,
    updateUser,
} from "./users"
import {
    createWebpage,
    deleteWebpage,
    getUnparentedWebpages,
    getWebpage,
    getWebpageByPath,
    getWebpageBySlug,
    getWebpageChildren,
    getWebpageParents,
    getWebpages,
    getWebpageTree,
    searchWebpages,
    updateWebpage,
    reorderChildren,
    getWebpageKeywords,
    getWebpageKeywordsByWebpage,
} from "./webpage"
import {
    createWebsite,
    deleteWebsite,
    getWebsite,
    getWebsiteForApplication,
    getWebsites,
    getWebsitesForOrganization,
    getWebsiteForDomain,
    searchWebsites,
    updateWebsite,
} from "./website"
import { Logger } from './common/logger'


// ==========================================================//

const SimpliCityAdminSDK = {
    check: function () {
        Logger.debug("-----------------------------------------------------")
        Logger.debug("      SimpliCitySDK (Admin) located!")
        Logger.debug("-----------------------------------------------------")
        Logger.flush()
        return true
    },
    account: {
        requestAccessToken,
        login,
        me,
        register,
        verifyToken,
    },
    alert: {
        findOne: getAlert,
        findCurrent: getCurrentAlert,
        findCurrentForModel: getCurrentAlertForModel,
        publishLive: publishLive,
        unpublishLive: unpublishLive,
        publishPreview: publishPreview,
        unpublishPreview: unpublishPreview,
    },
    application: {
        find: getApplications,
        findOne: getApplication,
        searchApplications: searchApplications,
        create: createApplication,
        delete: deleteApplication,
        update: updateApplication,
    },
    attachment: {
        getToken: generateAttachmentSasTokenPublic,
        find: getAttachments,
        search: searchAttachments,
        findOne: getAttachment,
        findOneByFilePath: getAttachmentByFilePath,
        create: createAttachmentPublic,
        delete: deleteAttachmentPublic,
    },
    component: {
        find: getComponent,
        findOne: getComponentById,
        create: createComponent,
        delete: deleteComponent,
        update: updateComponent,
        searchComponentsByTerm: searchComponentsByTerm,
        findReusableComponents: getReusableComponents,
        getComponentUsage: getComponentUsage,
    },
    documents: {
        getToken: generateDocumentSasToken,
        find: getDocuments,
        search: searchDocuments,
        findOne: getDocument,
        findOneByFilePath: getDocumentByFilePath,
        create: createDocument,
        update: updateDocument,
        delete: deleteDocument,
        rename: renameDocument,
        archive: archiveDocument,
        purge: purgeDocuments,
        purgeAll: purgeAllDocuments,
    },
    excerpt: {
        findOne: getExcerpt,
        find: getExcerpts,
        update: updateExcerpt,
        delete: deleteExcerpt,
        create: createExcerpt,
    },
    form: {
        findOne: getSchemaForm,
        find: getSchemaForms,
        update: updateSchemaForm,
        create: createSchemaForm,
        delete: deleteSchemaForm,
        duplicate: duplicateSchemaForm,
        findKeywords: getSchemaFormKeywords,
        search: searchSchemaForms,
        findPlacements: getFormPlacements
    },
    formResponse: {
        findOne: getFormResult,
        find: getAllFormResults,
        findResultsByForm: getFormResults,
        findResultsByFormAsCSV: getFormResultsAsCSV,
        create: createForm,
        delete: deleteFormResponse,
    },
    formSettings: {
        findOne: getFormSetting,
        find: getFormSettings,
        create: createFormSettings,
        createOne: createFormSetting,
        update: updateFormSettings,
        updateOne: updateFormSetting,
        delete: deleteFormSetting,
    },
    images: {
        getToken: generateImageSasToken,
        find: getImages,
        search: searchImages,
        searchImages: searchImages,
        findOne: getImage,
        findOneByFilePath: getImageByFilePath,
        create: createImage,
        update: updateImage,
        delete: deleteImage,
        purge: purgeImages,
        purgeAll: purgeAllImages
    },
    layout: {
        find: getLayouts,
        findForModel: getLayoutsForModel,
        findOne: getLayout,
        // searchApplications: searchLayouts,
        create: createLayout,
        delete: deleteLayout,
        update: updateLayout,
    },
    link: {
        findOne: getLink,
        find: getLinks,
        search: searchLinks,
        create: createLink,
        delete: deleteLink,
        update: updateLink,
    },
    model: {
        search: searchModels,
        find: getModels,
        findOne: getModel,
        create: createModel,
        delete: deleteModel,
        update: updateModel,
        findFeed: getRecentModelsFeed,
        findEventFeed: getRecentEventModelsFeed,
        toc: getModelTOC,
    },
    modelFeatures: {
        find: getModelFeatures,
        findByModel: getModelFeaturesByModel,
        findByModelFeature: getModelFeature,
        create: createModelFeature,
        delete: deleteModelFeature,
        update: updateModelFeature,
    },
    organization: {
        find: getOrganizations,
        findOne: getOrganization,
        search: searchOrganizations,
        create: createOrganization,
        delete: deleteOrganization,
        update: updateOrganization,
    },
    orgSettings: {
        find: getOrgSettings,
        findOne: getOrgSetting,
        create: createOrgSetting,
        delete: deleteOrgSettings,
        update: updateOrgSetting,
    },
    permissions: {
        find: getPermissions,
        findOne: getPermission,
        findRoles: getRolesForPermission,
        create: createPermission,
        update: updatePermission,
        delete: deletePermission,
    },
    publishing: {
        getStatus: getStatus,
        getModifications: getModifications,
        getMyModifications: getMyModifications,
        saveVersion: doneEditSave,
        publishCurrent: publishCurrentChangeSet,
        add: addToChangeSet,
        remove: removeFromChangeSet,
        getChangeSet: getChangeSet,
        getCurrentChangeSet: getCurrentChangeSet,
        publishChangeSet: publishChangeSet,
        publishModifications: publishCurrentModifications,
    },
    redirects: {
        find: getRedirects,
        findOne: getRedirect,
        create: createRedirect,
        update: updateRedirect,
        delete: deleteRedirect,
    },
    role: {
        find: getRoles,
        findOne: getRole,
        search: searchRoles,
        create: createRole,
        update: updateRole,
        delete: deleteRole,
        findRolesForProfile: getRolesForProfile,
    },
    schemaModel: {
        find: getSchemaModels,
        findOne: getSchemaModel,
        findOneByType: getSchemaModelByType,
        search: searchSchemaModel,
        addField: addFieldToSchemaModel,
        create: createSchemaModel,
        update: updateSchemaModel,
        updateTOC: updateSchemaModelTOC,
        delete: deleteSchemaModel,
    },
    seo: {
        find: getSeo,
        findOne: getSeoByGuid,
        findOneByWebpage: getSeoByWebpageGuid,
        create: createSeo,
        update: updateSeo,
        delete: deleteSeo,
    },
    target: {
        find: getTargets,
        findOne: getTarget,
        create: createTarget,
        update: updateTarget,
        delete: deleteTarget,
    },
    users: {
        find: getUsers,
        findOne: getUser,
        search: searchUsers,
        create: createUser,
        update: updateUser,
        delete: deleteUser,
    },
    utils: {
        makeRequest,
        decodeToken,
        checkToken,
        checkTokenExpiry,
        parseParams,
        handleJSON,
        TOKEN,
    },
    webpage: {
        find: getWebpages,
        search: searchWebpages,
        findOne: getWebpage,
        findOneBySlug: getWebpageBySlug,
        findOneByPath: getWebpageByPath,
        findTree: getWebpageTree,
        findParents: getWebpageParents,
        findChildren: getWebpageChildren,
        findUnparented: getUnparentedWebpages,
        create: createWebpage,
        delete: deleteWebpage,
        update: updateWebpage,
        reorderChildren: reorderChildren,
        getKeywords: getWebpageKeywords,
        getKeywordsByWebpage: getWebpageKeywordsByWebpage,
    },
    website: {
        find: getWebsites,
        findOne: getWebsite,
        findForOrganization: getWebsitesForOrganization,
        findForApplication: getWebsiteForApplication,
        findForDomain: getWebsiteForDomain,
        create: createWebsite,
        update: updateWebsite,
        delete: deleteWebsite,
        search: searchWebsites,
    },
}

export default SimpliCityAdminSDK;
