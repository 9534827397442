import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FormSuccess = () => {

    return (
        <div className="simplicity-forms-success-box">
            <div className='iconBox'>
                <FontAwesomeIcon icon={`fa-regular fa-circle-check`} />
            </div>
            <div className='msgBox'>
                <h4>Thank you for your feedback!</h4>
                <p>Your feedback is important to us and helps improve our online services for everyone.</p>
            </div>
        </div>
    )
}

export { FormSuccess }
