
/**
 * @type {QuestionComponent<FormQuestion<null>>}
 */
const SimplicityFormParagraph = (props) => {

    const { field } = props
    const { content } = field || {}

    if (!content) {
        return null
    }

    return (
        <p className="simplicity-forms-paragraph">{content}</p>
    )
}

export { SimplicityFormParagraph };

