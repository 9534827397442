import React, { useEffect } from "react";
import { CircularProgress } from '@mui/material'
import { HomeResultItem } from 'cms/shared/feeds/homepage/result-item'
import { HomeEventResultItem } from 'cms/shared/feeds/homepage/event-result-item'
import { SkipDuringPrerender } from "cms/shared/hyrdation/avoid-prerender";

const FeedStyleClear = (props) => {
    
    const {
        type,
        title,
        models,
        path,
        path2,
        linkText,
        linkText2,
        empty_message,
        loaded,
    } = props
    const eventType = ['model.event', 'model.meeting']
    const isEvent = eventType.includes(type)
    const hasModels = loaded && !!models && models.length > 0

    return (
        <div className='feed-component-container home'>
            <div className='feed-component-header-clear'>
                <div>
                    <h2>{title}</h2>
                </div>
            </div>
            <div className='feed-component-panel-clear'>
                <div style={{ height: "100%" }}>
                    <SkipDuringPrerender>
                        {!loaded &&
                            <div className='spinner-container'><CircularProgress /></div>
                        }
                        {loaded && !hasModels &&
                            <>{empty_message}</>
                        }
                        {models && models.map((model, index) => {
                            const { guid } = model
                            if (isEvent) {
                                return (
                                    <HomeEventResultItem
                                        type={type}
                                        key={`${guid}${index}`}
                                        model={model}
                                    />
                                )
                            } else {
                                return (
                                    <HomeResultItem
                                        type={type}
                                        key={`${guid}${index}`}
                                        model={model}
                                    />
                                )
                            }
                        })}
                    </SkipDuringPrerender>
                </div>
            </div>
            <div className='feed-component-footer'>
                {path &&
                    <div className='feed-component-footer-item'>
                        <a href={path}>
                            {linkText}
                        </a>
                    </div>
                }
                {path2 &&
                    <div className='feed-component-footer-item'>
                        <a href={path2}>
                            {linkText2}
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}

export { FeedStyleClear }
