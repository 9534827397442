import { Hits } from 'react-instantsearch'
import { isEmpty } from 'cms/utils/empty-exists'
import { useSearchInput } from 'cms/search/hooks/use-search-input'
import { AllResultsLink } from './all-results-link'
import { CompactFooter } from './compact-footer'
import { CompactHit } from './compact-hit'
import { StaticLink } from './static-link'

export function CompactResults () {
    const { query } = useSearchInput()

    if (isEmpty(query)) {
        return (
            <div className={'compact-results-container'}>
                <div className={'compact-results-header'}>
                    <h4 className={'compact-results-title'}>
                        Search document collections
                    </h4>
                </div>

                <div className="compact-static-links">
                    <StaticLink href="/government-administration/meeting-agendas-and-minutes">
                        Council agendas and minutes
                    </StaticLink>
                    <StaticLink href="/government-administration/corporate-policies">
                        Corporate policies
                    </StaticLink>
                </div>
            </div>
        )
    }

    return (
        <div className={'compact-results-container'}>
            <div className={'compact-results-header'}>
                <h4 className={'compact-results-title'}>Results</h4>
                <AllResultsLink>
                    View all results <i className="fa-regular fa-arrow-right"></i>
                </AllResultsLink>
            </div>

            <Hits hitComponent={CompactHit} />
            <CompactFooter />
        </div>
    )
}
