const headingRegex = /^h[1-6]$/

/**
 * @type {QuestionComponent<FormQuestionHeading>}
 */
const SimplicityFormHeading = (props) => {
    const { field } = props
    const { headingType: Heading, prompt, description } = field

    const isValidHeading = headingRegex.test(Heading)

    if (!isValidHeading) {
        return null
    }

    return (
        <>
            <Heading>{prompt}</Heading>
            {description && <p className="form-heading-desc">{description}</p>}
        </>
    );
};

export { SimplicityFormHeading };
