import { Button } from '@mui/material';
import React from 'react'

const AccordionExpandCollapse = (props) => {

    const {
        expandAllSections,
        collapseAllSections,
        allClosed,
        allOpen,
    } = props

    return (
        <div className="acc-btn-wrapper">
            <Button
                variant='text'
                className={`acc-option ${allOpen ? 'disabled' : 'open'}`}
                onClick={allOpen ? null : expandAllSections}
                aria-label="Open all accordions in the next group"
            >
                Open all
            </Button>

            <span className="sep">|</span>

            <Button
                variant='text'
                className={`acc-option ${allClosed ? 'disabled' : ''}`}
                onClick={allClosed ? null : collapseAllSections}
                aria-label="Close all accordions in the next group"
            >
                Close all
            </Button>
        </div>
    );
}

AccordionExpandCollapse.displayName = 'AccordionExpandCollapse'
export { AccordionExpandCollapse }
