import { NavLink } from 'react-router-dom'

/**
 * @typedef {object} WebpageHit
 * @property {string} website_guid
 * @property {string} webpage_guid
 * @property {string} nav_title
 * @property {string} path
 * @property {string} name
 * @property {string} type
 * @property {string} title
 * @property {string} description
 * @property {string} banner-image
 * @property {object} banner_image
 */

/**
 * @param {object} props 
 * @param {import("instantsearch.js").Hit<WebpageHit>} props.hit
 */
export function CompactHit (props) {
    const { hit } = props

    return (
        <NavLink to={hit.path}>
            {hit.title}
        </NavLink>
    )
}

