import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/seo`

const getSeo = async function (token, params) {
  const method = "GET"
  let path = `${endpoint}`
  if (params) {
    const page = params.page
    const pageSize = params.pageSize
    const query = qs.stringify(
      { pagination: { page, pageSize } },
      { encodeValuesOnly: true }
    )
    path = `${path}?${query}`
  }
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
}

const getSeoByGuid = async function (token, guid) {
  const method = "GET"
  const path = `${endpoint}/${guid}`
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
}

const getSeoByWebpageGuid = async function (token, guid) {
  const method = "GET"
  const path = `${endpoint}/webpage/${guid}`
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
}

const createSeo = async function (token, payload) {
  const method = "POST"
  const path = `${endpoint}`
  const headers = { Authorization: `Bearer ${token}` }
  return await makeRequest(method, base_url, path, headers, payload)
}

const updateSeo = async function (token, guid, payload) {
  const method = "PUT"
  const path = `${endpoint}/${guid}`
  const headers = { Authorization: `Bearer ${token}` }
  return await makeRequest(method, base_url, path, headers, payload)
}

const deleteSeo = async function (token, guid) {
  const method = "DELETE"
  const path = `${endpoint}/${guid}`
  const headers = { Authorization: `Bearer ${token}` }
  const payload = undefined
  return await makeRequest(method, base_url, path, headers, payload)
}

export {
  getSeo,
  getSeoByGuid,
  getSeoByWebpageGuid,
  createSeo,
  deleteSeo,
  updateSeo,
}
