// export const urlMask = /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/;
export const urlMask = /^((ftp|http|https):\/\/)[1-6]\d{0,5}$/;
export const phoneMask = "+1 000-000-0000";
export const emailMask = /^\S*@?\S*$/;

export const maskPhone = (value) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/g, "($1)$2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  return value;
};
