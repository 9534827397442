import React from 'react'
import { FeedbackHelpful } from 'components/feedback-helpful'
import { CalendarFeedFilter } from 'cms/shared/feeds/calendar'
// import { useAuth } from 'cms/auth/auth/hooks/useAuth'
// import moment from 'moment'
// import { EventAgenda } from 'cms/shared/event-calendar/agenda'

const Events = () => {
    
    // const { token } = useAuth()
    // const type = 'model.event'
    // const [events,setEvents] = useState()
    //
    // const fetchFeed = async (token) => {
    //     const page = 1
    //     const pageSize = 500
    //     // const type = 'model.news'
    //     const term = undefined
    //     const category = undefined
    //     const categoryName = undefined
    //     const options = { page, pageSize, type, term, category, categoryName }
    //     const { results } = await SimpliCitySDK.model.findFeed(token, options)
    //     const _events = []
    //     let count = 0
    //     for (const model of results) {
    //         const fields = {}
    //         count = count + 1
    //         for (const field of model.fields) {
    //             const name = field.name
    //             fields[name] = field
    //         }
    //         const { title, path, guid  } = model
    //         const { event_date, end_date } = fields
    //         const _event_date = getFieldValue(event_date)
    //         const _end_date = getFieldValue(end_date)
    //         // const _event_date = event_date.values[0].value?.date
    //         // const _end_date = end_date.values[0].value?.date
    //         const start = moment.isDate(_event_date)
    //             ? new Date(_event_date)
    //             : undefined
    //         const end = moment.isDate(_end_date)
    //             ? new Date(_end_date)
    //             : undefined
    //         if (start) {
    //             const event = {
    //                 Id: guid,
    //                 // Id: count,
    //                 Subject: title || 'Untitled',
    //                 StartTime: start,
    //                 EndTime: end ? end : moment(start).add(1, 'hour').toDate(), // new Date(2019, 0, 1, 11, 0),
    //                 // CategoryColor: '#1b8898'
    //                 Path: path,
    //             }
    //             _events.push(event)
    //         }
    //     }
    //     setEvents(_events)
    // }
    // useEffect(() => {
    //     fetchFeed(token)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [token])
    // const getField = (model,fieldName) => {
    //     const {fields} = model || {}
    //     if (!fields) {
    //         return
    //     }
    //     return fields?.find((element) => element.name === fieldName)
    // }
    // // eslint-disable-next-line no-unused-vars
    // const getFieldType = (models, fieldName) => {
    //     const firstModel = models[0]
    //     const field = getField(firstModel, fieldName)
    //     const type = field?.type
    //     return type
    // }
    // const getFieldValue = (field) => {
    //     if (!field) {
    //         return
    //     }
    //     const type = field?.type
    //     const {values} = field
    //     let value, date
    //     switch (type) {
    //         case 'field.date':
    //             value = values[0]?.value?.date
    //             date = value ? new Date(value) : undefined
    //             return date
    //         case 'field.text':
    //             value = values[0].value
    //             return value
    //         default:
    //             value = values ? values[0].value : undefined
    //             return value
    //     }
    // }
    
    return (
        <main>
            <h1 style={{ marginTop: '16px' }}>Events</h1>
                <>
                    <div>
                        
                        <CalendarFeedFilter />
                        
                    </div>
                    
                    {/* <EventCalendar events={events} />*/}
                    {/* <EventAgenda events={events} />*/}
                </>
            <FeedbackHelpful />
        </main>
    )
}


export { Events }
