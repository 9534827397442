import { generateHTML, generateText } from '@tiptap/core'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import { assembleTipTapExtensions } from './tiptap-extensions/assemble-extensions'
import { isString } from "cms/utils/empty-exists"
import { isRichTextQuill } from 'cms/rich-text/is-rich-text-quill'

// Set configuration options
//    https://github.com/nozer/quill-delta-to-html
const quillConfig = {
    multiLineParagraph: false,
}
export const DEFAULT_HTML = '<p></p>'

export function quillToHtml (richtext) {
    const converter = new QuillDeltaToHtmlConverter(richtext.ops, quillConfig)
    return converter.convert()
}

export function tipTapToHtml (richtext, options) {
    if (!richtext) {
        return DEFAULT_HTML
    }
    const extensions = assembleTipTapExtensions(options)
    return generateHTML(richtext, extensions)
}
export function tipTapToText (richtext, options) {
    if (!richtext) {
        return ''
    }
    const extensions = assembleTipTapExtensions(options)
    return generateText(richtext, extensions)
}


export function convertToHtml (richtext, options) {
    // handle null, undefined, and empty string
    if (!richtext) {
        return DEFAULT_HTML
    }
    
    // handle a non-empty string passed to it
    if (isString(richtext)) {
        return richtext
    }
    const isQuill = isRichTextQuill(richtext)
    // debugger
    const html = isQuill
        ? quillToHtml(richtext)
        : tipTapToHtml(richtext, options)
    
    return html
}

