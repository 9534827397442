(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), require("@logtail/browser"), require("jwt-decode"), require("moment"), require("qs"), require("@azure/msal-browser"));
	else if(typeof define === 'function' && define.amd)
		define(["axios", "@logtail/browser", "jwt-decode", "moment", "qs", "@azure/msal-browser"], factory);
	else if(typeof exports === 'object')
		exports["SimpliCityAdminSDK"] = factory(require("axios"), require("@logtail/browser"), require("jwt-decode"), require("moment"), require("qs"), require("@azure/msal-browser"));
	else
		root["SimpliCityAdminSDK"] = factory(root["axios"], root["@logtail/browser"], root["jwt-decode"], root["moment"], root["qs"], root["@azure/msal-browser"]);
})(this, (__WEBPACK_EXTERNAL_MODULE_axios__, __WEBPACK_EXTERNAL_MODULE__logtail_browser__, __WEBPACK_EXTERNAL_MODULE_jwt_decode__, __WEBPACK_EXTERNAL_MODULE_moment__, __WEBPACK_EXTERNAL_MODULE_qs__, __WEBPACK_EXTERNAL_MODULE__azure_msal_browser__) => {
return 