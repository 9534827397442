import { createContext, useEffect, useReducer } from 'react'
import { useCookies } from 'react-cookie'
import PropTypes from 'prop-types'
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'
import { LOGIN, LOGOUT } from 'cms/auth/auth/store/reducers/actions'
import authReducer from 'cms/auth/auth/store/reducers/auth'
import LoaderWrapper from 'cms/auth/components/loader-wrapper'
import { CmsConfig } from 'cms/config'
import { Logger } from 'cms/utils/logger'


const API_BASEURL = CmsConfig.API_BASEURL
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    token: null
}
const login_url = CmsConfig.LOGIN_URL
const profile_endpoint = `${API_BASEURL}/admin/account/me`
const cookie_domain = CmsConfig.COOKIE_DOMAIN
const admin_auth_cookie_name = 'simplicity_admin'

/**
 * @type {{ token?: string }}
 */
const defaultContext = null

const SimpliCityAuthContext = createContext(defaultContext)

export const SimpliCityAuthProvider = (props) => {

    const { children } = props
    const [state, dispatch] = useReducer(authReducer, initialState)
    const [cookies, setCookie, removeCookie] = useCookies(admin_auth_cookie_name)
    
    const verifyToken = (serviceToken) => {
        if (!serviceToken) {
            return false
        }
        const decoded = jwtDecode(serviceToken)
        /**
         * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
         */
        const isValid = decoded.exp > Date.now() / 1000
        // Logger.debug(`Verify token: ${serviceToken}`, isValid)
        return isValid
    }

    const setSession = (serviceToken) => {
        if (serviceToken) {
            Logger.debug('SET SERVICE TOKEN')
            Logger.flush()
            // Logger.debug('SET SERVICE TOKEN: ' + serviceToken)
            setCookie(admin_auth_cookie_name, serviceToken,  { path: '/', domain: cookie_domain })
            // localStorage.setItem('serviceToken', serviceToken)
            axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`
        } else {
            Logger.debug('SET SERVICE TOKEN: MISSING' )
            Logger.flush()
            removeCookie(admin_auth_cookie_name)
            // localStorage.removeItem('serviceToken')
            delete axios.defaults.headers.common.Authorization
        }
    }

    useEffect(() => {

        const init = async () => {
            try {
                // const path = window.location.pathname
                const serviceToken = cookies[admin_auth_cookie_name]
                // Logger.debug(`init :: Verify token: ${serviceToken}`)
                // Logger.debug(`init :: path: ${path}`)
                if (serviceToken && verifyToken(serviceToken)) {
                    // Logger.debug(`Running init (check session): ${serviceToken}`)
                    // setSession(serviceToken)
                    const response = await axios({
                        method: 'GET',
                        url: profile_endpoint,
                        headers: { Authorization: `Bearer ${serviceToken}`},
                        timeout: 8000,
                        responseType: 'json'
                    })

                    const user = response.data
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user,
                            token: serviceToken
                        }
                    })

                } else {
                    Logger.debug(`Login: ${login_url}`)
                    Logger.flush()
                    window.location.replace(login_url)
                    // dispatch({
                    //     type: LOGOUT
                    // });
                }
            } catch (err) {
                Logger.error(err);
                // Logger.debug(`Login: ${login_url}`)
                // window.location.replace(login_url)
                // dispatch({
                //     type: LOGOUT
                // });
            }
        };

        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location])

    const logout = () => {
        Logger.debug("logout taking place")
        Logger.flush()
        removeCookie(admin_auth_cookie_name, { path: '/', domain: cookie_domain })
        setSession(null)
        dispatch({type: LOGOUT})
    }

    const getToken = () => {
        const serviceToken = cookies[admin_auth_cookie_name]
        return serviceToken
    }

    const resetPassword = async () => {}

    const updateProfile = () => {}

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <LoaderWrapper/>
    }

    return <SimpliCityAuthContext.Provider
        value={{
            ...state,
            // login,
            logout,
            // register,
            resetPassword,
            updateProfile,
            getToken,
        }}
        >
            {children}
        </SimpliCityAuthContext.Provider>
}

SimpliCityAuthProvider.propTypes = {
    children: PropTypes.node
}

export default SimpliCityAuthContext
