import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/webpage`

const getWebpages = async function (token, params) {
    const method = "GET"
    let path = `${endpoint}`
    if (params) {
        const page = params.page;
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const searchWebpages = async function (token, searchTerm, params) {
    const method = "GET"
    let path = `${endpoint}/search`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            {
                term: searchTerm,
                pagination: { page, pageSize },
            },
            { encodeValuesOnly: true }
        );
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const getWebpage = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const getWebpageBySlug = async function (token, slug) {
    const method = "GET"
    const path = `${endpoint}/slug/${slug}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const getWebpageByPath = async function (token, path) {
    const method = "GET"
    const fullpath = `${endpoint}/path/${path}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, fullpath, headers, payload)
};
const getWebpageTree = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/tree/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const getWebpageParents = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/parents/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const getWebpageChildren = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/children/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const getUnparentedWebpages = async function (token, website) {
    const method = "GET"
    const path = `${endpoint}/unparented/${website}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const createWebpage = async function (token, payload) {
    const method = "POST"
    const path = `${endpoint}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
};
const updateWebpage = async function (token, guid, payload) {
    const method = "PUT"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
};
const deleteWebpage = async function (token, guid) {
    const method = "DELETE"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const reorderChildren = async function (token, guid, payload) {
    const method = "PUT"
    const path = `${endpoint}/${guid}/reorder`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
};
const getWebpageKeywords = async function (token) {
    const method = "GET"
    const path = `${endpoint}/keywords`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
const getWebpageKeywordsByWebpage = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/${guid}/keywords`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
};
export {
    getWebpages,
    searchWebpages,
    getWebpage,
    getWebpageBySlug,
    getWebpageByPath,
    getWebpageTree,
    getWebpageParents,
    getWebpageChildren,
    createWebpage,
    deleteWebpage,
    updateWebpage,
    getUnparentedWebpages,
    reorderChildren,
    getWebpageKeywords,
    getWebpageKeywordsByWebpage,
};
