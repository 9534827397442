import { useMemo } from 'react'

/**
 * @template T
 * @param {T} value
 */
const useInitialValue = (value) => {
    const initialValue = useMemo(() => {
        return structuredClone(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return initialValue
}

export { useInitialValue }
