import { ErrorMessages } from 'cms/forms/error-messages'
import ErrorMsg from 'cms/forms/error-msg'
import { useSetQuestionErrors } from 'cms/forms/hooks/use-set-question-errors'
import { useShowError } from 'cms/forms/hooks/use-show-error'
import { isLatLng } from 'cms/forms/utils/is-lat-lng'
import 'leaflet-geosearch/dist/geosearch.css'
import 'leaflet/dist/leaflet.css'
import { useMemo } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import { DraggableMarker } from './draggable-marker'
import { SearchField } from './search-field'
import { useForm } from 'cms/forms/context'


/**
 * @type {QuestionComponent<FormQuestionLocation>}
 */
const SimplicityFormLocation = ({ field }) => {
    const {
        guid,
        prompt,
        hasDescription,
        description,
        lat,
        lng,
        hasHelperText,
        helperText,
        isRequired,
        required,
        value,
    } = field || {}

    const { onInputChange } = useForm()

    /**
     * @param {LatLng} location 
     */
    const onLocationChange = (location)  => {
        onInputChange(location, field)
    }

    const currentError = useMemo(
        () => {
            const hasLatLng = isLatLng(value)

            if (isRequired && !hasLatLng) {
                return ErrorMessages.required()
            }

            return null
        },
        [isRequired, value]
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    const mapCenter = useMemo(
        () => {
            const hasLatLng = isLatLng(value)
            const center = hasLatLng ? value : [lat, lng]

            return center
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return (

        <div className="simplicity-forms-field-group">
            <label
                htmlFor={`simplicity-forms-map-${guid}`}
                className="simplicity-forms-field-label"
            >
                {prompt}
                {!required && <span> (optional)</span>}
            </label>
            {hasDescription && description && (
                <span className="simplicity-forms-description">{description}</span>
            )}
            <MapContainer
                center={mapCenter}
                zoom={13}
                scrollWheelZoom={false}
                style={{ height: "50vh" }}
                className="simplicity-forms-location"
            >
                <SearchField />
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <DraggableMarker
                    defaultLocation={value}
                    onChange={onLocationChange}
                />
            </MapContainer>
            {hasHelperText && helperText && (
                <span className="simplicity-forms-helper-text">{helperText}</span>
            )}
            {hasError && (
                <ErrorMsg type="error" error={error} />
            )}
        </div>
    )
}

export { SimplicityFormLocation }

/**
 * @typedef {object} DraggableMarkerProps
 * @property {LatLng=} defaultLocation
 * @property {FormQuestionLocation} field
 */
