import { useSetQuestionErrors } from 'cms/forms/hooks/use-set-question-errors'
import { useShowError } from 'cms/forms/hooks/use-show-error'
import { useCallback, useMemo } from 'react'
import { createAddressErrorMap } from './create-address-error-map'
import { formatAddressErrors } from './format-address-errors'

/**
 * @param {FormQuestionAddress} question
 * @param {QuestionAddressValue} address
 */
const useAddressError = (question, address) => {
    const { guid, isRequired } = question

    const { currentErrors, errorMap } = useMemo(
        () => {
            const errorMap = createAddressErrorMap(isRequired, address)
            const errors = formatAddressErrors(errorMap)

            const currentErrors = errors.length ? errors : null

            return {
                errorMap,
                currentErrors
            }
        },
        [isRequired, address]
    )

    useSetQuestionErrors(guid, currentErrors)
    const { error, hasError } = useShowError(currentErrors, question)

    const errorClass = useCallback(
        /**
         * @param {keyof errorMap} property 
         */
        (property) => {
            if (hasError && errorMap[property]) {
                return 'form-field-error-input'
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasError]
    )

    return {
        error,
        hasError,
        errorClass
    }
}

export { useAddressError }
