import { getCategory, getCategoryLabel, getCategoryName } from 'cms/models/category-options'
import { useMemo } from "react"


/**
 * @param {string} type
 */
const useCategoryInfo = (type) => {
    const categoryInfo = useMemo(() => {
        const categories = getCategory(type)
        const categoryName = getCategoryName(type)
        const filterName = getCategoryLabel(type)
        
        return {
            categories,
            categoryName,
            filterName
        }
    }, [type])

    return categoryInfo
}

export { useCategoryInfo }
