import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function QuoteComponent (props) {
    
    const { component } = props
    // const { richtext, author } = component
    const { author } = component
    
    return (
        <div>
            <blockquote className="simplicity-quote">
                <FontAwesomeIcon icon={`fa-regular fa-quote-left`} />
                {author && (
                    <div className={"author"}>
                        <div>- {author}</div>
                    </div>
                )}
            </blockquote>
        </div>
    )
}


QuoteComponent.displayName = 'QuoteComponent'
export { QuoteComponent }
