import { LargeFeedFilter } from 'cms/shared/feeds/large'
import React from 'react'

const Notices = () => {
    
    const type = 'model.notice'
    
    return (
        <main>
            <h1 style={{ marginTop: '16px' }}>Notices</h1>
            
            <LargeFeedFilter
                type={type}
                enableBar={true}
            />

        </main>
    )
}

export { Notices }
