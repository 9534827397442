import React from 'react'
import { TabContent } from "./tab"

const TabItems = (props) => {
    
    const tabs = props.tabs;
    const hasTabs = !!tabs;

    return (
        <div className="tab-items">
            {hasTabs && tabs.map((tab, index) =>
                <TabContent tab={tab} key={index} />
            )}
        </div>
    )
}

TabItems.displayName = TabItems
export { TabItems }
