import {
    mergeAttributes,
    Node,
} from '@tiptap/core'

export const inputRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/

const SimpliCityImage = Node.create({
    
    name: 'image',
    
    addOptions () {
        return {
            inline: false,
            allowBase64: false,
            HTMLAttributes: {},
        }
    },
    
    inline () {
        return this.options.inline
    },
    
    group () {
        return this.options.inline ? 'inline' : 'block'
    },
    
    draggable: true,
    
    addAttributes () {
        return {
            guid: {
                default: null,
            },
            src: {
                default: null,
            },
            url: {
                default: null,
            },
            alt: {
                default: null,
            },
            title: {
                default: null,
            },
            caption: {
                default: undefined,
            },
            width: {
                default: null,
            },
            height: {
                default: null,
            },
            style: {
                default: undefined,
            },
            settings: {
                default: null,
            },
            class: {
                default: 'simplicity-rich-text-image',
            },
        }
    },
    
    // TODO: needs to support a data attribute to parse with
    //  so that div and img can both be used
    renderHTML ({ HTMLAttributes }) {
        const settings = HTMLAttributes?.settings
        const url = HTMLAttributes?.url
        const { format, options } = settings || {}
        // eslint-disable-next-line no-unused-vars
        const { dimension, method, position, useCaption } = options || {}
        if (format === 'image.format.cropped-focus') {
            HTMLAttributes.height = '400px'
            // HTMLAttributes.style = `height: 400px; background-image:url(${url});background-position: ${position};`
            HTMLAttributes.style = `height: 400px; background-image:url(${url}); background-position: ${position}; background-repeat: no-repeat; background-size: cover;`
            HTMLAttributes.class = `simplicity-rich-text-image cover`
            HTMLAttributes.settings = ''
            return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
            // return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
        } else if (format === '"image.format.scaled-image') {
            HTMLAttributes.class = `simplicity-rich-text-image`
            if (method === '100Aspect') {
                HTMLAttributes.height = `${dimension}px`
                HTMLAttributes.class = `simplicity-rich-text-image ${method}`
            } else if (method === 'HeightPriority') {
                HTMLAttributes.height = `${dimension}px`
                HTMLAttributes.class = `simplicity-rich-text-image ${method}`
            } else if (method === 'WidthPriority') {
                HTMLAttributes.width = `${dimension}px`
                HTMLAttributes.class = `simplicity-rich-text-image ${method}`
            }
            HTMLAttributes.settings = ''
            return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
        } else {
            return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
        }
    },
    
})

export { SimpliCityImage, SimpliCityImage as default }
