
import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.public.api_base_url
const context = Config.public.context
const endpoint = `/${context}/form`

export const getFormSettings = async function (token, form, params) {
    const method = 'GET'
    let path = `${endpoint}/${form}/settings`
    if (params) {
        const { page, pageSize } = params
        const query = qs.stringify(
            { pagination: { page, pageSize } }, { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getFormSetting = async function (token, form, key) {
    const method = 'GET'
    let path = `${endpoint}/${form}/settings/${key}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
