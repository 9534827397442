/**
 * @type {QuestionTypes[]}
 */
const logicTypes = [
    'question.multipleChoice',
    'question.yesNo',
    'question.checkbox',
    'question.grid'
]

/**
 * Determins if the given question type can potentially have rules.
 * @param {FormQuestion} question
 * @returns {question is LogicalQuestion}
 */
const isLogicalQuestion = (question) => {
    const isLogicType = logicTypes.includes(question.type)
    return isLogicType
}

export { isLogicalQuestion }


/**
 * @typedef {FormQuestionMultipleChoice
 * | FormQuestionYesNo
 * | FormQuestionCheckbox
 * | FormQuestionGrid
 * } LogicalQuestion
 */
