import React from 'react'
import { DOCUMENT_FORMATS, formatBytes } from 'cms/components/document-list/helpers'

const AccordionItem = (props) => {
    
    const {
        doc,
        document,
        // style,
        format,
        // target,
    } = props || {}
    const style = 'body'  // DOCUMENT_STYLES.BODY // only option
    const { title } = doc
    const { file_size, url } = document
    // const isTitle = format === DOCUMENT_FORMATS.TITLE
    const isTitleSize = format === DOCUMENT_FORMATS.TITLE_SIZE

    
    return (
        <>
            <div className={`list-item ${style}`}>
                <a
                    href={url}
                    className={`display-as ${style} `}
                    target={format}
                    rel='noreferrer'
                >
                    <div className='display-as-link'>
                        {title}
                    </div>
                </a>
                {isTitleSize &&
                    <div>
                        {<div className='document-file-size-line'>({formatBytes(file_size)})</div>}
                    </div>
                }
            </div>
        </>
    )
}

export default AccordionItem
