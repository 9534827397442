/**
 * @param {FormQuestionAddress} question
 */
const addressSchemaTransformer = (question) => {

    /**
     * @type {FormQuestionAddress}
     */
    const newQuestion = {
        ...question,
        value: {
            city: '',
            country: '',
            postcode: '',
            province: '',
            street_address: '',
            unit: '',
        },
    }

    return newQuestion
}

export { addressSchemaTransformer }
