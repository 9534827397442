/**
 * @param {FormQuestionOpinionScale} question
 */
const opinionScaleValueTransformer = (question) => {

    const value = {
        selected: question.value,
        min: question.minValue,
        max: question.maxValue
    }

    return value
}


export { opinionScaleValueTransformer }
