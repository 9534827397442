import React from 'react'
import { Outlet } from 'react-router-dom'
import { BreadcrumbList } from 'cms/shared/breadcrumb'
import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { ErrorDisplay } from 'cms/shared/error_display'
import { EventNav } from 'cms/shared/event-nav'
import { useSimpliCity } from 'cms/hooks/use-simplicity'

const ApplicationNewsLayout = (props) => {
    
    const {
        crumbs,
        selectedSection
    } = props
    const { webpage, error } = useSimpliCity()
    
    return (
        <>
            <Header />
            
            <div className='event two-column-side-nav'>
                
                <div className='two-column-side-nav-right'>
                    
                    <div id='layout' className='layout'>
                        
                        <div className='main-content'>
                            
                            <BreadcrumbList crumbs={crumbs} />
                            <div>
                                
                                <Outlet />
                            
                            </div>
                        </div>
                    
                    </div>
                </div>
                
                <div className='two-column-side-nav-left'>
                    <EventNav
                        webpage={webpage}
                        selectedSection={selectedSection}
                    />
                </div>
                
            </div>
            
            <ErrorDisplay error={error} />
            
            <Footer />
            
        </>
    )
}
export { ApplicationNewsLayout }
