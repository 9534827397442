import { useMediaQuery } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Logger } from 'cms/utils/logger'

export function useQuickSearch () {
    const backDropId = 'search-overlay'
    const bodyScrollLockClass = 'no-scroll'

    const isAtLeastTablet = useMediaQuery('(min-width:600px)')

    const [showResults, setShowResults] = useState(isAtLeastTablet)
    const [showSearchInput, setShowSearchInput] = useState(false)

    const loc = useLocation()
    

    /** @type {import('react').MutableRefObject<HTMLInputElement>} */
    const inputRef = useRef()

    function closeOnAnchorClick (e) {
        /** @type {string} */
        const tagName = e.target.tagName

        if (tagName.toLowerCase() === 'a') {
            setShowSearchInput(false)

            // we need to explicitly close the results in desktop
            setShowResults(false)
        }
    }

    function closeOnOverlayFocus (e) {
        const targetId = e.target.id

        if (targetId === backDropId) {
            setShowResults(false)
        }
    }

    function onInputFocus () {
        const isInputFocused = document.activeElement === inputRef.current
        const isDesktopFocused = isAtLeastTablet && isInputFocused

        if (isDesktopFocused || showSearchInput) {
            setShowResults(true)
        }
    }

    function toggleSearchInput () {
        setShowSearchInput((show) => !show)
    }

    useEffect(() => {
        if (showResults) {
            document.body.classList.add(bodyScrollLockClass)
        } else {
            document.body.classList.remove(bodyScrollLockClass)
        }
    }, [showResults])

    useEffect(() => {
        // when we explicitly close the input in mobile
        if (!showSearchInput) {
            setShowResults(false)
        } else {
            Logger.debug(`Focusing quick search`)
            Logger.flush()
            inputRef.current.focus()
        }
    }, [showSearchInput])

    useEffect(
        () => {
            Logger.debug(`Changed location to ${loc.pathname}, closing search input`)
            Logger.flush()
            setShowSearchInput(false)
        }, [loc.pathname]
    )

    return {
        backDropId,
        closeOnAnchorClick,
        closeOnOverlayFocus,
        inputRef,
        onInputFocus,
        showResults,
        showSearchInput,
        toggleSearchInput,
    }
}
