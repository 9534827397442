import React from "react"
import cn from 'clsx';

const YoutubeComponent = (props) => {
    
    const { component } = props
    const {
        useEmbed,
        embed,
        title,
        width: _width,
        height: _height,
        src,
        srcid,
        forceResponsive
    } = component || {}
    // const isResponsive = forceResponsive === 'on'
    
    let video_url;
    if (srcid) {
        video_url = `https://www.youtube.com/embed/${srcid}`
    } else {
        video_url = src
    }
    
    const classNames = cn(
        'simplicity-embed',
        // 'you-tube-container',
        { 'responsive': forceResponsive }
    )
    const width = forceResponsive ? undefined : _width
    const height = forceResponsive ? undefined : _height
    
    return (
        <div className={classNames}>
            
            <h2>{title}</h2>
            {!useEmbed && (
                <div>
                    <iframe
                        // className={"responsive-iframe"}
                        frameBorder="0"
                        src={video_url}
                        width={width}
                        height={height}
                        title={title}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            )}
            
            {useEmbed &&
                <div dangerouslySetInnerHTML={{ __html: embed }} />
            }
        </div>
    )
}

YoutubeComponent.displayName = 'YoutubeComponent'
export { YoutubeComponent }
