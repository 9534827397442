import { Menu } from 'cms/shared/page-nav/menu'
import { usePageNav } from './use-page-nav'

const PageNav = (props) => {
    
    const { webpage } = props
    const { guid } = webpage

    const { 
        error,
        // sectionGuid,
        sectionTree,
        selectedSections,
        openMenus,
        setOpenMenus
    } = usePageNav(guid)
    
    const { children: treeChildren, nav_title } = sectionTree || {}
    
    return (
        <div className='side-nav'>
            
            <h2>{nav_title}</h2>
            
            <Menu
                tree={treeChildren}
                currentPageGuid={guid}
                openMenus={openMenus}
                setOpenMenus={setOpenMenus}
                selectedSections={selectedSections}
                // parent={parent}
            />
        </div>
    )
}

export { PageNav }
