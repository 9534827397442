/**
 * @type {QuestionTypes[]} 
 */
const questionsWithoutTitle = ['question.group', 'question.paragraph']

/**
 * Determines if a question should be excluded from the list of form questions.
 * 
 * @param {QuestionTypes} questionType 
 * @param {string=} prompt 
 */
const shouldExcludeQuestion = (questionType, prompt) => {

    const isQuestionWithoutTitle = questionsWithoutTitle.includes(questionType)
    const isWithoutPrompt = !prompt && !isQuestionWithoutTitle

    return isWithoutPrompt
}

export { shouldExcludeQuestion }
