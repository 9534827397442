import { NavLink } from "react-router-dom"
import { isDocumentHit } from "./is-document-hit"

/**
 * @param {object} props
 * @param {import("./is-document-hit").SearchHit} props.hit
 */
export function HitLink (props) {
    
    const { hit } = props
    if (isDocumentHit(hit)) {
        return (
            <NavLink to={hit.document_url} target='_blank'>
                {hit.document_url}
            </NavLink>
        )
    }
    const fullUrl = new URL(hit.path, window.location).href
    
    return (
        <NavLink to={hit.path}>
            {fullUrl}
        </NavLink>
    )
}
