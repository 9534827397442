import { FeedPagination } from 'cms/shared/feeds/pagination/feed-pagination'
import { usePagination } from 'react-instantsearch'

const SearchPaginationCustom = () => {
    
    const searchConfig = {
        padding: 10,
    }
    const {
        currentRefinement,
        nbPages,
        isFirstPage,
        isLastPage,
        refine,
    } = usePagination(searchConfig)

    const previousPageIndex = currentRefinement - 1
    const nextPageIndex = currentRefinement + 1

    return (
        <FeedPagination pagination={{
            controls: {
                next: () => refine(nextPageIndex),
                prev: () => refine(previousPageIndex),
                to: (pageNum) => refine(pageNum - 1)
            },
            state: {
                hasNext: !isLastPage,
                hasPrev: !isFirstPage,
                page: currentRefinement + 1,
                numPages: nbPages,
                hasResults: nbPages > 0
            }
        }} />
    )
}

export { SearchPaginationCustom }
