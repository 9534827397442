// import { createSlice } from '@reduxjs/toolkit';
import { REGISTER, LOGIN, LOGOUT } from './actions'

export const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    token: null
}

const authReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case REGISTER: {
            const { user, token } = action.payload;
            return {
                ...state,
                user,
                token,
            }
        }
        case LOGIN: {
            const { user, token } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user,
                token,
            }
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null,
                token: null,
            }
        }
        default: {
            return { ...state };
        }
    }
}

export default authReducer
