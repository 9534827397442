import React from 'react'
import { HomeNoticeResultItem } from 'cms/shared/feeds/homepage/notice-result-item'
import { SkipDuringPrerender } from "cms/shared/hyrdation/avoid-prerender";
import { CircularProgress } from "@mui/material";

const FeedStyleHeader = (props) => {
    
    const {
        title,
        models,
        linkText,
        path,
        pagination,
        empty_message,
        loaded
    } = props
    const {total} = pagination || {}
    const hasModels = !!models && models.length > 0
    
    return (
        <div className='feed-component-container-notices'>
            <div className='feed-component-header'>
                <div className='feed-component-title-link'>
                    {title}
                </div>
                <div><a href={path}>{linkText} ({total})</a></div>
            </div>
            <div className='feed-component-panel'>
                <div>
                    <SkipDuringPrerender>
                        {!loaded &&
                            <div className='spinner-container'><CircularProgress /></div>
                        }
                        {loaded && !hasModels &&
                            <>{empty_message}</>
                        }
                        {models && models.map((model, index) => {
                            const { guid } = model
                            return (
                                <HomeNoticeResultItem
                                    key={`${guid}${index}`}
                                    model={model}
                                />
                            )
                        })}
                    </SkipDuringPrerender>
                </div>
            </div>
        </div>
    )
}

export { FeedStyleHeader }
