import React, { useEffect } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { Logger } from 'cms/utils/logger'

const LoadWebsite = (props) => {
    
    const {
        setError,
        setWebsite,
        setSettings,
        // setWebsiteLoading
    } = props
    const { token } = useAuth()
    
    const useFetchWebsite = (token) => {
        async function fetchWebsiteData (token) {
            try {
                
                const domain = window.location.host
                // const domain = 'local.simplicitycms.ca:3300'
                Logger.debug(`Using domain: ${domain}`)
                Logger.flush()
                const website = await SimpliCitySDK.website.findForDomain(token, encodeURIComponent(domain))
                
                const { organization } = website
                const settings = await SimpliCitySDK.orgSettings.find(token, organization)

                // FIXME: website must come last here in order to provide settings
                setSettings(settings)
                setWebsite(website)
                
            } catch (error) {
                Logger.error('Failed to load website data.', error)
                Logger.flush()
                setError({ message: 'Failed to load website data.', error: error })
                // setWebsiteLoading(false)
            }
        }
        useEffect(() => {
            fetchWebsiteData(token)
        }, [token])
    }
    useFetchWebsite(token)
    
    return (
        <></>
    )
}

LoadWebsite.displayName = 'LoadWebsite'
export { LoadWebsite }
