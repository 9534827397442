import { useEffect, useState } from 'react'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'

const SearchFilterBar = (props) => {
    
    const {
        categories,
        runSearch,
        filterName,
        type
    } = props
    
    const [term, setTerm] = useState('')
    const [category, setCategory] = useState()
    const [searchTitle,setSearchTitle] = useState('Keywords')
    useEffect(() => {
        if (type === 'model.bylaw') {
            setSearchTitle('Keywords or Bylaw Number')
        }
    }, [type]);
    
    const onChangeSearchTerm = (event) => {
        const value = event.target.value
        setTerm(value)
    }
    
    const handleCategory = (event) => {
        const value = event.target.value
        setCategory(value)
    }
    
    const handleSearch = () => {
        const categoryValue = category === 'All' ?
            undefined :
            category
        
        runSearch(term, categoryValue)
    }
    
    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }
    
    useEffect(() => {
        handleSearch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category])
    
    return (
        <div className="view-filters">
            <div className="feed-search-bar feed-search-root">
                <div className={"feed-search-inputContainer"}>
                    {isArrayNotEmpty(categories) &&
                        <>
                            <label htmlFor="edit-category">
                                {filterName}
                            </label>
                            <div className="search-select">
                                <select
                                    id="edit-category"
                                    name="category"
                                    className="form-select"
                                    value={category}
                                    onChange={handleCategory}
                                >
                                    {categories.map((category) => {
                                        
                                        return (
                                            <option
                                                key={category.value}
                                                value={category.value}
                                            >
                                                {category.label}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </>
                    }
                </div>
                
                <div className={"feed-search-inputContainer"}>
                    <label htmlFor="edit-keywords">
                        {searchTitle}
                    </label>
                    <input
                        value={term}
                        onChange={onChangeSearchTerm}
                        onKeyDown={handleKeyDown}
                        className={"feed-search-input"}
                        type="text"
                        id="edit-keywords"
                        name="keywords"
                        size="30"
                        maxLength="128"
                    />
                </div>
                
                <div className={"feed-search-inputContainer"}>
                    <div>&nbsp;</div> {/* invisible label above the button for spacing */}
                    <button
                        type="submit"
                        className={"feed-search-button"}
                        aria-label="Submit the search query"
                        onClick={handleSearch}
                    >
                        Search
                    </button>
                </div>
            </div>
        </div>
    )
}

export { SearchFilterBar }

