import { useRef } from 'react'
import {
    ScheduleComponent,
    ViewsDirective,
    ViewDirective,
    // Week,
    Month,
    Year,
    // Agenda,
    Inject,
    // ICalendarExport
} from '@syncfusion/ej2-react-schedule'
// import { Internationalization } from '@syncfusion/ej2-base'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
// import { isStringNotEmpty } from "cms/utils/empty-exists";

const EventCalendar = (props) => {
    
    const { events } = props
    
    // const instance = new Internationalization()
    const scheduleObj = useRef(null)
    // const scheduleObj = useRef < ScheduleComponent > (null);
    
    // const getTimeString = (value) => {
    //     return instance.formatDate(value, { skeleton: 'hm' })
    // }
    const getTimeString = (value) => {
        return dayjs(value).format('h:mm A')
    }
    // const hasEndTime = (event) => {
    //     return dayjs(event?.EndTime).isValid()
    // }
    const EventTemplate = (options) => {
        const navigate = useNavigate()
        const card = {
            padding: '0 6px 0 6px',
            lineHeight: '24px',
        }
        const onclickEvent = (event) => {
            event.stopPropagation()
            navigate(options.Path)
        }
        const subject = {
            // color : 'white', background: options.CategoryColor
        }
        const time = {
            // color : 'white', background: '#16625a'
        }
        return (
            <>
                <div className="calendar-template-wrap"
                     style={card}
                     onClick={onclickEvent}
                >
                    <div className="calendar-subject" style={subject}>
                        {options.Subject}
                    </div>
                    <div className="calendar-time" style={time}>
                        {getTimeString(options.StartTime)}
                        {/*{hasEndTime(options) &&*/}
                        {/*    <> - {getTimeString(options?.EndTime)}</>*/}
                        {/*}*/}
                    </div>
                </div>
            </>
        )
    }
    // const onEventRendered = (args) => {
    //     if (args.data.EndTime < scheduleObj.current.selectedDate) {
    //         args.element.classList.add('e-past-app');
    //     }
    // }
    const fieldsData = {
        id: 'Id',
        subject: { name: 'Subject' },
        description: { name: 'Description' },
        startTime: { name: 'StartTime' },
        endTime: { name: 'EndTime' },
        // location: { name: 'Location' },
        // categoryColor: { name: 'CategoryColor' },
    }
    
    const eventSettings = {
        dataSource: events,
        template: EventTemplate,
        enableMaxHeight: true,
        enableTooltip: false,
        fields: fieldsData
    }
    const today = new Date()  // new Date(2018, 1, 15)
    // const today = new Date(2018, 1, 15)
    
    // const exportCalendar = () => {
    //     scheduleObj.current.exportToICalendar()
    // }
    
    return (
        <>
            {events &&
                <div style={{
                    // width: '1024px',
                    width: '100%',
                }}>
                    
                    <ScheduleComponent
                        width='100%'
                        height='600px'
                        selectedDate={today}
                        // selectedDate={new Date(2024, 0, 1)}
                        eventSettings={eventSettings}
                        currentView='Month'
                        readOnly={true}
                        ref={scheduleObj}
                        // eventRendered={onEventRendered}
                    >
                        <ViewsDirective>
                            <ViewDirective
                                option='Month'
                                readonly={true}
                            />
                            <ViewDirective
                                option='Year'
                                readonly={true}
                            />
                            {/*<ViewDirective*/}
                            {/*    option='Week'*/}
                            {/*    readonly={true}*/}
                            {/*/>*/}
                            {/*<ViewDirective*/}
                            {/*    option='Agenda'*/}
                            {/*    readonly={true}*/}
                            {/*/>*/}
                        </ViewsDirective>
                        {/*<Inject services={[Month, Agenda, Year]} />*/}
                        {/*<Inject services={[Month, Year, Week, ICalendarExport]} />*/}
                        {/*<Inject services={[Week, Year, Month]} />*/}
                        <Inject services={[Year, Month]} />
                    </ScheduleComponent>
                    
                    {/*<div style={{*/}
                    {/*    display: 'flex',*/}
                    {/*    flexDirection: 'row-reverse',*/}
                    {/*    // width: '1024px',*/}
                    {/*    width: '100%',*/}
                    {/*    marginTop: '16px'*/}
                    {/*}}>*/}
                    {/*    <button*/}
                    {/*        className="webform-submit button-primary form-submit"*/}
                    {/*        onClick={exportCalendar}*/}
                    {/*    >*/}
                    {/*        Export All*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    
                </div>
            }
        </>
    )
}

export { EventCalendar }
