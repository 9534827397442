/**
 * @param {string} indexName
 * @param {string} attribute
 * @param {Directions} direction
 * @returns {string}
 */
export function getReplicaName (indexName, attribute, direction) {
    return [indexName, attribute, direction].join('_')
}

/** @typedef {'asc' | 'desc'} Directions */
