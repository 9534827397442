import { mergeAttributes } from "@tiptap/core";
import { createColGroup } from "./create-col-group";

/**
 * @typedef {import("@tiptap/extension-table").TableOptions} Options
 * @typedef {import("@tiptap/core").NodeConfig<Options>} TableNodeConfig
 * @typedef {import("@tiptap/pm/model").DOMOutputSpec} DOMOutputSpec
 */

/** @type {TableNodeConfig} */
export const tableNodeConfig = {
    renderHTML ({ node, HTMLAttributes }) {
        const { colgroup} = createColGroup(
            node,
            this.options.cellMinWidth,
        );

        /** @type {DOMOutputSpec} */
        const table = [
            "table",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {}),
            colgroup,
            ["tbody", 0],
        ];

        /** @type {DOMOutputSpec} */
        const wrapper = [
            "div",
            { class: "table-wrapper" },
            table
        ]


        return wrapper;
    },
};
