/**
 * @type {SnapshotComponent<RatingSnapshotValue>}
 */
const RatingSnapshot = (props) => {

    const { value } = props
    const { max, selected } = value

    return (
        <>{selected} / {max}</>
    )
}

export { RatingSnapshot }

/**
 * @typedef {object} RatingSnapshotValue
 * @property {number} selected
 * @property {number} max
 * @property {'heart' | 'thumbUp' | 'star'} iconType
 */
