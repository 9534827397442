import React, { Fragment } from 'react'
import { CommonTaskItem } from 'layout/homepage/services-banner/common-tasks/common-task-item'
import { common_tasks_data } from './common-tasks-data'

const CommonTasksStatic = () => {
    
    const title = 'Common Tasks'
    const allServices = 'All online services'
    const allServicesPath = '/programs-and-services/online-payments-and-services'
    
    return (
        <Fragment>
            <div className="featured-services__wrapper">
                <div className="region region-featured-services common-tasks">
                    
                    <h2 className="common-tasks-title">
                        {title}
                    </h2>
                    
                    <div className="common-tasks-items">
                        {common_tasks_data &&
                            common_tasks_data.map((service, index) => {
                                return (
                                    <Fragment key={index}>
                                        <CommonTaskItem service={service} />
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                    {allServices &&
                        <div className='common-tasks-items-item'>
                            <a href={`${allServicesPath}`} className='common-tasks-link common-tasks-link-all'>
                                <span className='common-tasks-text'>{allServices}</span>
                            </a>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}
export { CommonTasksStatic }
