import React, { useEffect, useState } from 'react'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import Lightbox from 'yet-another-react-lightbox'
import Inline from 'yet-another-react-lightbox/plugins/inline'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import { generateImageSlides } from './utils'
import { isArrayNotEmpty, isValidGuid } from 'cms/utils/empty-exists'

const ImageGalleryFilmstrip = (props) => {
    
    const { token } = useAuth()
    const { image_gallery } = props || {}
    const [slides, setSlides] = useState([])
    const showToggle = true
    const descriptionTextAlign = 'start'
    const descriptionMaxLines = 3
    
    useEffect(() => {
        if (isArrayNotEmpty(image_gallery)) {
            const images = image_gallery.filter((element) => isValidGuid(element?.image))
            generateImageSlides(images, token)
            .then((result) => setSlides(result))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image_gallery])

    return (
        <div className="simplicity-image-list-item">
            {isArrayNotEmpty(slides) && (
                <div style={{ width: "100%", maxWidth: "900px", aspectRatio: "3 / 2" }}>
                    <Lightbox
                        slides={slides}
                        style={{
                            width: "100%",
                            maxWidth: "900px",
                            aspectRatio: "3 / 2",
                            margin: "0 auto",
                        }}
                        captions={{ showToggle, descriptionTextAlign, descriptionMaxLines }}
                        plugins={[Inline, Thumbnails, Captions, Fullscreen]}
                    />
                </div>
            )}
        </div>
    );
}

export { ImageGalleryFilmstrip }
