/*
 * Copyright (C) 2024 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { SimpliCityApp } from 'app'
import reportWebVitals from './reportWebVitals'
import { Logger } from 'cms/utils/logger'

const rootElement = document.getElementById('root')
Logger.debug(`ENVIRONMENT: ${process.env.NODE_ENV}`)
if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
    // production
    Logger.debug(`Production build for hydration.`)
    if (rootElement.hasChildNodes()) {
        ReactDOM.hydrateRoot(rootElement, <SimpliCityApp />)
    } else {
        const root = ReactDOM.createRoot(rootElement)
        root.render(<SimpliCityApp />)
    }
} else {
    // dev uses strict mode (possible double renders)
    Logger.debug(`Development mode.`)
    if (rootElement.hasChildNodes()) {
        ReactDOM.hydrateRoot(rootElement, <React.StrictMode><SimpliCityApp /></React.StrictMode>)
    } else {
        const root = ReactDOM.createRoot(rootElement)
        root.render(<React.StrictMode><SimpliCityApp /></React.StrictMode>)
    }
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
