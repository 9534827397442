import { QuestionTypeMap } from 'cms/forms/question-type-map'
import { isObject } from 'cms/utils/empty-exists'
import { omit } from 'lodash'
import { Logger } from 'cms/utils/logger'

/**
 * Creates snapshots of the given questions
 * @param {FormQuestion[]} questions 
 * @param {Record<string, object>=} labels 
 */
export function snapshotQuestions (questions, labels) {
    const snapshots = questions.map(
        (question) => {
            const { guid, type, value, label, group } = question
            const { valueTransformer } = QuestionTypeMap[question.type]

            const hasValueTransformer = typeof valueTransformer === 'function'

            /**
             * @type {QuestionSnapshot}
             */
            const snapshot = {
                guid,
                prompt: label,
                group,
                type,
                value,
                isTransformed: hasValueTransformer
            }

            if (hasValueTransformer) {
                const questionLabels = labels[question.guid]
                const formattedValue = valueTransformer(question, questionLabels)

                // remove rules from the raw value
                const rawValue = isObject(value) ?
                    omit(value, 'rules') :
                    value

                snapshot.value = formattedValue
                snapshot.rawValue = rawValue
            }

            return snapshot
        }
    )

    Logger.debug('[snapshot-questions] Snapshots: ',snapshots)

    return snapshots
}

/**
 * @typedef {object} QuestionSnapshot
 * @property {string} guid
 * @property {string=} group
 * @property {string} prompt
 * @property {QuestionTypes} type
 * @property {any} value
 * @property {any=} rawValue
 */
