import React, { useState, useEffect } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { Logger } from 'cms/utils/logger'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'


const useImageLoader = () => {
    
    const { token = '' } = useAuth()
    
    const defaultImages = new Map()
    const [images,setImages] = useState(defaultImages)
    const [image,setImage] = useState()
    
    const [settings, setSettings] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [aspectRatio, setAspectRatio] = useState(1)
    // const [imageDimensions, setImageDimensions] = useState({})
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    // const { width, height } = imageDimensions || {}
    const { title, alt, url } = image || {}
    
    const setDefaultDimensions = () => {
        setWidth(0)
        setHeight(0)
    }
    
    const measureImage = (imageUrl) => {
        const pseudoImage = new Image()
        pseudoImage.src = imageUrl
        
        pseudoImage.onload = () => {
            setWidth(pseudoImage.width)
            setHeight(pseudoImage.height)
            const ratio = Math.floor(pseudoImage.width / pseudoImage.height)
            setAspectRatio(ratio)
            setLoaded(true)
        }
        pseudoImage.onerror = (err) => {
            Logger.debug('[useImageLoader] image error', {error: err})
            Logger.error(err)
            setLoaded(false)
        }
    }
    
    const fetchImage = async (image_guid, settings) => {
        if (image_guid) {
            setLoaded(false)
            setSettings(settings)
            let current_image = await SimpliCitySDK.images.findOne(token, image_guid)
            if (current_image && current_image.guid) {
                setImage(current_image)
                if (image) {
                    measureImage(image?.url)
                    // Logger.debug(imageDimensions)
                }
                // Logger.debug('setImage', current_image)
            } else {
                Logger.error('[useImageLoader] Could not load image...', { guid: current_image.guid })
            }
        } else {
            setImage(undefined)
            setDefaultDimensions()
            // setLoaded(true)
        }
    }
    const getImageData = async (image_guid) => {
        if (!image_guid) {
            console.log('missing image guid')
            return undefined
        } else {
            let current_image = await SimpliCitySDK.images.findOne(token, image_guid)
            if (current_image && current_image.guid) {
                return current_image
            } else {
                console.log('image load failed', image_guid)
            }
        }
    }
    
    const preloadImage = async (image) => {
        const { url } = image || {}
        // let data = {...image}
        const retrieve = async (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image()
                img.src = url
                img.onload = () => {
                    resolve(img)
                }
                // img.onerror = reject
            })
        }
        try {
            const img = await retrieve(url)
            return img
        } catch (err) {
            console.error(err)
            throw err
        }
    }
    
    const preloadImageArray = async (urls) => {
        try {
            const images = []
            for (let url of urls) {
                const image = await preloadImage(url)
                images.push(image)
            }
            return images
        } catch (err) {
            console.error(err)
        }
    }
    
    const addImage = async (image_guid) => {
        // fetchImage(image_guid, _settings)
        if (images.has(image_guid)) {
            const img = images.get(image_guid)
            setImage(img)
            console.log('had image',img, images)
        } else {
            const img = await getImageData(image_guid)
            setImage(img)
            console.log('got image',img, images)
            const newImages = new Map(images)
            newImages.set(image_guid, img)
            setImages(newImages)
        }
    }
    const addImages = async (image_array) => {
        for (let guid of image_array) {
            const data = await getImageData(guid)
            const newImages = new Map(images)
            newImages.set(guid, data)
            setImages(newImages)
        }
    }
    const getImage = async (guid) => {
        
        if (images.has(guid)) {
            const img = images.get(guid)
            setImage(img)
            return img
        } else {
            const img = await getImageData(guid)
            setImage(img)
            const newImages = new Map(images)
            newImages.set(guid, img)
            setImages(newImages)
            return img
        }
    }
    
    useEffect(() => {
        if (image?.url) {
            measureImage(image?.url)
        }
    }, [image])
    
    return {
        image,
        width,
        height,
        title,
        alt,
        url,
        loaded,
        aspectRatio,
        settings,
        fetchImage,
        getImageData,
        addImage,
        addImages,
        getImage,
        preloadImage,
        preloadImageArray,
    }
}

export { useImageLoader }
