import { simple } from 'instantsearch.js/es/lib/stateMappings'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { useInstantSearch } from 'react-instantsearch'

const useSearchUrl = () => {
    const { uiState } = useInstantSearch()
    const [queryString, setQueryString] = useState("")

    useEffect(
        () => {
            const routeState = simple().stateToRoute(uiState)
            let query = qs.stringify(routeState)

            if (query) {
                query = "?" + query
            }

            setQueryString('/search' + query)
        },
        [uiState]
    )

    return queryString

}
export { useSearchUrl }
