import { isArrayNotEmpty } from 'cms/utils/empty-exists'
import { RichText } from 'cms/rich-text'

const SimpleListStyle = (props) => {
    
    const {
        children,
        description,
        // error
    } = props

    return (
        <>
            <RichText
                className="navigation-list-description"
                richtext={description}
            />
            <ul>
                {isArrayNotEmpty(children) && children.map((webpage, index) => (
                    <li key={index}>
                        <a href={webpage.path}>
                            {webpage.nav_title}
                        </a>
                    </li>
                ))}
            </ul>
        </>
    )
}

SimpleListStyle.displayName = 'SimpleListStyle'
export { SimpleListStyle }
