import { useSearchBox } from 'react-instantsearch'
import { AllResultsLink } from './all-results-link'

export function CompactFooter () {
    const { query } = useSearchBox()

    if (!query) {
        return null
    }

    return (
        <div className={'compact-results-footer'}>
            <AllResultsLink>
                View all results for “{query}” <i className="fa-regular fa-arrow-right"></i>
            </AllResultsLink>
        </div>
    )
}
