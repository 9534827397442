import { Config } from '../config'
import { makeRequest } from '../common/index'
import { requestAccessToken } from './azure-ad'
const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/account`

export const login = async function (payload) {
    const method = 'POST'
    const path = `${endpoint}/login`
    const headers = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const register = async function (payload) {
    const method = 'POST'
    const path = `${endpoint}/register`
    const headers = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const me = async function (token) {
    const method = 'GET'
    const path = `${endpoint}/me`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const verifyToken = async function (accessToken, refreshToken) {
    const method = 'POST'
    const path = `${endpoint}/verify`
    const headers = {"Authorization": `Bearer ${accessToken}`}
    const payload = {access: accessToken, refresh: refreshToken}
    return await makeRequest(method, base_url, path, headers, payload)
}
export { requestAccessToken }
