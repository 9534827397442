import { QuestionTypeMap } from 'cms/forms/question-type-map'
import { Logger } from 'cms/utils/logger'

/**
 * Applies the configured schema transformer if one exists for the given question.
 * 
 * The question is mutated.
 * @param {FormQuestion} question 
 */
const applySchemaTransformer = (question) => {
    const config = QuestionTypeMap[question.type]

    const transformer = config?.schemaTransformer

    // transform the question if a transformer exists
    if (typeof transformer === 'function') {
        const transformedQuestion = transformer(question)

        Logger.debug(`[get-current-form] transformed question type ${question.type}:`, transformedQuestion)
        Logger.flush()
        Object.assign(question, transformedQuestion)
    }
}

export { applySchemaTransformer }
