import React from 'react'
import { LargeFeedFilter } from "cms/shared/feeds/large";

const Alerts = () => {
    
    const type = 'model.alert'
    const enableBar = true
    
    return (
        <main>
            <h1 style={{ marginTop: '16px' }}>Alerts</h1>
            
            <LargeFeedFilter
                type={type}
                enableBar={enableBar}
            />
            
        </main>
    )
}

export { Alerts }
