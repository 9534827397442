import React, { useEffect, useState } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { ModelImage } from 'cms/shared/images/model-image'

const BannerImage = (props) => {
    
    const { model: model_guid } = props || {}
    const { token = '' } = useAuth()
    const [bannerImg, setBannerImg] = useState()
    const [model, setModel] = useState()

    useEffect(() => {
        const getModel = async (model_guid) => {
            if (model_guid && !bannerImg) {
                const _model = await SimpliCitySDK.model.findOne(token, model_guid)
                setModel(_model)
                if (_model?.banner_image) {
                    const banner_image = _model?.banner_image?.image
                    setBannerImg(banner_image)
                }
            }
        }
        getModel(model_guid)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model_guid])

    return (
        <div className='webpage-banner-image'>
            {bannerImg &&
                <ModelImage
                    model={model}
                    position='center center'
                    type='background cover'
                    width='100%'
                    height='200px'
                    useAspect={true}
                    background_size='cover'
                />
            }
        </div>
    )
}

export { BannerImage }
