import React from 'react'

const FeedbackButton = (props) => {
    const { label, onClick, isSelected } = props
    return (
        <button
            onClick={onClick}
            className={`btn page-feedback__option ${isSelected ? "selected" : ""}`}
            data-option={label.toLowerCase()}
        >
            {label}
        </button>
    )
}
export { FeedbackButton}
