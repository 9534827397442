import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CarouselImage } from 'cms/components/hero-carousel/carousel-image'
import { RichTextSimple } from 'cms/rich-text/rich-text-simple'

const HeroCarouselSlideHomepage = (props) => {
    
    const { slides, images, index, total, moveIndex } = props || {}
    const slide = slides[index]
    const { title, description, link } = slide || {}
    const { text, url: link_url, link_type } = link || {}
    const image = Array.isArray(images) && images[index]
    const settings = slide?.settings
    
    return (
        // <div className="home-banner">
        <Fragment>
            
            <CarouselImage
                image={image}
                slide={slide}
                settings={settings}
                isHomepage={true}
            />
            
            <div className="home-banner-content">
                
                {title &&
                    <h1>{title}</h1>
                }
                
                {description &&
                    <RichTextSimple
                        richtext={description}
                        className='carousel-slide-description'
                    />
                }
                
                <div className="controls">
                    
                    {link_url &&
                        <Link to={link_url}>{text}</Link>
                    }
                    
                    <div className="forward-back">
                        <button
                            aria-label="Previous"
                            onClick={() => moveIndex(-1)}
                        >
                            <FontAwesomeIcon icon={`fa-regular fa-arrow-left`} />
                        </button>
                        
                        <span>{`${index + 1}/${total}`}</span>
                        
                        <button
                            aria-label="Next"
                            onClick={() => moveIndex(1)}
                        >
                            <FontAwesomeIcon icon={`fa-regular fa-arrow-right`} />
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

HeroCarouselSlideHomepage.displayName = 'HeroCarouselSlideHomepage'
export { HeroCarouselSlideHomepage }
