import { isArray } from 'cms/utils/empty-exists'

/**
 * @param {OptionalQuestion<FormQuestionLocation>} question
 */
const locationValueTransformer = (question) => {
    const location = isArray(question.value) ? 
        question.value : 
        []

    const [lat, lng] = location
    const value = {
        lat,
        lng
    }
    
    return value
}

export { locationValueTransformer }
