import styles from './current-filter-tag.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * @param {object} props 
 * @param {() => void} props.onClose
 * @param {string} props.children
 * @param {string} props.prefix
 */
export function CurrentFilterTag (props) {

    const { children: label, onClose, prefix } = props

    return (
        <span className={styles.tag}>
            <span className={styles.labelPrefix}>{prefix}:</span>
            &nbsp;
            {label}

            <button
                type="button"
                onClick={onClose}
                aria-label={`Remove the ${label} refinement`}
            >
                <FontAwesomeIcon icon={`fa-regular fa-xmark`} />
            </button>
        </span>
    )
}
