/*
 * Copyright (C) 2024 SimpliCity Digital Inc - All Rights Reserved
 *
 * This file contains Customer specific (or overridden) config. It is not the same as the SimpliCity CmsConfig.js file.
 */
const Config = {
    home_page_id: "48c25737-29e4-463e-b7ea-b093ddaa4291",
    website_id: "2ad55006-3f62-4bb4-8b9a-6a3225e48e54",
    recaptcha_site_key: "6LenVeEpAAAAAHBfaB6YaiuxZewd_XN-5LkeT_2a",
    google_site_verification: '+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=',
    dates: {
        date_short_no_year: "MMM D",
        time: "h:mma",
    },
    location: {
        default: [49.3539659,-123.0809661]
    },
    SyncFusion: {
        licence: process.env.REACT_APP_SYNCFUSION_LICENCE_KEY,
    },
    Recollect: {
        script: { src: "https://assets.ca.recollect.net/api/widget.js?scripts=head" },
        style: { href: "https://recollect.a.ssl.fastly.net/0.11.1654194167/api/areas/DistNorthVancouver/services/waste/style/widget/logan/Default.css" },
    },
    Userway: {
        src: "https://cdn.userway.org/widget.js",
        account: "unAfj7cv4G",
    },
    SEO: {
        url: 'https://www.dnv.org',
        website_title: 'District of North Vancouver',
        description: 'This is the official website for the District of North Vancouver, in British Columbia, Canada. It contains information on District programs and services, property and development, government business, and more.',
        website_image: 'https://images.dnv.org/images/dnv-air-deepcove.jpg',
        keywords: 'District North Vancouver',
    },
};

export { Config }
