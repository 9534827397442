
import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/form`

const getFormSettings = async function (token, form, params) {
    const method = 'GET'
    let path = `${endpoint}/${form}/settings`
    if (params) {
        const { page, pageSize } = params
        const query = qs.stringify(
            { pagination: { page, pageSize } }, { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const getFormSetting = async function (token, form, key) {
    const method = 'GET'
    let path = `${endpoint}/${form}/settings/${key}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const createFormSetting = async function (token, form, key, payload) {
    const method = 'POST'
    let path = `${endpoint}/${form}/settings/${key}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
const createFormSettings = async function (token, form, payload) {
    const method = 'POST'
    let path = `${endpoint}/${form}/settings`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
const updateFormSetting = async function (token, form, key, payload) {
    const method = 'PATCH'
    const path = `${endpoint}/${form}/settings/${key}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
const updateFormSettings = async function (token, form, payload) {
    const method = 'PATCH'
    const path = `${endpoint}/${form}/settings`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
const deleteFormSetting = async function (token, form, key) {
    const method = 'DELETE'
    const path = `${endpoint}/${form}/settings/${key}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

export {
    getFormSettings,
    getFormSetting,
    createFormSettings,
    createFormSetting,
    updateFormSettings,
    updateFormSetting,
    deleteFormSetting,
}