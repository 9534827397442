/**
 * Apply rules to questions
 * @param {FormQuestion[]} questions
 * @param {LogicRule[]} logicRules
 */
const applyLogicRules = (questions, logicRules) => {
    /**
     * @type {FormQuestion[]}
     */
    const shownQuestionsWithValue = []
    const hiddenQuestions = new Set(
        questions
            .filter((question) => !question.isShown)
            .map((question) => question.guid),
    )

    for (const question of questions) {
        // determine which rules should be run on the current question
        const questionLogicRules = logicRules.filter((rule) => {
            const { action, target } = rule

            // matches everything
            if (target === '*') {
                return true
            }

            const isShowAction = action === 'SHOW_TARGET_QUESTION'
            const isTargetGroup = target === question.group

            /**
             * A group may cascade its rules if the action is to show,
             * the question is within the group, and the question does not
             * have its own targeting rules.
             *
             * This allows us to progressively show group questions if
             * those questions are being targeted by a rule.
             */
            const isGroupMatch =
                isShowAction && isTargetGroup && !question.isDependent

            // match if the group is being marked to show
            if (isGroupMatch) {
                return true
            }

            const isMatch = target === question.guid
            return isMatch
        })

        for (const rule of questionLogicRules) {
            const { action } = rule

            // invalid / show implies that a question should be shown
            const isHidden = action === 'HIDE_TARGET_QUESTION'
            const wasHidden = hiddenQuestions.has(question.guid)

            // record questions that will be changed to shown and has a value
            if (question.value && wasHidden && !isHidden) {
                shownQuestionsWithValue.push(question)
            }

            question.isShown = !isHidden

            const isInvalidated = action === 'INVALIDATE_SOURCE_QUESTION'
            question.isLogicallyInvalid = isInvalidated
        }
    }

    return shownQuestionsWithValue
}

export { applyLogicRules }
