/**
 * @param {FormQuestionFileUpload} question
 */
const fileUploadSchemaTransformer = (question) => {

    /**
     * @type {FormQuestionFileUpload}
     */
    const newQuestion = {
        ...question,
        value: [],
    }

    return newQuestion
}

export { fileUploadSchemaTransformer }
