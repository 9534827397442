import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/attachment`

export const getAttachments = async function (token,params) {
    const method = 'GET'
    let path = `${endpoint}`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } }, { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const searchAttachments = async function (token,searchTerm,params) {
    const method = 'GET'
    let path = `${endpoint}/search`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            {
                term: searchTerm,
                pagination: { page, pageSize }
            },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getAttachment = async function (token, guid) {
    const method = 'GET'
    let path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getAttachmentByFilePath = async function (token, filepath) {
    const method = 'GET'
    let path = `${endpoint}/filepath/${filepath}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const updateAttachment = async function (token, guid, payload) {
    const method = 'PUT'
    let path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
export const createAttachment = async function (token, form, payload) {
    const method = 'POST'
    let path = `${endpoint}?`
    // const query = qs.stringify({ form: form }, { encodeValuesOnly: true })
    // path = `${path}${query}`
    const headers = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const deleteAttachment = async function (token, guid) {
    const method = 'DELETE'
    let path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

// --- Public -------------------------------------------------------------
export const createAttachmentPublic = async function (organization, form, payload) {
    const method = 'POST'
    // TODO: change path  to public
    // let path = `/public/attachment?`
    let path = `${endpoint}?`
    const query = qs.stringify(
        {
            organization: organization,
            form: form
        },
        { encodeValuesOnly: true }
    )
    path = `${path}${query}`
    const headers = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const generateAttachmentSasTokenPublic = async function () {
    const method = 'POST'
    // let path = `/public/attachment/token`
    const path = `${endpoint}/token`
    const headers = undefined
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const deleteAttachmentPublic = async function (guid) {
    const method = 'DELETE'
    // let path = `/public/attachment/${guid}`
    let path = `${endpoint}/${guid}`
    const headers = undefined
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
