import React, { useEffect, useState } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { ScaledWidthImage } from './scaled-width-image'
import { ScaledHeightImage } from './scaled-height-image'
import { ScaledFullWidthImage } from './scaled-fullwidth-image'
import { CroppedFocusImage } from './cropped-focus-image'
import validator from 'cms/shared/images/validator'
import { isNull, isObject } from 'cms/utils/empty-exists'
import { Logger } from 'cms/utils/logger'

const SimpliCityFormattedImage = (props) => {
    
    const {
        image: _image, // guid
        settings
    } = props || {}
    const { token } = useAuth()
    const [image, setImage] = useState()
    const [hasContent, setHasContent] = useState()
    const FORMATS = {
        CROPPED_FOCUS: 'image.format.cropped-focus',
        SCALED_IMAGE: 'image.format.scaled-image',
    }
    const { format, options } = settings || {}
    const { method, useCaption } = options || {}
    const { description: caption } = image || {}
    const orientation = (image?.width > image?.height) ? 'horizontal' : 'vertical'
    const isScaled = format === FORMATS.SCALED_IMAGE
    // console.log('useCaption', useCaption, image?.title, image?.description)
    
    let Component
    if (isScaled) {
        switch (method) {
            case 'WidthPriority':
                Component = <ScaledWidthImage
                    image={image}
                    settings={settings}
                    forceSixteenNine={false}
                    contain={false}
                    orientation={orientation}
                />
                break
            case 'HeightPriority':
                Component = <ScaledHeightImage
                    image={image}
                    settings={settings}
                    forceSixteenNine={false}
                    contain={false}
                    orientation={orientation}
                />
                break
            case '100Aspect':
                Component = <ScaledFullWidthImage
                    image={image}
                    settings={settings}
                    forceSixteenNine={false}
                    contain={false}
                    orientation={orientation}
                />
                break
            default:
                Component = <ScaledFullWidthImage
                    image={image}
                    settings={settings}
                    forceSixteenNine={false}
                    contain={false}
                    orientation={orientation}
                />
                break
        }
    } else {
        // i.e. where format === FORMATS.CROPPED_FOCUS
        Component = <CroppedFocusImage image={image} settings={settings} />
    }

    useEffect(() => {
        // component has an image property containing the guid of the image
        let guid = _image
        // First check if the image is improperly saved
        // - we want a guid at this point not an image
        const isImage = validator.isImage(_image)
        if (isImage) {
            guid = _image.guid
        }
        const isGuid = validator.isGuid(guid)
        if (isGuid) {
            const fetchData = async () => {
                const img = await SimpliCitySDK.images.findOne(token, guid)
                setImage(img)
                setHasContent(!!img)
            }
            fetchData()
        } else {
            Logger.info(`Image property was not valid. [IMAGE: ${JSON.stringify(image)}]`)
            Logger.flush()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_image])
    
    if (isObject(_image) || isNull(_image)) {
        Logger.warn('invalid image data in SimpliCityFormattedImage (should be guid)')
        Logger.flush()
        // throw Error('invalid image data in SimpliCityFormattedImage (should be guid)')
        return null
    }
    
    return (
        <>
            {image && hasContent &&
                <>
                    {Component}
                    {useCaption && caption &&
                        <div className='simplicity-image-caption'>{caption}</div>
                    }
                </>
            }
        </>
    )
}

export default SimpliCityFormattedImage
