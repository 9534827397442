/**
 * @param {SearchHit} hit 
 * @returns {hit is Hit<DocumentHit>}
 */
export function isDocumentHit (hit) {

    /** @type {DocumentHit} */
    const docHit = hit
    return !!docHit.document_url
}

/**
 * @template T
 * @typedef {import("instantsearch.js").Hit<T>} Hit
 */

/**
 * @typedef {Hit<WebpageHit | DocumentHit>} SearchHit
 */

/**
 * @typedef {object} WebpageHit
 * @property {string} website_guid
 * @property {string} webpage_guid
 * @property {string} nav_title
 * @property {string} path
 * @property {string} name
 * @property {string} type
 * @property {string} title
 * @property {string} description
 * @property {string} banner-image
 * @property {object} banner_image
 */

/**
 * @typedef {object} DocumentHit
 * @property {string} classification
 * @property {string} type
 * @property {string} title
 * @property {string} description
 * @property {string} document_type
 * @property {string} document_url
 * @property {boolean} is_document
 */
