/**
 * @type {import('react').FC}
 */
const CaptchaNotice = () => {
    return (
        <small className="form-captcha-notice">
            This form is protected by reCAPTCHA and the Google{" "}
            <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and{" "}
            <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.
        </small>
    )
}

export { CaptchaNotice }
