/**
 * @type {SnapshotComponent<{ label: string }>}
 */
const DropdownSnapshot = (props) => {
    const { value } = props
    
    return (
        <>{value.label}</>
    )
}

export { DropdownSnapshot }
