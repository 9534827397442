import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { Component } from 'cms/fields/component'
import React, { Fragment } from 'react'

const HomeContent = () => {
    
    const { webpage, model, fields } = useSimpliCity()
    const { content } = fields || {}
    
    return (
        <Fragment>
            {/* Selectively showing just the field called "content" of type "field.component" */}
            {fields &&
                <Component
                    field={content}
                    model={model}
                    webpage={webpage}
                />
            }
        </Fragment>
    )
}
HomeContent.displayName = 'HomeContent'
export { HomeContent }
