import { useEffect } from 'react'
import { Logger } from 'cms/utils/logger'
import { LayoutWrapper } from "cms/layout/layout-wrapper"
import { useFetchWebpage } from 'cms/webpage'

const Home = () => {

    const { fetchWebpageData } = useFetchWebpage()

    useEffect(() => {
        Logger.debug("Start Load home...")
        fetchWebpageData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LayoutWrapper />
    )
}
export { Home }
