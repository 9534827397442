/**
 * @type {SnapshotComponent<Attachment[]>}
 */
const FileUploadSnapshot = (props) => {
    const { value: list } = props

    return (
        <ul>
            {list.map(
                (attachment) => (
                    <li key={attachment.guid}>{attachment.file_name}</li>
                )
            )}
        </ul>
    )
}

export { FileUploadSnapshot }
