import { createTheme } from '@mui/material'

export let theme = createTheme()

// mui forces us to define the palette separately and after the main theme
theme = createTheme(theme, {
    palette: {
        navy: theme.palette.augmentColor({
            color: {
                main: '#004F59',
            },
            name: 'navy',
        }),
        focus: theme.palette.augmentColor({
            color: {
                main: '#3D7737',
            },
            name: 'focus',
        }),
    },
})
