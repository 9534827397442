import { HomeFeed } from 'cms/shared/feeds/homepage'

const Home_Feeds = () => {
    
    return (
            <div id='feed-home-container' className='feed-home-container'>
                <div id='feed-home-row' className='feed-home-row'>
                    <HomeFeed
                        style='clear'
                        title={'News'}
                        type={'model.news'}
                        path={'/news'}
                        linkText={'All News'}
                        quantity={2}
                        empty_message={'There is currently no news.'}
                    />
                    
                    <HomeFeed
                        style='clear'
                        title={'Events'}
                        type={'model.event'}
                        linkText={'All Events'}
                        path={'/events'}
                        quantity={2}
                        range={'future'}
                        empty_message={'There are no upcoming events.'}
                    />
                    
                    <HomeFeed
                        style='clear'
                        title={'Meetings'}
                        type={'model.meeting'}
                        linkText={'All Meetings'}
                        path={'/government-administration/council-meeting-calendar'}
                        linkText2={'Council agendas'}
                        path2={'/government-administration/meeting-agendas-and-minutes'}
                        quantity={2}
                        range={'future'}
                        empty_message={'There are no upcoming meetings.'}
                    />
                    
                    <HomeFeed
                        style='header'
                        title={'Notices'}
                        type={'model.notice'}
                        linkText={'All Notices'}
                        path={'/notices'}
                        quantity={1}
                        empty_message={'There are currently no notices.'}
                    />
                
                </div>
            </div>
    )
}
Home_Feeds.displayName = 'Home_Feeds'
export { Home_Feeds }
