import { icons } from 'icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { isArrayNotEmpty, isStringNotEmpty } from "cms/utils/empty-exists"
import React, { Fragment } from "react"
import { NavLink } from "react-router-dom"
import { NavigationSummaryLink } from "./navigation-summary-link"
// import { Logger } from 'cms/utils/logger'

const isValidIcon = (icon, title) => {
    const valid = isStringNotEmpty(icon) && icon !== null
    // console.debug(`icon '${title}', '${icon}', ${valid}`)
    return valid
}

const NavigationItem = (props) => {
    
    const { item: webpage, guid } = props
    const { nav_icon: icon, nav_title } = webpage
    const hasIcon = isValidIcon(icon, nav_title)
    const [expanded, setExpanded] = React.useState(false)
    const hasChildren = isArrayNotEmpty(webpage.children)

    const handleExpandChange = (panel) => (event, isExpanded) => {
        // allow expansion only if the section has children
        if (hasChildren) {
            setExpanded(isExpanded ? panel : false)
        }
    }

    return (
        <Fragment>
            <div className="navigation-list-item">
                <Accordion
                    className={"navigation-list-item-accordion"}
                    expanded={expanded === guid}
                    onChange={handleExpandChange(guid)}
                    sx={{ boxShadow: "unset" }}
                >
                    <AccordionSummary
                        id={guid}
                        className={`section-summary ${expanded === guid ? "expanded" : ""}`}
                        style={{ cursor: hasChildren ? undefined : "auto" }}
                    >
                        <div className={"section-summary-content"}>
                            <div className={"section-summary-icon"}>
                                {hasIcon &&
                                    <FontAwesomeIcon
                                        icon={`fa-regular fa-${icon}`}
                                    />
                                }
                            </div>
                            <div className={"section-summary-content-detail"}>
                                <h4 className="navigation-list-item-title">
                                    <NavigationSummaryLink
                                        path={webpage.path}
                                        title={webpage.nav_title}
                                        hasChildren={hasChildren}
                                    />
                                </h4>
                                <p className="navigation-list-item-summary">
                                    {webpage.description}
                                </p>
                            </div>
                        </div>
                        {hasChildren && (
                            <div className={"section-summary-expand"}>
                                <FontAwesomeIcon
                                    icon={icons.regular.faPlus}
                                    size="1x"
                                    fixedWidth
                                />
                                <FontAwesomeIcon
                                    icon={icons.regular.faMinus}
                                    size="1x"
                                    fixedWidth
                                />
                            </div>
                        )}
                    </AccordionSummary>
                    <AccordionDetails>
                        {hasChildren && (
                            <div className="navigation-list-item-children">
                                {webpage.children.map((child, index) => {
                                    const { nav_title, path } = child
                                    // const { nav_title, path, nav_icon } = child
                                    // const hasIcon = isValidIcon(nav_icon, nav_title)
                                    return (
                                        <div key={index} className="navigation-list-item-child-item">
                                            <div className="icon">
                                                {/*{hasIcon && (*/}
                                                {/*    <FontAwesomeIcon*/}
                                                {/*        icon={`fa-regular ${nav_icon}`}*/}
                                                {/*    />*/}
                                                {/*)}*/}
                                            </div>
                                            <div className="text">
                                                <NavLink to={path}>{nav_title}</NavLink>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>
        </Fragment>
    )
}

NavigationItem.displayName = "NavigationItem"
export { NavigationItem }
