import { useDeepCompareMemo } from "cms/hooks/use-deep-compare-memo"
import { convertToHtml } from "./rich-text-utils"

/**
 * Convert Quill or TipTap to HTML
 * Returns early where it can to prevent expensive
 * @param {any} richtext
 * @param {any} dynamicOptions
 * @returns {string}
 */
export function useRichTextToHtml (richtext, dynamicOptions = {}) {
    
    const html = useDeepCompareMemo(() => {
        
        return convertToHtml(richtext, dynamicOptions)
        
    }, [richtext])

    return html
}
