import { components } from 'react-select'

/**
 * @param {ValueContainerSummaryProps} props
 */
export function ValueContainerSummary (props) {
    const { children, ...rest } = props

    // the search input which is always present as is the last element
    const SearchInput = children[children.length - 1]

    // the placeholder is always the 0th element and is not an array of nodes
    const Placeholder = !Array.isArray(children[0]) ? children[0] : null

    // this will laways be an array since we're using multi mode
    const items = props.selectProps.value
    const selectedText = items.length ? `Selected (${items.length})`: null

    return (
        <components.ValueContainer {...rest}>
            {Placeholder}
            {selectedText}
            {SearchInput}
        </components.ValueContainer>
    )

}
