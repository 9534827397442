import { ErrorMessages } from "cms/forms/error-messages";
import { useMemo } from "react";

/**
 * @param {ShortAnswerSubQuestion} subQuestion
 * @param {string} value
 */
export function useGridShortAnswerValidator (subQuestion, value) {
    const {
        required,

        // max chars
        hasMaxCharacters,
        maxCharacters,

        // min chars
        hasMinCharacters,
        minCharacters
    } = subQuestion

    const error = useMemo(
        () => {
            // if the field is required, but there is no value, error
            if (required && !value) {
                return ErrorMessages.required()
            }

            // if the length of the value is less than min chars, error
            if (hasMinCharacters && value.length < Number(minCharacters)) {
                return ErrorMessages.text.minLength(minCharacters)
            }

            // if the length of the value is more than max chars, error
            if (hasMaxCharacters && value.length > Number(maxCharacters)) {
                return ErrorMessages.text.maxLength(maxCharacters)
            }

            // by default, no error
            return null
        },
        [hasMaxCharacters, hasMinCharacters, maxCharacters, minCharacters, required, value]
    )

    return error
}
