import React, { Fragment } from 'react'
import { CTAHorizontal } from './horizontal'
import { CTAVertical } from './vertical'

const CallToActionComponent = (props) => {
    
    const { component } = props || {}
    const {
        image = '',
        title = '',
        description = '',
        imageCard,
        links = [],
        settings,
    } = component || {}
    const { style, format } = settings || {}
    // const hasContent = isValidGuid(image)
    const isVertical = imageCard === 'vertical'
    const isHorizontal = imageCard === 'horizontal'
    
    return (
        <>
            {isVertical &&
                <CTAVertical
                    image={image}
                    title={title}
                    description={description}
                    links={links}
                    style={style}
                    format={format}
                    settings={settings}
                />
            }
            
            {isHorizontal &&
                <CTAHorizontal
                    image={image}
                    title={title}
                    description={description}
                    links={links}
                    style={style}
                    format={format}
                    settings={settings}
                />
            }
        </>

    )
}

CallToActionComponent.displayname = 'CallToActionComponent'
export { CallToActionComponent }
