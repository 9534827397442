import React from 'react'
import { evaluateIsCategory } from 'pages/story/category/story-config'
import { useSimpliCityPath } from 'cms/webpage/use-simplicity-path'
import { StoryCategory } from 'pages/story/category'
import { Story } from 'pages/story/story'

const Stories = () => {
    
    const { slug } = useSimpliCityPath()
    const isRoot = slug === 'dnv-stories' || slug === 'all'
    const isCategory = isRoot || evaluateIsCategory(slug)
    
    if (isCategory) {
        
        return (
            <main>
                <StoryCategory />
            </main>
        )
        
    } else {
        
        return (
            <main>
                <Story />
            </main>
        )
    }
    
}



export { Stories }
