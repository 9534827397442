import { Config } from '../config'
import { makeRequest } from '../common/index'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/media`

export const generateImageSasToken = async function (token) {
    const method = 'POST'
    const path = `${endpoint}/image/token`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    // const payload = {
    //     storageAccountName,
    //     containerName
    // }
    // console.log(`makeRequest: token [${token}]`)
    // console.log(`makeRequest: storageAccountName [${storageAccountName}]`)
    // console.log(`makeRequest: containerName [${containerName}]`)
    return await makeRequest(method, base_url, path, headers, payload)
}
export const generateDocumentSasToken = async function (token) {
    const method = 'POST'
    const path = `${endpoint}/document/token`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    // const payload = {
    //     storageAccountName,
    //     containerName
    // }
    // console.log(`makeRequest: token [${token}]`)
    // console.log(`makeRequest: storageAccountName [${storageAccountName}]`)
    // console.log(`makeRequest: containerName [${containerName}]`)
    return await makeRequest(method, base_url, path, headers, payload)
}
// const generateSasToken = async function (storageAccountName, containerName, token) {
//     const method = 'POST'
//     const path = `${endpoint}/token`
//     const headers = {"Authorization": `Bearer ${token}`}
//     const payload = {
//         storageAccountName,
//         containerName
//     }
//     // console.log(`makeRequest: token [${token}]`)
//     // console.log(`makeRequest: storageAccountName [${storageAccountName}]`)
//     // console.log(`makeRequest: containerName [${containerName}]`)
//     return await makeRequest(method, base_url, path, headers, payload)
// }

// export {
//     generateImageSasToken,
//     generateDocumentSasToken
// }
