import { RichText } from 'cms/rich-text'
import { isArrayNotEmpty, isRichTextNotEmpty, isStringNotEmpty } from 'cms/utils/empty-exists'
import { CTAImage } from './cta-image'
import { CTALink } from './cta-link'

const CTAVertical = (props) => {

    const {
        image,
        title,
        description,
        links,
        style,
        format,
        settings
    } = props

    return (
        <>
            <div className={`simplicity-cta-container vertical`}>
                <CTAImage
                    image={image}
                    imageCard={'vertical'}
                    settings={settings}
                />

                <div className={`simplicity-cta-content vertical`}>

                    {isStringNotEmpty(title) &&
                        <div className="cta-title">{title}</div>
                    }

                    {isRichTextNotEmpty(description) &&
                        <RichText className={isArrayNotEmpty(links) ? 'truncate' : "overflow"} richtext={description} />
                    }

                    {isArrayNotEmpty(links) &&
                        links.map((link, index) => {
                            return (
                                <CTALink
                                    key={index}
                                    link={link}
                                    format={format}
                                    style={style}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </>

    )
}

CTAVertical.displayname = 'CTAVertical'
export { CTAVertical }
