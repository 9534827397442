import { Config } from '../config'
import { makeRequest } from '../common/index'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/permission`

const getPermissions = async function (token, permission) {
    const method = 'GET'
    const path = `${endpoint}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const getPermission = async function (token, guid) {
    const method = 'GET'
    const path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const getRolesForPermission = async function (token, permission) {
    const method = 'GET'
    const path = `${endpoint}/${permission}/roles`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
// TODO: ensure additional security
const createPermission = async function (token, payload) {
    const method = 'POST'
    const path = `${endpoint}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
// TODO: ensure additional security
const updatePermission = async function (token, guid, payload) {
    const method = 'PUT'
    const path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
// TODO: ensure additional security
const deletePermission = async function (token, guid) {
    const method = 'DELETE'
    const path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

export {
    getPermissions,
    getPermission,
    getRolesForPermission,
    createPermission,
    updatePermission,
    deletePermission,
}