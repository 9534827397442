import React, { Fragment } from 'react'
import { isArrayNotEmpty, isRichTextNotEmpty, isStringNotEmpty } from "cms/utils/empty-exists";
import { RichText } from 'cms/rich-text'
import { CTAImage } from './cta-image'
import { CTALink } from './cta-link'

const CTAHorizontal = (props) => {
    
    const {
        image,
        title,
        description,
        links,
        style,
        format,
        settings
    } = props
    
    return (
        <>
            {/*{hasContent &&*/}
                <div className={`simplicity-cta-container horizontal`}>
                    
                    <CTAImage
                        image={image}
                        imageCard={'horizontal'}
                        settings={settings}
                    />
                    
                    <div className={`simplicity-cta-content horizontal`}>
                            
                        {isStringNotEmpty(title) &&
                            <div className="cta-title">{title}</div>
                        }
                        
                        {isRichTextNotEmpty(description) &&
                            <RichText richtext={description} />
                        }
                        
                        {isArrayNotEmpty(links) &&
                            <div className='cta-link-container'>
                                
                                {links.map((link, index) => {
                                    return (
                                        <CTALink
                                            key={index}
                                            link={link}
                                            format={format}
                                            style={style}
                                        />
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
            {/*}*/}
        </>

    )
}

CTAHorizontal.displayname = 'CTAHorizontal'
export { CTAHorizontal }
