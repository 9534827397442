import { useRefinementList } from "react-instantsearch"

/**
 * @param {string} attribute 
 */
export function useCurrentRefinementList (attribute) {
    const refinementListState = useRefinementList({
        attribute,
        sortBy: ["name:asc"],
        limit: 100,
    })
    const currentItems = refinementListState.items.filter(
        (item) => item.isRefined
    )

    return  {
        ...refinementListState,
        currentItems
    }
}
