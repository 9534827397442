/**
 * @type {SnapshotComponent<OpinionScaleSnapshotValue>}
 */
const OpinionScaleSnapshot = (props) => {

    const { value } = props
    const { max, selected } = value

    return (
        <>{selected} / {max}</>

    )
}

export { OpinionScaleSnapshot }

/**
 * @typedef {object} OpinionScaleSnapshotValue
 * @property {number} selected
 * @property {number} min
 * @property {number} max
 */
