import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.public.api_base_url
const context = Config.public.context
const endpoint = `/${context}/attachment`

// --- Public -------------------------------------------------------------
export const createAttachmentPublic = async function (organization, form, payload) {
    const method = 'POST'
    // TODO: change path  to public
    // let path = `/public/attachment?`
    let path = `${endpoint}?`
    const query = qs.stringify(
        {
            organization: organization,
            form: form
        },
        { encodeValuesOnly: true }
    )
    path = `${path}${query}`
    const headers = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const generateAttachmentSasTokenPublic = async function () {
    const method = 'POST'
    // let path = `/public/attachment/token`
    const path = `${endpoint}/token`
    const headers = undefined
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const deleteAttachmentPublic = async function (guid) {
    const method = 'DELETE'
    // let path = `/public/attachment/${guid}`
    let path = `${endpoint}/${guid}`
    const headers = undefined
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
