// import { Highlight } from 'react-instantsearch'
import { HitLink } from './hit-link'
import { HitTitleLink } from "components/search/full-search/hit/hit-title-link";

/**
 * @param {object} props 
 * @param {import('./is-document-hit').SearchHit} props.hit 
 */
export function SearchHit (props) {
    
    const { hit } = props

    // Hardcoding the title for documents
    const title = hit.is_document ? 'Document' : hit.type
    
    return (
        <div className='search-hit'>
            <div className='search-hit-type'>{title}</div>
            <HitTitleLink hit={hit} />
            <div className='search-hit-description'>{hit.description}</div>
            <HitLink hit={hit} />
        </div>
    )
}
