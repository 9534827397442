import React from "react"

function FooterNeighbourhoods () {
    
    return (
        <div className={"neighbourhoods"}>
            <h2>In your neighbourhood</h2>
            
            <ul>
                <li>
                    <a href="/programs-and-services/deep-cove" aria-label='Deep Cove'>
                        Deep Cove
                    </a>
                </li>
                <li>
                    <a href="/programs-and-services/delbrook/upper-lonsdale" aria-label='Delbrook / Upper Lonsdale'>
                        Delbrook / Upper Lonsdale
                    </a>
                </li>
                <li>
                    <a href='/programs-and-services/lions-gate' aria-label='Lions Gate / Lower Capilano'>
                        Lions Gate / Lower Capilano
                    </a>
                </li>
                <li>
                    <a href='/programs-and-services/lynn-creek' aria-label='Lynn Creek'>
                        Lynn Creek
                    </a>
                </li>
                <li>
                    <a href='/programs-and-services/lynn-valley' aria-label='Lynn Valley'>
                        Lynn Valley
                    </a>
                </li>
                <li>
                    <a href='/programs-and-services/maplewood' aria-label='Maplewood'>
                        Maplewood
                    </a>
                </li>
                <li>
                    <a href='/programs-and-services/seymour' aria-label='Seymour'>
                        Seymour
                    </a>
                </li>
                <li>
                    <a href='/programs-and-services/upper-capilano' aria-label='Upper Capilano'>
                        Upper Capilano
                    </a>
                </li>
            </ul>
        </div>
    )
}

export { FooterNeighbourhoods }
