import { getQuestionFormComponentByType } from './question-type-map'



/**
 * @param {object} props 
 * @param {FormQuestion} props.field
 */
const QuestionTypes = (props) => {

    const { field } = props
    const { type, isShown } = field || {}

    // show/hide question
    if (!isShown) {
        return null
    }

    const QuestionComponent = getQuestionFormComponentByType(type)

    // this is how the original code logically handled mismatches (i.e., it doesn't error out)
    if (!QuestionComponent) {
        return null
    }

    return <QuestionComponent field={field} />
}

export { QuestionTypes }
