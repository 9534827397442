import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faArrowUpRightFromSquare, faArrowRight } from 'icons'
import { icons } from 'icons'

const CTAStyleLink = (props) => {
    const { link, style, format } = props || {}
    // const { is_external } = link
    // const isExternal = isTrue(is_external)
    // const isInternal = isFalse(is_external)
    const { link_type } = link || {}
    const isExternalLink = link_type === "external"
    const isInternalLink = link_type === "internal"
    const isIntegrationLink = link_type === "integration"
    const isMailtoLink = link_type === "mailto"

    return (
        <div className="simplicity-text-link-item">
            <a
                href={link?.url}
                className={`simplicity-cta-link ${style} ${format} ${
                    isExternalLink || isInternalLink || isMailtoLink || isIntegrationLink
                        ? "link--icon link--icon--left"
                        : ""
                }`}
                target={link.target}
            >
                {link?.text}

                {isInternalLink && (
                    <span className="simplicity-link-icon">
                        <FontAwesomeIcon icon={icons.regular.faArrowRight} size="1x" />
                    </span>
                )}
                {isExternalLink && (
                    <span className="simplicity-link-icon">
                        <FontAwesomeIcon icon={icons.regular.faArrowUpRightFromSquare} size="1x" />
                    </span>
                )}
                {isMailtoLink && (
                    <span className="simplicity-link-icon">
                        <FontAwesomeIcon icon={icons.regular.faArrowRight} size="1x" />
                    </span>
                )}
                {isIntegrationLink && (
                    <span className="simplicity-link-icon">
                        <FontAwesomeIcon icon={icons.regular.faArrowRight} size="1x" />
                    </span>
                )}
            </a>
        </div>
    )
}

export { CTAStyleLink }
