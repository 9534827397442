
const TabNav = (props) => {
    const { tabs, selectTab } = props

    return (
        <div className="tab-nav-wrapper">
            <ul className="tab-nav" role="tablist">
                {tabs.map((tab, index) => (
                    <li
                        key={tab.guid}
                        data-tab-index={index}
                        role="tab"
                        tabIndex={index}
                        aria-selected={tab.isVisible}
                        aria-controls={tab.guid}
                        id={tab.guid}
                        onClick={(event) => selectTab(event, tab.guid)}
                        className={tab.isVisible ? "active" : ""}
                    >
                        <span>{tab.title}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

TabNav.displayName = 'TabNav'
export { TabNav }

