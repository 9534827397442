import React, { Fragment } from 'react'
import { getProperty } from 'cms/utils'
import SimpliCityFormattedImage from "cms/shared/images/formatted-image";

const BannerImage = (props) => {
    
    const { model } = props || {}
    const bannerImage = getProperty(model, 'banner_image')
    const { image, settings } = bannerImage || {}
    
    return (
        <>
            {image && (
                <Fragment>
                    <SimpliCityFormattedImage
                        image={image}  // guid
                        settings={settings}
                    />
                </Fragment>
            )}
        </>
    )
}

export { BannerImage }
