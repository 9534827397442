import { useSortBy } from 'react-instantsearch'
import ReactSelect from 'react-select'
import { useAlgolia } from 'cms/search/hooks/use-algolia'
import { useReplicaIndexName } from 'cms/search/hooks/use-replica-index-name'
import { useDropdownStyles } from '../use-dropdown-styles'
import { SortOption } from './sort-option'

export function SortDropdown () {
    const { searchIndex } = useAlgolia()
    const dateReplica = useReplicaIndexName("updated_at")
    const styles = useDropdownStyles({ rightAligned: true })

    const { options, refine, currentRefinement } = useSortBy({
        items: [
            { label: "Most relevant", value: searchIndex },
            { label: "Newest", value: dateReplica("desc") },
            { label: "Oldest", value: dateReplica("asc") },
        ],
    })

    const currentOption = options.find((option) => option.value === currentRefinement)

    return (
        <ReactSelect
            aria-label="Sort results by"
            value={currentOption}
            components={{
                Option: SortOption,
            }}
            {...styles}
            onChange={(selected) => {
                if (selected) {
                    refine(selected.value);
                }
            }}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            options={options}
            hideSelectedOptions={false}
            isSearchable={false}
        />
    )
}
