import { Crumb } from './crumb'

/**
 * @typedef {object} Crumb
 * @property {string} title
 * @property {string=} path
 */

/**
 * @param {object} props 
 * @param {Crumb[]} props.crumbs
 * @returns 
 */
const BreadcrumbList = (props) => {
    
    const { crumbs = [] } = props
    
    return (
        <div className="simplicity-breadcrumb">
            {crumbs &&
                <ul>
                    {crumbs.map((crumb, index) => (
                        <Crumb
                            key={index}
                            alt={crumb.title}
                            text={crumb.title}
                            href={crumb.path}
                            isLast={index === crumbs.length - 1}
                        />
                    ))}
                </ul>
            }
        </div>
    )
}
export { BreadcrumbList }
