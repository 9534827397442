import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import StarIcon from '@mui/icons-material/Star'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import Rating from '@mui/material/Rating'
import { useForm } from 'cms/forms/context'
import { ErrorMessages } from 'cms/forms/error-messages'
import ErrorMsg from 'cms/forms/error-msg'
import { useSetQuestionErrors } from 'cms/forms/hooks/use-set-question-errors'
import { useShowError } from 'cms/forms/hooks/use-show-error'
import { isNumber } from 'cms/utils/empty-exists'
import { useMemo } from 'react'

export const RatingIconMap = {
    heart: {
        Icon: FavoriteIcon,
        EmptyIcon: FavoriteBorderIcon
    },
    thumbUp: {
        Icon: ThumbUpAltIcon,
        EmptyIcon: ThumbUpOffAltIcon
    },
    star: {
        Icon: StarIcon,
        EmptyIcon: StarOutlineIcon
    }
}

/**
 * @type {QuestionComponent<FormQuestionRating>}
 */
const SimplicityFormRating = (props) => {

    const { field } = props || {}
    const {
        guid,
        prompt,
        rating,
        iconType,
        hasDescription,
        description,
        hasHelperText,
        helperText,
        isRequired,
        value,
    } = field || {}
    const { onInputChange } = useForm()

    const currentError = useMemo(
        () => {
            if (isRequired && !isNumber(value)) {
                return ErrorMessages.required()
            }

            return null
        },
        [isRequired, value]
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    const { Icon, EmptyIcon } = useMemo(
        () => {
            const icons = RatingIconMap[iconType]

            // default to star icons
            if (!icons) {
                return RatingIconMap.star
            }

            return icons
        },
        [iconType]
    )

    const onChange = (e, val) => {
        onInputChange(val, field)
    }

    // Only calculate the initial value once, otherwise React complains about changing
    // the default value.
    const initialValue = useMemo(
        () => isNumber(value) ? value : 0,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return (
        <div className="simplicity-forms-field-group">
            <label
                htmlFor={`simplicity-forms-rating-${guid}`}
                className="simplicity-forms-field-label"
            >
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </label>
            {hasDescription && description && <span className="simplicity-forms-description">{description}</span>}
            <Rating
                className={`simplicity-forms-rating ${iconType}`}
                defaultValue={initialValue}
                max={rating}
                icon={<Icon />}
                emptyIcon={<EmptyIcon />}
                onChange={onChange}
            />
            {hasHelperText && helperText && <span className="simplicity-forms-helper-text">{helperText}</span>
            }
            {hasError && <ErrorMsg type="error" error={error} />}
        </div>
    )
}

export { SimplicityFormRating }
