/**
 * @type {SnapshotComponent<CheckboxSnapshotValue[]>}
 */
const CheckboxSnapshot = (props) => {
    const { value: list } = props

    const labels = list.map(
        (option) => option.label
    )

    const formattedValue = labels.join(', ')
    
    return (
        <>{formattedValue}</>
    )
}

export { CheckboxSnapshot }


/**
 * @typedef {object} CheckboxSnapshotValue
 * @property {string} id
 * @property {string} label
 * @property {string} value
 */
