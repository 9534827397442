import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function FooterContactUs () {
    return (
        <div className="footer-contact">
            <h2>Contact us</h2>
            <p>
                General information<br />
                604-990-2311<br />
                <a href="mailto:infoweb@dnv.org">
                    infoweb@dnv.org
                </a>
            </p>
            <address>
                355 West Queens Road<br />
                North Vancouver, BC<br />
                V7N 4N5
            </address>
            <a href="/contact-us" className='footer-button-link' aria-label='Full Firectory'>
                <div>Full directory</div>
                <div>
                    <FontAwesomeIcon icon={`fa-regular fa-arrow-right`} />
                </div>
            </a>
        </div>
    )
}

export { FooterContactUs }
