import React from 'react'
import { LargeFeedFilter } from 'cms/shared/feeds/large'
import { FeedbackHelpful } from 'components/feedback-helpful'
import { useSimpliCity } from 'cms/hooks/use-simplicity'

const Licences = () => {
    
    const { webpage, model } = useSimpliCity()
    // const { slug: _slug, path: _path } = useSimpliCityPath()
    
    const type = 'model.licence'
    
    return (
        <main>
            <h1 style={{ marginTop: '16px' }}>
                Licences
            </h1>
            
            <LargeFeedFilter
                type={type}
            />
            
            <FeedbackHelpful
                model={model}
                webpage={webpage}
            />
            
        </main>
    )
}

export { Licences }
