(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), require("jwt-decode"), require("moment"), require("qs"));
	else if(typeof define === 'function' && define.amd)
		define(["axios", "jwt-decode", "moment", "qs"], factory);
	else if(typeof exports === 'object')
		exports["SimpliCitySDK"] = factory(require("axios"), require("jwt-decode"), require("moment"), require("qs"));
	else
		root["SimpliCitySDK"] = factory(root["axios"], root["jwt-decode"], root["moment"], root["qs"]);
})(this, (__WEBPACK_EXTERNAL_MODULE_axios__, __WEBPACK_EXTERNAL_MODULE_jwt_decode__, __WEBPACK_EXTERNAL_MODULE_moment__, __WEBPACK_EXTERNAL_MODULE_qs__) => {
return 