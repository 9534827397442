/**
 * @param {AddressErrorMap} errorMap
 */
const formatAddressErrors = (errorMap) => {
    const errors = []

    if (errorMap.street_address) {
        errors.push('Please enter a street address')
    }

    if (errorMap.city) {
        errors.push('Please enter a city')
    }

    if (errorMap.province) {
        errors.push('Please enter a province')
    }

    if (errorMap.postcode) {
        errors.push('Please enter a postal code')
    }

    return errors
}

export { formatAddressErrors }
