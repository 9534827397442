import { Config } from '../config'
import { makeRequest } from '../common/index'

const base_url = Config.public.api_base_url
const context = Config.public.context
const endpoint = `/${context}/publish/alert`

export const getCurrentAlert = async function (token) {
    const method = 'GET'
    const path = `${endpoint}/current`
    const headers = undefined
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getAlert = async function (token,guid) {
    const method = 'GET'
    const path = `${endpoint}/{guid}`
    const headers = undefined
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
