import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/excerpt`

const getExcerpts = async function (token, params) {
    const method = 'GET'
    let path = `${endpoint}`
    if (params) {
        const { page, pageSize } = params
        const query = qs.stringify(
            { pagination: { page, pageSize } }, { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const getExcerpt = async function (token, guid) {
    const method = 'GET'
    let path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const createExcerpt = async function (token, payload) {
    const method = 'POST'
    let path = `${endpoint}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
const updateExcerpt = async function (token, guid, payload) {
    const method = 'PATCH'
    const path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    return await makeRequest(method, base_url, path, headers, payload)
}
const deleteExcerpt = async function (token, guid) {
    const method = 'DELETE'
    const path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

export {
    getExcerpts,
    getExcerpt,
    createExcerpt,
    updateExcerpt,
    deleteExcerpt,
}