
const ResultItemGeneric = (props) => {
    
    const { model } = props
    const {
        title,
        description,
        path
    } = model
    
    return (
        <div className="feed-result-row">
            <h3 className="title">
                <a href={path}>
                    {title}
                </a>
            </h3>
            <div className="feed-result-description">
                <p>{description}</p>
            </div>
        </div>
    )
}

export { ResultItemGeneric }
