import { Config } from '../config'
import { makeRequest } from "../common/index"
import { get } from "lodash"
// import qs from 'qs'

const base_url = Config.public.api_base_url
const context = Config.public.context
const endpoint = `/${context}/form/response`

export const createFormResponse = async function (token, payload, options) {
    const method = "POST"
    const path = `${endpoint}`

    const additionalHeaders = get(options, 'headers', {})
    const headers = { 
        ...additionalHeaders,
        Authorization: `Bearer ${token}`
    }
    return await makeRequest(method, base_url, path, headers, payload)
}
