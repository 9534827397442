import {
    getContainerClient,
    getFilesInContainer,
    createFileInContainer,
} from 'cms/integration/azure/storage/azure-blob-storage'
import { Logger } from 'cms/utils/logger'


//      TODO: See AbortController
//        may also solve 'progress'
//      https://stackoverflow.com/questions/65678404/how-to-cancel-azure-blob-upload-multiple-files

export const uploadFile = async (storageAccount, containerName, file, userDelegateSasToken) => {
    if (!file) {
        return []
    }
    let upload_status
    try {
        Logger.debug(`uploading ${file.name} to ${storageAccount}/${containerName}/?${userDelegateSasToken}`)
        Logger.flush()
        const containerClient = await getContainerClient(storageAccount, containerName, userDelegateSasToken)
        
        // upload file
        await createFileInContainer(containerClient, file)
        
        upload_status = {
            status: 'UPLOADED',
            message: 'The upload has completed successfully.',
        }
    } catch (err) {
        Logger.error(err)
        Logger.flush()
        upload_status = {
            status: 'ERROR',
            message: err.message,
        }
        //TODO: throw on error to stop further uploads
    }
    return upload_status
}


export const getFiles = getFilesInContainer

