// the library resource from core for use with kits or sets
import { library } from '@fortawesome/fontawesome-svg-core'

// named "selected" icons in the kit organized by prefix/family
import { all } from './dnv/all'
import { brands } from './dnv/brands'
import { solid } from './dnv/solid'
import { regular } from './dnv/regular'

// We import one entire font set to support using dynamic font names
// all regular pro icons ! 2.5Mb !
const AVAILABLE_PREFIXES = ["far"]
import * as farIcons from '@fortawesome/pro-regular-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { isStringNotEmpty } from 'cms/utils/empty-exists'
library.add(far, ...all)
export const getIcon = (prefix, name) => {
    if (!prefix) {
        prefix = 'far'
    }
    if (isStringNotEmpty(name) || name === null) {
        return null
    }
    // const icon = byPrefixAndName[prefix][name]
    const icon = farIcons[name]
    return icon?.icon
}

export {
    AVAILABLE_PREFIXES,
    all,
    regular,
    solid,
    brands,
}
