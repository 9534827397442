import { noop } from 'lodash'
import { forwardRef, useEffect } from 'react'
import { useSearchInput } from 'cms/search/hooks/use-search-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * @param {object} props
 * @param {boolean=} props.autoSearch
 * @param {boolean=} props.navigateOnSubmit
 * @param {SearchEvent=} props.onFocus
 * @param {SearchEvent=} props.onChange
 * @param {Partial<SearchInputClassNames>} props.classNames
 * @param {import('react').ReactNode} props.children
 * @param {import('react').Ref<HTMLInputElement>} ref
 */
export function _SearchInput (props, ref) {
    const {
        autoSearch,
        navigateOnSubmit,
        children,
        onFocus = noop,
        onChange: onQueryChange = noop,
    } = props

    const { 
        clear,
        onChange,
        onSubmit,
        query,
        search,
    } = useSearchInput(autoSearch, navigateOnSubmit)


    useEffect(
        () => {
            onQueryChange(query)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [query]
    )

    return (
        <form
            onSubmit={onSubmit}
            className={'full-search-root'}
        >
            <div className={'full-search-inputContainer'}>
                <input
                    ref={ref}
                    className={'full-search-input'}
                    value={search}
                    type="text"
                    onChange={onChange}
                    placeholder="Search"
                    aria-label="Search"
                    onFocus={() => onFocus(query)}
                />

                {search && <button
                    type="button"
                    className={'full-search-reset'}
                    onClick={clear}
                    aria-label="Clear the search query"
                >
                    <FontAwesomeIcon icon={`fa-regular fa-xmark`} />
                </button>}
            </div>

            <button
                type="submit"
                className={'full-search-submit'}
                aria-label="Submit the search query"
            >
                {children}
            </button>
        </form>
    )
}

export const FullSearchInput = forwardRef(_SearchInput)
FullSearchInput.displayName = 'FullSearchInput'

/**
 * @typedef {(search: string) => void} SearchEvent
 * @typedef {string | string[]} ClassName
 */

/**
 * @typedef {object} SearchInputClassNames
 * @property {ClassName} root
 * @property {ClassName} input
 * @property {ClassName} inputContainer
 * @property {ClassName} submit
 * @property {ClassName} reset
 */
