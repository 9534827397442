import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CarouselImage } from 'cms/components/hero-carousel/carousel-image'
import { RichTextSimple } from 'cms/rich-text/rich-text-simple'

const HeroCarouselSlide = (props) => {
    
    const { slides, images, index, total, moveIndex } = props || {}
    const slide = slides[index]
    const { title, description, link } = slide || {}
    const { text, url: link_url, link_type } = link || {}
    const image = Array.isArray(images) && images[index]
    const settings = slide?.settings
    
    return (
        <div className={`carousel-slide`}>
            
            <div className="carousel-image-wrapper">
                <CarouselImage
                    image={image}
                    slide={slide}
                    settings={settings}
                    isHomepage={false}
                />
            </div>
            
            <div className="carousel-content">
                
                <div className='carousel-slide-title'>{title}</div>
                
                <RichTextSimple
                    richtext={description}
                    className='carousel-slide-description'
                />
                
                <div className="controls">
                    
                    <Link to={link_url} className='carousel-slide-link'>
                        {text}
                    </Link>
                    
                    <div className="forward-back">
                        <button
                            aria-label="Previous"
                            onClick={() => moveIndex(-1)}
                        >
                            <FontAwesomeIcon icon={`fa-regular fa-arrow-left`}/>
                        </button>
                        
                        <span>{`${index + 1}/${total}`}</span>
                        
                        <button
                            aria-label="Next"
                            onClick={() => moveIndex(1)}
                        >
                            <FontAwesomeIcon icon={`fa-regular fa-arrow-right`}/>
                        </button>
                    </div>
                </div>
            </div>
        
        </div>
    )
}
// }

export { HeroCarouselSlide }


/**
 * @typedef {object} WebpageProps
 * @property {FeaturedWebpage=} web
 * @property {string} webpageCard
 * @property {number} total
 */

/**
 * @typedef {object} FeaturedWebpage
 * @property {string} guid - Unique identifier for the featured webpage.
 * @property {string} webpage - Webpage associated with the featured webpage.
 * @property {number} position - Position of the featured webpage.
 * @property {string} title - Title of the featured webpage.
 * @property {string} description - Description of the featured webpage.
 * @property {string} image - Identifier for the associated image.
 */

/**
 * @typedef {Object} WebpageResponse
 * @property {number} id - Identifier for the webpage.
 * @property {string} guid - Unique identifier for the webpage.
 * @property {string} website - Website associated with the webpage.
 * @property {string} type - Type of the webpage.
 * @property {string} model - Model associated with the webpage.
 * @property {string} parent - Parent identifier for the webpage.
 * @property {string} nav_title - Title of the webpage for navigation.
 * @property {string} description - Description of the webpage.
 * @property {string} slug - Slug for the webpage.
 * @property {string} path - Path to the webpage.
 * @property {string|null} keywords - Keywords associated with the webpage (can be null).
 * @property {string} shortlink - Shortlink identifier for the webpage.
 * @property {string|null} nav_image - Navigation image associated with the webpage (can be null).
 * @property {string} nav_icon - Icon identifier for the webpage navigation.
 * @property {string} created_at - The date and time when the webpage was created.
 * @property {string} updated_at - The date and time when the webpage was last updated.
 * @property {string} updated_by - Identifier of the user who last updated the webpage.
 * @property {string} created_by - Identifier of the user who created the webpage.
 * @property {number} hide_in_nav - Flag indicating whether the webpage should be hidden in navigation (0 or 1).
 */

/**
 * @typedef {Object} ModelResponse
 * @property {string} guid
 * @property {string} title
 * @property {string} description
 */
