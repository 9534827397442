import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faArrowUpRightFromSquare } from 'icons'
import { icons } from 'icons'


const UnderlineLink = (props) => {
    const { link, style, format } = props || {}
    const { link_type } = link || {}
    const isExternalLink = link_type === "external"

    return (
        <div className="simplicity-text-link-item">
            <a
                href={link?.url}
                className={`simplicity-underline-link ${style} ${format} ${
                    isExternalLink ? "link--icon link--icon--left" : ""
                }`}
                target={link.target}
            >
                {link?.text}

                {isExternalLink && (
                    <span className="simplicity-link-icon">
                        <FontAwesomeIcon icon={icons.regular.faArrowUpRightFromSquare} size="1x" />
                    </span>
                )}
            </a>
        </div>
    )
}

export { UnderlineLink }
