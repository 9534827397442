import { NavLink } from 'react-router-dom'
import { useSaveSearchQuery } from 'cms/search/hooks/use-save-search-query'
import { useSearchUrl } from 'cms/search/hooks/use-search-url'

export function AllResultsLink (props) {

    const searchUrl = useSearchUrl()
    const saveSearchQuery = useSaveSearchQuery()

    return (
        <NavLink to={searchUrl} onClick={saveSearchQuery}>
            {props.children}
        </NavLink>
    )
}
