/*
 * Copyright (C) 2024 SimpliCity Digital Inc - All Rights Reserved
 */
const CmsConfig = {
    ENV: process.env.REACT_APP_ENV || 'REACT_APP_ENV not set',
    useDebug: false,
    showErrors: true,
    isPrerendering: () => navigator.userAgent === 'SimpliCityPrerender',
    isPreview: process.env.REACT_APP_ENABLE_PREVIEW === 'true' || false,
    enable_logs: process.env.REACT_APP_ENABLE_LOGS === 'true' || false,
    API_BASEURL: process.env.REACT_APP_SIMPLICITY_SDK_API_BASE_URL,
    PREVIEW_URL: process.env.REACT_APP_SIMPLICITY_SDK_PREVIEW_URL,
    LOGIN_URL: `${process.env.REACT_APP_LOGIN_URL}?redirectUri=${process.env.REACT_APP_REDIRECT_URI}`,
    LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
    COOKIE_DOMAIN: '.simplicitycms.ca',
    Search: {
        applicationId: process.env.REACT_APP_SEARCH_APP_ID,
        apiKey: process.env.REACT_APP_SEARCH_API_KEY
    },
    dates: {
        timezone: 'America/Vancouver',
        internalFormats: {
            datetime: 'YYYY-MM-DDTHH:mm:ssZZ', // ... but just use .toISOString() for a datetime
            date: 'YYYY-MM-DD',
            time: 'hh:mm A',
        },
        display: {
            date: 'MMMM DD, YYYY',
            date_new: 'dddd, MMM D',
            date_short: 'ddd, MMM D',
            event_date: 'dddd, MMM D',
            date_fullday: 'dddd MMMM D, YYYY',
            date_fullday_with_time: 'dddd MMMM D, YYYY h:mma',
            time: 'h:mma',
            datetime: 'MMMM DD, YYYY h:mma',
        },
    },
    forms: {
        files: {
            defaultMaxSize: 40 * 1024 * 1024, // 40mb
        },
        location: {
            default: [49.3539659,-123.0809661]
        },
    },
    SEO: {
        url: 'https://www.simplicitycms.ca',
        content_type: 'text/html; charset=utf-8',
        x_ua_compatible: 'IE=edge,chrome=1',
        robots_content: 'index,follow',
        website_title: 'SimpliCity CMS',
        website_image: 'https://images.dnv.org/images/dnv-air-deepcove.jpg',
        description: 'One front door to access municipal information and digital services',
        viewport: 'width=device-width, initial-scale=1.0',
    },
    Logging: {
        SourceToken: process.env.REACT_APP_LOG_SOURCE_TOKEN || 'tJ2uREcbw4nRBTZyryY52PHN', // defaults to simplicity development token
        enableConsole: process.env.REACT_APP_ENABLE_CONSOLE_LOG === 'true' || false,
        enableApiHttp: process.env.REACT_APP_ENABLE_HTTP_LOG === 'true' || false,
        enableSavePipelineLog: process.env.REACT_APP_ENABLE_SAVE_PIPELINE_LOG === 'true' || false,
        enableWebpageLog: process.env.REACT_APP_ENABLE_WEBPAGE_LOG === 'true' || false,
        localLoggingOnly: process.env.REACT_APP_LOG_SOURCE_TOKEN === undefined
    },
    // FontAwesomeKit: process.env.REACT_APP_FONT_KIT, // dnv: kit-790184f31f, simplicity: kit-db56931b65
    SyncFusion: {
        licence: process.env.REACT_APP_SYNCFUSION_LICENCE_KEY
    },
    // TODO: Replace with SimpliCity account
    Recollect: {
        script: { src: 'https://assets.ca.recollect.net/api/widget.js?scripts=head' },
        style: { href: 'https://recollect.a.ssl.fastly.net/0.11.1654194167/api/areas/DistNorthVancouver/services/waste/style/widget/logan/Default.css' }
    },
    // TODO: Replace with SimpliCity account
    Userway: {
        src: 'https://cdn.userway.org/widget.js',
        account: ''
    },
    // TODO: make into an organization setting
    google_site_verification: '+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=', // Must define in customer config
    recaptcha_site_key: '6LenVeEpAAAAAHBfaB6YaiuxZewd_XN-5LkeT_2a', // Must define in customer config
}
console.log('Built by Simplicity Digital with love.')
console.log(`[ENV = ${CmsConfig.ENV}]`)

export { CmsConfig }
