import { RichText } from 'cms/rich-text'
import { useMemo } from 'react'
import { useFormSnapshot, useFormState } from './context'
import { FormResultFields } from './request/form-result-fields'
import { useGroupResponses } from './request/use-group-responses'

export const ReviewStep = (props) => {
    const { title, description } = props
    const getSnapshot = useFormSnapshot()

    const { schemaForm } = useFormState()
    const { steps, questions } = schemaForm

    const { groups, snapshots } = useMemo(
        () => getSnapshot(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const groupedResponses = useGroupResponses(snapshots, groups)

    return (
        <div className='review-step'>
            <p className='title'>{title}</p>
            <RichText richtext={description} className='paragraph' />

            {/* steps */}
            {steps.map((step) => {
                const { label: stepLabel, step: stepNumber } = step

                const stepResponses = groupedResponses.filter(
                    (response) => {
                        const question = questions.find(
                            (question) =>
                                question.guid === response.guid,
                        )
                        return question?.step === stepNumber
                    },
                )

                return (
                    <div className='response-step-container' key={`review-step-${stepNumber}`}>
                        <div className='response-step'>
                            <span>{stepNumber}</span>
                            <div>
                                <h2 className={stepLabel ? "step-title" : 'empty-step'}>{stepLabel}</h2>
                                <FormResultFields
                                    responses={stepResponses}
                                />
                            </div>
                        </div>

                        <hr />
                    </div>
                )
            })}
        </div>
    )
}
