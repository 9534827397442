import React, { useEffect, useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import Inline from 'yet-another-react-lightbox/plugins/inline'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { generateImageSlides } from './utils'
import { isArrayNotEmpty, isValidGuid } from 'cms/utils/empty-exists'
// import { Logger } from 'cms/utils/logger'

const ImageGalleryCarousel = (props) => {
    
    const { token } = useAuth()
    const { image_gallery } = props || {}
    const [slides, setSlides] = useState([])
    const showToggle = true
    const descriptionTextAlign = 'start'
    const descriptionMaxLines = 3

    useEffect(() => {
        if (image_gallery && image_gallery.length > 0) {
            const images = image_gallery.filter((element) => isValidGuid(element?.image))
            generateImageSlides(images, token)
                .then((result) => setSlides(result)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image_gallery])

    // Logger.debug(slides)

    return (
        <div className="simplicity-image-list-item">
            {isArrayNotEmpty(slides) && (
                <div style={{
                    width: "100%",
                    maxWidth: "900px",
                    aspectRatio: "3 / 2"
                }}>
                    <Lightbox
                        slides={slides}
                        style={{
                            width: "100%",
                            maxWidth: "900px",
                            aspectRatio: "3 / 2",
                            margin: "0 auto",
                        }}
                        carousel={{
                            spacing: 0,
                            padding: 0,
                            imageFit: "cover",
                        }}
                        captions={{ showToggle, descriptionTextAlign, descriptionMaxLines }}
                        plugins={[Inline,Captions,Fullscreen]}
                    />
                </div>
            )}
        </div>
    );
}

export { ImageGalleryCarousel }
