
import { useLocation } from 'react-router-dom'

const RemoveTrailingSlash = () => {
    
    // const {...rest} = props
    const location = useLocation()
    
    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
        window.location.replace(location.pathname.replace(/\/+$/, ""))
        // return (
        //     <Navigate
        //         // replace
        //         {...rest}
        //         to={{
        //             pathname: location.pathname.replace(/\/+$/, ""),
        //             search: location.search
        //         }}
        //     />
        // )
    } else {
        return null
    }
}
export { RemoveTrailingSlash }
