
import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/layout`

const getLayouts = async function (token, params) {
    const method = 'GET'
    let path = `${endpoint}`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } }, { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const getLayoutsForModel = async function (token, modelType, params) {
    const method = 'GET'
    let path = `${endpoint}/model/${modelType}`
    if (params) {
        const page = params.page
        const pageSize = params.pageSize
        const query = qs.stringify(
            { pagination: { page, pageSize } }, { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

// const searchLayouts = async function (token, searchTerm, searchType, params) {
//     const method = 'GET'
//     let path = `${endpoint}/search`
//     const payload = undefined
//     if (params) {
//         const page = params.page
//         const pageSize = params.pageSize
//         const query = qs.stringify(
//             {
//                 term: searchTerm,
//                 type: searchType,
//                 pagination: { page, pageSize }
//             },
//             { encodeValuesOnly: true }
//         )
//         path = `${path}?${query}`
//     }
//     const headers = {"Authorization": `Bearer ${token}`}
//     return await makeRequest(method, base_url, path, headers, payload)
// }
const getLayout = async function (token, guid) {
    const method = 'GET'
    let path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
const createLayout = async function (token, layout) {
    const method = 'POST'
    let path = `${endpoint}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = layout
    return await makeRequest(method, base_url, path, headers, payload)
}
const updateLayout = async function (token, guid, layout) {
    const method = 'PUT'
    let path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = payload
    return await makeRequest(method, base_url, path, headers, payload)
}
const deleteLayout = async function (token, guid) {
    const method = 'DELETE'
    let path = `${endpoint}/${guid}`
    const headers = {"Authorization": `Bearer ${token}`}
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

export {
    getLayouts,
    getLayoutsForModel,
    // searchLayouts,
    getLayout,
    createLayout,
    deleteLayout,
    updateLayout,
}