import React, { useState, useEffect, Fragment } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { BannerImage } from './banner-image'
import { objectHasPropertyString } from 'cms/utils/empty-exists'
import { Logger } from 'cms/utils/logger'

const Webpage = (props) => {
    
    const { web, webpageCard, total } = props || {}
    const { token = '' } = useAuth()
    const [webpage, setWebpage] = useState()
    const {model: model_guid} = webpage || {}

    useEffect(() => {
        const fetchWebpage = async () => {
            if (web?.webpage) {
                let _webpage = await SimpliCitySDK.webpage.findOne(token, web?.webpage)
                if (objectHasPropertyString(_webpage, 'guid')) {
                    setWebpage(_webpage)
                } else {
                    Logger.debug(`Could not load webpage record... ${web.webpage})`)
                    Logger.flush()
                }
            } else {
                setWebpage(undefined)
            }
        }
        fetchWebpage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [web])
    
    const onClick = (e, url) => {
        e.stopPropagation()
        location.href = url
    }
    
    return (
        <Fragment>
            {webpage && (
                <div
                    className={`feature-webpage-content ${webpageCard}_full_width_${total%3}`}
                    onClick={(event) => onClick(event, webpage?.path)}
                >
                    {model_guid &&
                        <BannerImage
                            model={model_guid}
                        />
                    }
                    <h4>
                        <a
                            href={webpage?.path}
                            className='cta-link-style'
                        >
                            {web?.title}
                        </a>
                    </h4>
                    <p>{web?.description}</p>
                </div>
            )}
        </Fragment>
    )
}

export { Webpage }
