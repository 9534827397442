import { Config } from '../config'
import { makeRequest } from "../common/index"
import qs from 'qs'

const base_url = Config.admin.api_base_url
const context = Config.admin.context
const endpoint = `/${context}/redirects`

const getRedirects = async function (token, params) {
    const method = "GET"
    let path = `${endpoint}`
    if (params) {
        const { page, pageSize, term, target, source, sort, sortdir } = params
        const pagination = { page, pageSize }
        const query = qs.stringify(
            { pagination, term, target, source, sort, sortdir },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

const getRedirect = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

const createRedirect = async function (token, payload) {
    const method = "POST"
    const path = `${endpoint}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}

const deleteRedirect = async function (token, guid) {
    const method = "DELETE"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}

const updateRedirect = async function (token, id, payload) {
    const method = "PATCH"
    const path = `${endpoint}/${id}`
    const headers = { Authorization: `Bearer ${token}` }
    return await makeRequest(method, base_url, path, headers, payload)
}

export {
    createRedirect,
    deleteRedirect,
    getRedirect,
    getRedirects,
    updateRedirect,
}
