import React, { useEffect } from 'react'
import cn from 'clsx'
import { isTrue } from "cms/utils/empty-exists";
const EmbedComponent = (props) => {
    
    const { component } = props
    const {
        html,
        isResponsive,
        enableScript,
        script: _script,
    } = component || {}
    const hasContent = html !== '/*<!-- SimpliCity CMS Embed placeholder -->*/\n'
    
    const classNames = cn(
        'simplicity-embed',
        { 'responsive': isResponsive }
    )
    useEffect(() => {
        if (isTrue(enableScript)) {
            window.eval(_script)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_script])
    
    return (
        <>
            {hasContent &&
                <div className={classNames}>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
            }
        </>
    )
}

EmbedComponent.displayName = 'EmbedComponent'
export { EmbedComponent }
