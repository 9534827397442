import React from 'react'
import { RichText } from 'cms/rich-text'
import { RichTextImage } from 'cms/shared/images/rich-text-image'

const RichTextWithImageComponent = (props) => {
    
    let { component } = props || {}
    let {
        richtext,
        image,
        side = 'left',
        displayWidth,
        displayHeight,
        settings,
    } = component || {}
    // object-fit: cover;
    
    const orientation = (displayWidth > displayHeight) ? 'horizontal' : 'vertical'
    return (
        <div className="simplicity-richtext-wrap">
            {image && (
                <div
                    className={`simplicity-richtext-wrap-image ${side} ${orientation}`}
                    // style={{
                    //     width: displayWidth,
                    //     height: displayHeight
                    // }}
                >
                    <RichTextImage
                        width={displayWidth}
                        height={displayHeight}
                        image={image}
                        settings={settings}
                        orientation={orientation}
                    />
                </div>
            )}
            <div className="rich-text-wrap read-only">
                <RichText richtext={richtext} />
            </div>
        </div>
    )
}

RichTextWithImageComponent.displayName = 'RichTextWithImageComponent'
export { RichTextWithImageComponent }
