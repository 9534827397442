import { useForm } from 'cms/forms/context'
import ErrorMsg from '../error-msg'
import { useRequiredValidator } from '../hooks/use-required-validator'
import { useSetQuestionErrors } from '../hooks/use-set-question-errors'
import { useShowError } from '../hooks/use-show-error'

/**
 * @type {QuestionComponent<FormQuestion<string>>}
 */
const SimplicityFormShortAnswer = (props) => {

    const { field } = props || {}
    const {
        guid,
        prompt,
        value,
        hasPlaceholderText,
        hasDescription,
        description,
        hasHelperText,
        helperText,
        isRequired,
        required,
    } = field || {}
    const { onInputChange } = useForm()

    const currentError = useRequiredValidator(
        isRequired,
        value
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    const onChangeAnswer = (event) => {
        const value = event.target.value
        onInputChange(value, field)
    }

    return (
        <div className="simplicity-forms-field-group">
            <label className="simplicity-forms-field-label">
                {prompt}
                {!required && <span> (optional)</span>}
            </label>
            {hasDescription && description && <span className="simplicity-forms-description">{description}</span>}
            <input
                type="text"
                id={guid}
                name={guid}
                placeholder={hasPlaceholderText}
                value={value}
                onChange={onChangeAnswer}
                className={hasError ? 'form-field-error-input' : ''}
            />
            {hasError && <ErrorMsg type="error" error={error} />}
            {hasHelperText && helperText && <span className="simplicity-forms-helper-text">{helperText}</span>}
        </div>
    )
}

export { SimplicityFormShortAnswer }
