'use strict'

import { Config } from '../config'
import { Logtail } from '@logtail/browser'

const source_token = Config.Logging.SourceToken

const LogTailLogger = new Logtail(source_token)
const ConsoleLogger = console
let Logger = LogTailLogger

if (Config.Logging.enableConsole) {
    ConsoleLogger.flush = async () => {}
    Logger = ConsoleLogger
    
} else {
    const developerLog = (log) => {
        
        if (log.level === 'error') {
            const error = log.error
            const { message, stack } = error || {}
            // const error_message = `[${log.dt.toISOString()}] [${log.level}] ${log.message} ${message} \n${stack}`
            const error_message = `[${log.dt.toISOString()}] [${log.level.toUpperCase()}] ${log.message} ${message}`
            // ConsoleLogger.error(log.message, log.error)
        }
        else if (log.level === 'warn') {
            // ConsoleLogger.warn(log.message, log.context)
        }
        else {
            const message = `[${log.dt.toISOString()}] [${log.level}] ${log.message}`
            log.message = message
        }
        return log
    }
    Logger.use(developerLog)
    
}

export { Logger, ConsoleLogger }

