import { CurrentFilterTag } from "./current-filter-tag"
import { useCurrentRefinementList } from "./use-current-refinement-list"

import styles from './current-filter-list.module.css'

/**
 * @param {object} props
 * @param {string} props.attribute
 * @param {string} props.prefix
 */
export function CurrentFilterList (props) {
    const { attribute, prefix } = props
    const { currentItems, refine } = useCurrentRefinementList(attribute)

    return (
        <div className={styles.container}>
            {currentItems.map((item) => (
                <CurrentFilterTag
                    key={`filter-tag-${item.value}`}
                    onClose={() => refine(item.value)}
                    prefix={prefix}
                >
                    {item.label}
                </CurrentFilterTag>
            ))}
        </div>
    )
}

