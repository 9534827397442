
const handleJSON = async function (str) {
    if (isJsonString(str)) {
        try {
            const temp = JSON.parse(str)
            return JSON.stringify(temp)
        } catch (e) { throw e }
    }
    return JSON.stringify(str)
}
const isJsonString = function(str) {
    try { JSON.parse(str) } catch (e) { return false }
    return true;
}

export {
    handleJSON,
    isJsonString,
}