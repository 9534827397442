import React, { Fragment } from 'react'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { Component } from 'cms/fields/component'

const ServicesList = () => {
    
    // const title = 'Common Tasks'
    // const allServices = 'All online services'
    // const allServicesPath = '/programs-and-services/online-payments-and-services'
    
    const { webpage, model, fields } = useSimpliCity()
    const { services_list } = fields || {}
    
    return (
        <Fragment>
            <div className="featured-services-list__wrapper">
                <div className="region region-featured-services services-list">
                    
                    {services_list &&
                        <Fragment>
                            <Component
                                field={services_list}
                                model={model}
                                webpage={webpage}
                            />
                        </Fragment>
                    }
                    
                </div>
            </div>
        </Fragment>
    )
}
export { ServicesList }
