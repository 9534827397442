import {
    FormControlLabel,
    FormGroup,
    Checkbox as MuiCheckbox,
} from '@mui/material'
import cn from 'clsx'
import { useForm } from 'cms/forms/context'
import ErrorMsg from 'cms/forms/error-msg'
import { useRequiredValidator } from 'cms/forms/hooks/use-required-validator'
import { useSetQuestionErrors } from 'cms/forms/hooks/use-set-question-errors'
import { useShowError } from 'cms/forms/hooks/use-show-error'
import { useRef } from 'react'
import { OtherLabel } from './common/other-label'

/** @type {QuestionComponent<FormQuestionCheckbox>} */
const SimplicityFormCheckbox = (props) => {
    const { field } = props
    const {
        guid,
        prompt,
        value,
        hasDescription,
        description,
        hasHelperText,
        helperText,
        options,
        isRequired,
        otherOptions: instructions
    } = field
    const { onInputChange } = useForm()

    /**
     * Contains a list of selected option ids.
     * 
     * Used to simplify adding and removing strings as compared to a
     * traditional array.
     * @type {import('react').MutableRefObject<Set<string>>}
     */
    const selectedOptions = useRef(new Set(value))

    const handleChange = (event, currentOption) => {
        const isChecked = event.target.checked

        if (isChecked) {
            selectedOptions.current.add(currentOption.id)
        } else {
            selectedOptions.current.delete(currentOption.id)
        }

        const nextValue = [...selectedOptions.current.values()]
        onInputChange(nextValue, field)
    }

    const currentError = useRequiredValidator(
        isRequired,
        value,
        'Please make at least one selection'
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    const className = cn({
        'form-field-error-input': hasError
    })

    return (
        <div className='simplicity-forms-field-group'>
            <label
                htmlFor={`simplicity-forms-checkbox-${guid}`}
                className='simplicity-forms-field-label'
            >
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </label>
            {hasDescription && description && (
                <span className='simplicity-forms-description'>{description}</span>
            )}
            {hasError && (
                <ErrorMsg type='error' error={error} />
            )}
            {options && options.length > 0 && (
                <FormGroup className={`simplicity-forms-field-radios ${hasError && 'error'}`}>
                    {options.map((option) => {
                        const id = option?.id || option?.name
                        const isChecked = selectedOptions.current.has(option.id)

                        return (
                            <FormControlLabel
                                key={id}
                                control={
                                    <MuiCheckbox
                                        checked={isChecked}
                                        name={id}
                                    />
                                }
                                onChange={(e) => handleChange(e, option)}
                                label={
                                    <OtherLabel
                                        guid={guid}
                                        className={className}
                                        id={id}
                                        option={option}
                                        instructions={instructions}
                                        isChecked={isChecked}
                                    />
                                }
                            />
                        )
                    })}
                </FormGroup>
            )}
            {hasHelperText && helperText && (
                <span className='simplicity-forms-helper-text'>{helperText}</span>
            )}
        </div>
    )
}

export { SimplicityFormCheckbox }
