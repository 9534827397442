import Joi from 'joi'
const guid_validator = Joi.string().guid().required()
const image_validator = Joi.object({
    guid: Joi.string().guid().required(),
    organization: Joi.string().guid().required(),
    title: Joi.string().optional(),
    description: Joi.string().allow(null),
    alt: Joi.string().required(),
    url: Joi.string().uri(),
    width: Joi.number().optional(),
    height: Joi.number().optional(),
    file_ext: Joi.string(),
    file_name: Joi.string().optional(),
    file_size: Joi.number().optional().allow(null),
    file_type: Joi.string().optional().allow(null),
    storage_account: Joi.string().optional(),
    storage_container: Joi.string().optional(),
    storage_filepath: Joi.string().optional(),
    storage_url: Joi.string().uri(),
    created_at: Joi.string().optional(),
    created_by: Joi.string().guid().optional(),
    updated_at: Joi.string().optional(),
    updated_by: Joi.string().guid().optional(),
}).unknown(true)

const validate = function (input, schema) {
    const { error, value } = schema.validate(input)
    if (error) {
        // console.error(message)
        return false
    }
    if (value) {
        return true
    }
    return false
}
const isImage = function (input) {
    const message = `Image value was not an image object.`
    // console.log('validate:', 'image', input)
    return validate(input, image_validator, message)
}
const isGuid = function (input) {
    const message = `Image value was not an image guid.`
    // console.log('validate:', 'guid', input)
    return validate(input, guid_validator, message)
}

export default {
    isImage,
    isGuid,
}
