import { QuickSearch } from 'components/search'
import { Link } from 'react-router-dom';

const TopBar = () => {
    return (
        <div className="top-bar">
            <div className="top-bar-content">
                <ul>
                    <li>
                        <Link to="/government-administration/report-problem">Report a Problem</Link>
                    </li>
                    <li>
                        <Link to='/government-administration/contact-us'>Contact Us</Link>
                    </li>
                </ul>

                <QuickSearch />
            </div>
        </div>
    );
}

export { TopBar }
