import { Config } from '../config'
import { makeRequest } from '../common/index'
import qs from 'qs'

const base_url = Config.public.api_base_url
const context = Config.public.context
const endpoint = `/${context}/redirects`

export const getRedirects = async function (token, params) {
    const method = "GET"
    let path = `${endpoint}`
    if (params) {
        const { page, pageSize, term, target, source, sort, sortdir } = params
        const pagination = { page, pageSize }
        const query = qs.stringify(
            { pagination, term, target, source, sort, sortdir },
            { encodeValuesOnly: true }
        )
        path = `${path}?${query}`
    }
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
export const getRedirect = async function (token, guid) {
    const method = "GET"
    const path = `${endpoint}/${guid}`
    const headers = { Authorization: `Bearer ${token}` }
    const payload = undefined
    return await makeRequest(method, base_url, path, headers, payload)
}
