import { Chip } from '@mui/material'
import { FaWrapper } from '../../fa-wrapper'
import styles from '../dropdown-option.module.css'

/**
 * @param {RefinementOptionProps} props
 */
export function RefinementOption (props) {
    const { children, data, innerProps, isSelected, innerRef } = props

    return (
        <div ref={innerRef} {...innerProps} className={styles.container}>
            {/* checkbox simulation */}
            <FaWrapper icon="fa-solid fa-square-check fa-lg" color="var(--color-dnv-focus-green)" show={isSelected} />
            <FaWrapper icon="fa-regular fa-square fa-lg" show={!isSelected} />

            <div className={`${styles.label} ${styles.center}`}>
                <p>{children}</p>
                <Chip label={data.count} color="navy" size="small" />
            </div>
        </div>
    )
}
