import { isString } from 'cms/utils/empty-exists'
import { Fragment } from 'react'
import { DynamicResponse } from './dynamic-response'
import { DynamicResponseGroup } from './dynamic-response-group'

/**
 * @param {GroupedQuestionResponse} response 
 * @returns {response is QuestionResponseGroup}
 */
export const isQuestionResponseGroup = (response) => {
    return !isString(response.type)
}

/**
 * @param {object} props
 * @param {GroupedQuestionResponse[]} props.responses
 */

const FormResultFields = (props) => {
    const { responses } = props

    return responses.map((response) => (
        <Fragment key={response.guid}>
            {isQuestionResponseGroup(response) ? (
                <DynamicResponseGroup responseGroup={response} />
            ) : (
                <DynamicResponse response={response} />
            )}
        </Fragment>
    ))
}

export { FormResultFields }
