import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clsx } from 'clsx'

/** @type {import('@fortawesome/fontawesome-svg-core').SizeProp} */
const iconSize = '1x'

/**
 * @type {import('react').FC<FeedPaginationItemProps>}
 */
const FeedPaginationItem = (props) => {

    const {
        isDisabled = false,
        onClick,
        square = false,
        ariaLabel,
        isCurrentPage = false,
        children,
        iconLeft,
        iconRight
    } = props

    const baseClass = square ? 'feed-pagination-item-square' : 'feed-pagination-item'
    const searchClass = clsx(baseClass, {
        'feed-pagination-selected-item': isCurrentPage,
        disabled: isDisabled
    })

    const onClickWrapper = () => {
        if (!isDisabled) {
            onClick()
        }
    }

    /** @type {React.MouseEventHandler<HTMLAnchorElement>} */
    const handleClick = (event) => {
        event.preventDefault()
        onClickWrapper()
    }

    /**
     * @type {React.KeyboardEventHandler<HTMLAnchorElement>}
     */
    const handleKeyDown = (event) => {
        const activatableKeys = ['space', 'enter']
        const lowerKey = event.code.toLowerCase()

        if (activatableKeys.includes(lowerKey)) {
            event.preventDefault()
            onClickWrapper()
        }
    }

    return (
        <a
            className={searchClass}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            aria-label={ariaLabel}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
        >
            {iconLeft &&
                <FontAwesomeIcon
                    icon={iconLeft}
                    size={iconSize}
                />
            }
            
            <span className='feed-pagination-text'>{children}</span>
            
            {iconRight &&
                <FontAwesomeIcon
                    icon={iconRight}
                    size={iconSize}
                />
            }
        </a>
    )
}

export { FeedPaginationItem }


/**
 * @typedef {object} FeedPaginationItemProps
 * @property {boolean=} isDisabled
 * @property {() => void} onClick
 * @property {boolean=} square
 * @property {string} ariaLabel
 * @property {boolean=} isCurrentPage
 * @property {string | number} children
 * @property {import('@fortawesome/fontawesome-svg-core').IconDefinition} iconLeft
 * @property {import('@fortawesome/fontawesome-svg-core').IconDefinition} iconRight
 */
