import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Button } from '@mui/material'
import { QuestionTypes } from 'cms/forms/question-types'
import StepsDisplayHeader from 'cms/forms/steps/display-header'
import { useFormState } from '../context'
import { ReviewStep } from '../review-step'
import { useStepControls } from './use-step-controls'
import { useSteps } from './use-steps'

/**
 * @type {import('react').FC<StepsProps>}
 */
const Steps = (props) => {

    const {
        showReviewStep,
        title,
        description,
        onNavigate
    } = props
    const { activeStep } = useFormState()

    const {
        isFirstStep,
        isLastStep,
        showReview,
        showStepper,
        stepQuestions,
        steps,
    } = useSteps(showReviewStep)

    const { next, prev } = useStepControls(stepQuestions, onNavigate)
    const hasSteps = steps.length > 0

    return (
        <div style={{ width: '100%' }}>
            {hasSteps &&
                <>
                    {/* Stepper */}
                    {!showStepper && (
                        <StepsDisplayHeader
                            activeStep={activeStep}
                            steps={steps}
                        />
                    )}

                    {/* Review / Questions */}
                    {showReview ? (
                        <ReviewStep
                            description={description}
                            title={title}
                        />
                    ) : stepQuestions.map((question) => (
                        <QuestionTypes
                            key={question?.guid}
                            field={question}
                        />
                    ))}

                    {/* Step controls */}
                    <div className='form-btn-container'>
                        {!isFirstStep && <Button
                            color="inherit"
                            onClick={prev}
                            sx={{ mr: 1 }}
                            className='form-btn-back'
                        >
                            <ArrowBackIosIcon /> Previous
                        </Button>}
                        {isLastStep ?
                            <button type='submit' className='form-btn-submit'>Submit</button> :
                            <Button onClick={next} className='form-btn-next'>
                                Next <ArrowForwardIosIcon />
                            </Button>
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default Steps


/**
 * @typedef {object} StepsProps
 * @property {boolean} showReviewStep
 * @property {string} title
 * @property {string} description
 * @property {() => void} onNavigate
 */
